<template>
    <div class="padding-container">
        <div class="content-load" v-if="isPageLoading"></div>
        <div id="loader" v-if="isPageLoading"></div>
        <div class="fw-bold">
            <div
                class="text-gray-dashboard size-16 cursor-pointer"
                @click="modalConfirmBack = true"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </div>
        </div>
        <div class="box-manifest mt-4">
            <div class="d-flex align-items-center justify-content-between mb-4">
                <h5 class="fw-bold mb-0">
                    Detail Manifest - {{ detail.manifest_code }}
                </h5>
                <div
                    class="rounded-pill process d-flex align-items-center ms-4 px-4"
                    v-if="detail.status === 'process'"
                >
                    Dalam Proses
                </div>
                <div
                    class="rounded-pill ontheway d-flex align-items-center ms-4 px-4"
                    v-if="detail.status === 'ontheway'"
                >
                    Dalam Perjalanan
                </div>
                <div
                    class="rounded-pill end d-flex align-items-center ms-4 px-4"
                    v-if="detail.status === 'finish'"
                >
                    Selesai
                </div>
            </div>
            <div class="row">
                <div class="mb-4 col-md-6 position-relative">
                    <label class="mb-2">
                        Kota/Kabupaten Asal Manifest
                        <span class="text-yellow">*</span>
                    </label>
                    <div class="position-relative">
                        <img
                            src="../assets/magnifier.svg"
                            alt="icon"
                            class="input-icon"
                        />
                        <input
                            type="text"
                            class="form-control form-control-lg input-read-only pl-40"
                            :value="detail.origin_regency"
                            readonly
                        />
                    </div>
                </div>
                <div class="mb-4 col-md-6">
                    <label class="mb-2"
                        >Kota/Kabupaten Tujuan Manifest
                        <span class="text-yellow">*</span></label
                    >
                    <div class="position-relative">
                        <img
                            src="../assets/magnifier.svg"
                            alt="icon"
                            class="input-icon"
                        />
                        <input
                            type="text"
                            class="form-control form-control-lg input-read-only pl-40"
                            :value="detail.destination_regency"
                            readonly
                        />
                    </div>
                </div>
                <div class="mb-4 col-md-12">
                    <label class="mb-3"
                        >Pilih resi yang yang akan ditambahkan pada manifest
                        <span class="text-yellow">*</span></label
                    >
                    <div class="box">
                        <div
                            class="d-flex justify-content-between align-items-center flex-wrap"
                        >
                            <h5 class="fw-bold mb-0">Daftar Resi</h5>
                            <div class="position-relative">
                                <div class="input-group">
                                    <input
                                        type="text"
                                        class="form-control form-control-lg pl-filter-search w-search border-search"
                                        placeholder="Cari kota tujuan/nomor resi"
                                        v-model="search"
                                        @keyup.enter="handleSearch()"
                                    />
                                    <button
                                        class="btn btn-search px-4"
                                        type="button"
                                        @click="handleSearch()"
                                    >
                                        Cari
                                    </button>
                                </div>
                                <span class="magnifier-icon left">
                                    <img
                                        :src="
                                            originRegency === null ||
                                            destinationRegency === null ||
                                            search === null ||
                                            search === ''
                                                ? magnifierIcon
                                                : magnifierActiveIcon
                                        "
                                        alt="icon magnifier"
                                        width="20"
                                        height="20"
                                    />
                                </span>
                            </div>
                        </div>
                        <div class="overflow-auto" v-if="receipts.length !== 0">
                            <div
                                class="d-flex justify-content-between align-items-center mt-4"
                            >
                                <div class="d-flex align-items-center">
                                    <img
                                        src="../assets/arrow-right.svg"
                                        alt="arrow-right"
                                        class="me-3"
                                    />
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        :checked="checkAll"
                                        @change="handleCheckAll"
                                    />
                                    <p class="mb-0 ms-3">Tandai Semua</p>
                                </div>
                                <div class="d-flex align-items-center">
                                    <p class="mb-0 me-3">
                                        <span class="fw-bold"
                                            >{{ pageStart }}-{{ pageEnd }}</span
                                        >
                                        Data dari
                                        <span class="fw-bold">{{ rows }}</span>
                                        Data
                                    </p>
                                    <b-pagination
                                        v-model="currentPage"
                                        :total-rows="rows"
                                        :per-page="perPage"
                                    ></b-pagination>
                                </div>
                            </div>
                            <div
                                v-for="(receipt, index) in paginatedReceipts"
                                :key="index"
                            >
                                <div class="d-flex align-items-center mt-4">
                                    <img
                                        src="../assets/arrow-right.svg"
                                        alt="arrow-right"
                                        class="me-3 cursor-pointer"
                                        :class="{
                                            'rotate-90': receipt.isExpanded,
                                        }"
                                        @click="toggleReceipt(receipt)"
                                    />
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        :checked="
                                            checkedReceipts.some(
                                                (r) =>
                                                    r.receipt_code ===
                                                    receipt.receipt_code
                                            )
                                        "
                                        @change="handleReceiptCheck(receipt)"
                                    />
                                    <p class="mb-0 ms-3 fw-bold">
                                        {{ receipt.receipt_code }}
                                    </p>
                                </div>
                                <div class="d-flex mt-2">
                                    <img
                                        src="../assets/arrow-right.svg"
                                        alt="arrow-right"
                                        class="me-3 invisible"
                                    />
                                    <input
                                        type="checkbox"
                                        class="form-check-input invisible"
                                    />

                                    <table
                                        class="w-100 mt-2 ms-3"
                                        cellpadding="10"
                                    >
                                        <thead
                                            class="thead fw-bold"
                                            v-show="receipt.isExpanded"
                                        >
                                            <tr class="border-bottom">
                                                <td class="text-center">
                                                    Koli
                                                </td>
                                                <td class="text-center">
                                                    Berat (Kg)
                                                </td>
                                                <td class="text-center">
                                                    Berat Volume (Kg)
                                                </td>
                                                <td class="text-center">
                                                    Kubikasi (Kg3)
                                                </td>
                                            </tr>
                                        </thead>

                                        <tbody v-show="receipt.isExpanded">
                                            <tr
                                                class="border-bottom"
                                                v-for="(
                                                    item, index
                                                ) in receipt.items"
                                                :key="index"
                                            >
                                                <td class="text-center">
                                                    {{ item.qty }} Koli
                                                </td>
                                                <td class="text-center">
                                                    {{ item.weight }} Kg
                                                </td>
                                                <td class="text-center">
                                                    {{ item.weight_volume }} Kg
                                                </td>
                                                <td class="text-center">
                                                    {{ item.cubic }}
                                                    Kg3
                                                </td>
                                            </tr>
                                        </tbody>

                                        <tfoot
                                            class="fw-bold"
                                            :class="{
                                                tfoot: receipt.isExpanded,
                                            }"
                                        >
                                            <tr>
                                                <td>
                                                    <div
                                                        class="d-flex justify-content-center align-items-center"
                                                    >
                                                        <img
                                                            src="../assets/koli.svg"
                                                            alt="koli"
                                                            class="me-2"
                                                        />
                                                        {{ receipt.total_qty }}
                                                        Koli
                                                    </div>
                                                </td>
                                                <td>
                                                    <div
                                                        class="d-flex justify-content-center align-items-center"
                                                    >
                                                        <img
                                                            src="../assets/berat.svg"
                                                            alt="berat"
                                                            class="me-2"
                                                        />
                                                        {{
                                                            receipt.total_weight
                                                        }}
                                                        Kg
                                                    </div>
                                                </td>
                                                <td>
                                                    <div
                                                        class="d-flex justify-content-center align-items-center"
                                                    >
                                                        <img
                                                            src="../assets/berat-volume.svg"
                                                            alt="berat volume"
                                                            class="me-2"
                                                        />
                                                        {{
                                                            receipt.total_weight_volume
                                                        }}
                                                        Kg
                                                    </div>
                                                </td>
                                                <td>
                                                    <div
                                                        class="d-flex justify-content-center align-items-center"
                                                    >
                                                        <img
                                                            src="../assets/kubikasi.svg"
                                                            alt="kubikasi"
                                                            class="me-2"
                                                        />
                                                        {{
                                                            receipt.total_cubic
                                                        }}
                                                        Kg3
                                                    </div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <hr
                                    v-if="index < paginatedReceipts.length - 1"
                                />
                            </div>
                        </div>
                        <div v-else>
                            <div class="d-flex justify-content-center mt-5">
                                <img src="../assets/empty.svg" alt="empty" />
                            </div>
                            <p class="text-gray text-center mt-5">
                                Resi tidak tersedia
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="mb-4 col-md-6">
                <div class="box-manifest h-100">
                    <h5 class="fw-bold mb-4">Nomor Resi Yang Dipilih</h5>
                    <div>
                        <div
                            v-for="(receipt, index) in selectedReceipts"
                            :key="index"
                            class="d-flex justify-content-between align-items-center py-2"
                            :class="{
                                'border-bottom':
                                    index < selectedReceipts.length - 1,
                                'bg-light': newlyAddedReceipts.some(
                                    (r) => r.receipt === receipt.receipt
                                ),
                            }"
                        >
                            <p class="mb-0 fw-bold">{{ receipt.receipt }}</p>
                            <div class="d-flex align-items-center">
                                <p class="mb-0 me-2">
                                    {{
                                        newlyAddedReceipts.some(
                                            (r) => r.receipt === receipt.receipt
                                        )
                                            ? receipt.regency
                                            : receipt.regency
                                    }}
                                </p>
                                <img
                                    src="../assets/trash.svg"
                                    alt="trash"
                                    class="cursor-pointer"
                                    @click="removeReceipt(receipt.receipt)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mb-4 col-md-6">
                <div class="box-manifest h-100">
                    <h5 class="fw-bold">Ringkasan Manifest</h5>
                    <table class="w-100 mt-3" cellpadding="10">
                        <tr class="border-bottom">
                            <td class="pl-0">
                                <div class="d-flex align-items-center">
                                    <img
                                        src="../assets/rute.svg"
                                        alt="rute"
                                        class="me-2"
                                    />
                                    Rute Manifest
                                </div>
                            </td>
                            <td>:</td>
                            <td class="fw-bold">
                                {{ detail.origin_regency }} -
                                {{ detail.destination_regency }}
                            </td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="pl-0">
                                <div class="d-flex align-items-center">
                                    <img
                                        src="../assets/jumlah.svg"
                                        alt="jumlah"
                                        class="me-2"
                                    />
                                    Jumlah Resi
                                </div>
                            </td>
                            <td>:</td>
                            <td class="fw-bold">
                                {{ selectedReceipts.length }} Resi
                            </td>
                        </tr>
                        <tr>
                            <td class="pl-0">
                                <div class="d-flex align-items-center">
                                    <img
                                        src="../assets/arrive.svg"
                                        alt="arrive"
                                        class="me-2"
                                    />
                                    Waktu Tiba
                                </div>
                            </td>
                            <td>:</td>
                            <td class="fw-bold">
                                <input
                                    type="text"
                                    class="form-control form-control-lg input-read-only"
                                    readonly
                                    :value="
                                        formattedDate(
                                            detail.estimated_time_original
                                        )
                                    "
                                />
                            </td>
                        </tr>
                    </table>
                    <button
                        class="w-100 mt-3 btn btn-create"
                        @click="modalConfirm = true"
                    >
                        Simpan Perubahan
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirmBack">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Perubahan manifest belum disimpan
                    </h4>
                    <div class="text-gray-dashboard">
                        Lanjutkan perubahan manifest?
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            class="btn btn-outline-yellow w-100 btn-lg"
                            @click="$router.go(-1)"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            @click="modalConfirmBack = false"
                        >
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Perubahan manifest akan disimpan
                    </h4>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirm = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            @click="editManifest()"
                            :disabled="isLoading"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="isLoading"
                            ></span>
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Perubahan manifest berhasil disimpan
                    </h4>
                </div>
                <div class="mt-4">
                    <router-link
                        to="/manifest"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";
import moment from "moment";
import magnifierDisabled from "../assets/magnifier-disabled.svg";
import magnifier from "../assets/magnifier.svg";

export default {
    data() {
        return {
            modalConfirmBack: false,
            magnifierIcon: magnifierDisabled,
            magnifierActiveIcon: magnifier,
            isPageLoading: true,
            search: "",
            modalConfirm: false,
            modalSuccess: false,
            isLoading: false,
            detail: {
                orders: [],
            },
            moment: moment,
            receipts: [],
            allReceipts: [],
            removedReceiptsData: [],
            checkedReceipts: [],
            checkAll: false,
            newlyAddedReceipts: [],
            removedReceipts: [],
            currentPage: 1,
            perPage: 5,
        };
    },
    created() {
        this.initializeData();
    },
    methods: {
        handleSearch() {
            this.currentPage = 1;
            const searchTerm = this.search.toLowerCase().trim();

            const combinedReceipts = [
                ...this.allReceipts,
                ...this.removedReceiptsData,
            ];

            if (!searchTerm) {
                this.receipts = combinedReceipts
                    .filter(
                        (receipt) =>
                            !this.detail.orders.some(
                                (o) => o.receipt === receipt.receipt_code
                            ) ||
                            this.removedReceipts.some(
                                (r) => r.receipt === receipt.receipt_code
                            )
                    )
                    .map((receipt) => ({
                        ...receipt,
                        isExpanded: false,
                        isChecked: false,
                    }));
                this.updateCheckAllState();
                return;
            }

            this.receipts = combinedReceipts
                .filter((receipt) => {
                    const receiptCode = receipt.receipt_code || receipt.receipt;
                    const destination = receipt.dest || receipt.regency;

                    const matchesDest = destination
                        .toLowerCase()
                        .includes(searchTerm);
                    const matchesReceiptCode = receiptCode
                        .toLowerCase()
                        .includes(searchTerm);

                    return (
                        (matchesDest || matchesReceiptCode) &&
                        (!this.detail.orders.some(
                            (o) => o.receipt === receiptCode
                        ) ||
                            this.removedReceipts.some(
                                (r) => r.receipt === receiptCode
                            ))
                    );
                })
                .map((receipt) => ({
                    ...receipt,
                    isExpanded: false,
                    isChecked: false,
                    receipt_code: receipt.receipt_code || receipt.receipt,
                    dest: receipt.dest || receipt.regency,
                }));

            this.updateCheckAllState();
        },
        handleReceiptCheck(receipt) {
            const index = this.checkedReceipts.findIndex(
                (r) => r.receipt_code === receipt.receipt_code
            );

            if (index === -1) {
                this.checkedReceipts.push(receipt);
                if (
                    !this.detail.orders.some(
                        (o) => o.receipt === receipt.receipt_code
                    )
                ) {
                    this.newlyAddedReceipts.push({
                        order_id: receipt.order_id,
                        receipt: receipt.receipt_code,
                        regency: receipt.dest,
                        total_qty: receipt.total_qty,
                        total_weight: receipt.total_weight,
                        total_weight_volume: receipt.total_weight_volume,
                        items: receipt.items,
                    });
                }
            } else {
                this.checkedReceipts.splice(index, 1);
                const newIndex = this.newlyAddedReceipts.findIndex(
                    (r) => r.receipt === receipt.receipt_code
                );
                if (newIndex !== -1) {
                    this.newlyAddedReceipts.splice(newIndex, 1);
                }
            }
            this.updateCheckAllState();
        },

        handleCheckAll() {
            const currentPageReceipts = this.paginatedReceipts;

            if (!this.checkAll) {
                currentPageReceipts.forEach((receipt) => {
                    if (
                        !this.checkedReceipts.some(
                            (r) => r.receipt_code === receipt.receipt_code
                        )
                    ) {
                        this.checkedReceipts.push(receipt);

                        if (
                            !this.detail.orders.some(
                                (o) => o.receipt === receipt.receipt_code
                            )
                        ) {
                            this.newlyAddedReceipts.push({
                                order_id: receipt.order_id,
                                receipt: receipt.receipt_code,
                                regency: receipt.dest,
                                total_qty: receipt.total_qty,
                                total_weight: receipt.total_weight,
                                total_weight_volume:
                                    receipt.total_weight_volume,
                                items: receipt.items,
                            });
                        }
                    }
                });
            } else {
                currentPageReceipts.forEach((receipt) => {
                    const checkedIndex = this.checkedReceipts.findIndex(
                        (r) => r.receipt_code === receipt.receipt_code
                    );
                    if (checkedIndex !== -1) {
                        this.checkedReceipts.splice(checkedIndex, 1);
                    }

                    const newlyAddedIndex = this.newlyAddedReceipts.findIndex(
                        (r) => r.receipt === receipt.receipt_code
                    );
                    if (newlyAddedIndex !== -1) {
                        this.newlyAddedReceipts.splice(newlyAddedIndex, 1);
                    }
                });
            }

            this.checkAll = !this.checkAll;
        },

        removeReceipt(receiptCode) {
            const newlyAddedIndex = this.newlyAddedReceipts.findIndex(
                (r) => r.receipt === receiptCode
            );

            if (newlyAddedIndex !== -1) {
                this.newlyAddedReceipts.splice(newlyAddedIndex, 1);
                const checkedIndex = this.checkedReceipts.findIndex(
                    (r) => r.receipt_code === receiptCode
                );
                if (checkedIndex !== -1) {
                    this.checkedReceipts.splice(checkedIndex, 1);
                }
            } else {
                const removedReceipt = this.detail.orders.find(
                    (o) => o.receipt === receiptCode
                );

                if (removedReceipt) {
                    this.detail.orders = this.detail.orders.filter(
                        (o) => o.receipt !== receiptCode
                    );
                    const newReceipt = {
                        order_id: removedReceipt.order_id,
                        receipt_code: removedReceipt.receipt,
                        dest: removedReceipt.regency,
                        isExpanded: false,
                        total_qty: removedReceipt.total_qty,
                        total_weight: removedReceipt.total_weight,
                        total_weight_volume: removedReceipt.total_weight_volume,
                        items: removedReceipt.items,
                    };
                    if (
                        !this.removedReceiptsData.some(
                            (r) => r.receipt_code === receiptCode
                        )
                    ) {
                        this.removedReceiptsData.push(newReceipt);
                    }
                    if (
                        !this.receipts.some(
                            (r) => r.receipt_code === receiptCode
                        )
                    ) {
                        this.receipts.push(newReceipt);
                    }

                    this.removedReceipts.push(removedReceipt);
                }
            }

            this.updateCheckAllState();
        },
        updateCheckAllState() {
            const currentPageReceipts = this.paginatedReceipts;
            const allCurrentPageChecked = currentPageReceipts.every((receipt) =>
                this.checkedReceipts.some(
                    (checked) => checked.receipt_code === receipt.receipt_code
                )
            );

            this.checkAll =
                currentPageReceipts.length > 0 && allCurrentPageChecked;
        },
        toggleReceipt(receipt) {
            receipt.isExpanded = !receipt.isExpanded;
        },
        async getReceipts() {
            try {
                const res = await Api.get(
                    `${process.env.VUE_APP_SERVICE_URL}/manifest/receipts`,
                    {
                        headers: {
                            Authorization:
                                "Bearer" + localStorage.getItem("token"),
                        },
                    }
                );
                this.allReceipts = res.data.data;
                const combinedReceipts = [
                    ...res.data.data,
                    ...this.removedReceiptsData,
                ];

                this.receipts = combinedReceipts
                    .filter(
                        (receipt) =>
                            !this.detail.orders.some(
                                (o) => o.receipt === receipt.receipt_code
                            ) ||
                            this.removedReceipts.some(
                                (r) => r.receipt === receipt.receipt_code
                            )
                    )
                    .map((receipt) => ({
                        ...receipt,
                        isExpanded: false,
                        isChecked: false,
                    }));
            } catch (error) {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: error.response.data.message,
                });
            }
        },
        formattedDate(param) {
            return moment(param).format("DD MMMM YYYY");
        },
        async getDetailManifest() {
            try {
                const res = await Api.get(
                    `${process.env.VUE_APP_SERVICE_URL}/manifest/detail`,
                    {
                        params: {
                            manifest_id: this.$route.params.id,
                        },
                        headers: {
                            Authorization:
                                "Bearer" + localStorage.getItem("token"),
                        },
                    }
                );
                this.detail = res.data.data;
                this.getReceipts();
            } catch (error) {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: error.response.data.message,
                });
            }
        },
        async initializeData() {
            try {
                await Promise.all([
                    this.getDetailManifest(),
                    this.getReceipts(),
                ]);
                this.isPageLoading = false;
            } catch (error) {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: error.response.data.message,
                });
                this.isPageLoading = false;
            }
        },
        editManifest() {
            this.isLoading = true;
            const allOrders = [
                ...this.detail.orders.filter(
                    (order) =>
                        !this.removedReceipts.some(
                            (r) => r.receipt === order.receipt
                        )
                ),
                ...this.newlyAddedReceipts,
            ];

            const orderIds = allOrders.map((order) => order.order_id);

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/manifest/upsert`,
                {
                    manifest_id: this.$route.params.id,
                    order_id: orderIds,
                },
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.modalConfirm = false;
                    this.modalSuccess = true;
                    this.isLoading = false;
                })
                .catch((err) => {
                    this.modalConfirm = false;
                    this.isLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
    watch: {
        currentPage() {
            this.updateCheckAllState();
        },
    },
    computed: {
        rows() {
            return this.receipts.length;
        },
        paginatedReceipts() {
            const start = (this.currentPage - 1) * this.perPage;
            const end = start + this.perPage;
            return this.receipts.slice(start, end);
        },
        selectedReceipts() {
            return [
                ...this.detail.orders.filter(
                    (order) =>
                        !this.removedReceipts.some(
                            (r) => r.receipt === order.receipt
                        )
                ),
                ...this.newlyAddedReceipts,
            ];
        },
        pageStart() {
            return this.paginatedReceipts === 0
                ? 0
                : (this.currentPage - 1) * this.perPage + 1;
        },
        pageEnd() {
            return Math.min(
                Number(this.pageStart) + Number(this.perPage) - 1,
                this.rows
            );
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .page-link {
    background-color: #f3f5f7 !important;
}
::v-deep .page-item.active .page-link {
    background-color: #1291ff !important;
}
.pl-filter-search {
    padding-left: 40px !important;
}
.magnifier-icon.left {
    position: absolute;
    top: 7px;
    left: 12px;
    z-index: 999;
}
.rotate-90 {
    transform: rotate(90deg);
    transition: transform 0.3s ease;
}
.form-check-input {
    width: 20px;
    height: 20px;
}
.form-check-input:checked {
    background-color: #d9f2e4 !important;
    border-color: #329a5f !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='14' viewBox='0 0 20 14' fill='none'%3E%3Cpath d='M1.83398 7.16667L6.42553 12.0862C6.88676 12.5804 7.6701 12.5804 8.13133 12.0862L18.1673 1.33334' stroke='%23329A5F' stroke-width='1.75' stroke-linecap='round'/%3E%3C/svg%3E") !important;
}
.btn-search {
    background-color: #1291ff !important;
    border: 1px solid #c1cdd7 !important;
    color: #ffffff !important;
}
.btn-search:disabled {
    background-color: #1291ff !important;
    border: 1px solid #c1cdd7 !important;
    color: #ffffff !important;
    opacity: 1 !important;
}
.btn-search:focus {
    background-color: #1291ff !important;
    border: 1px solid #c1cdd7 !important;
    color: #ffffff !important;
    opacity: 1 !important;
}
.thead,
.tfoot {
    background-color: #f9fafb;
}
.btn-create {
    background-color: #1291ff !important;
    color: #ffffff !important;
}
.btn-create:hover {
    background-color: #1291ff !important;
    color: #ffffff !important;
}
.btn-create:focus {
    background-color: #1291ff !important;
    color: #ffffff !important;
}
.end {
    background-color: #d9f2e4;
    color: #267347;
    border: 1px solid #b2e6c8;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.process {
    background-color: #ffe7cc;
    border: 1px solid #ffcf99;
    color: #995100;
}
.ontheway {
    background-color: #cce7ff;
    border: 1px solid #99d0ff;
    color: #005299;
}
.btn-delete {
    border: 1px solid #ff8600;
    background-color: transparent;
    color: #ff8600;
    border-radius: 8px !important;
}
.btn-edit {
    background-color: #1291ff;
    color: #ffffff;
    border-radius: 8px !important;
}
.btn-delete:hover {
    border: 1px solid #ff8600;
    background-color: transparent;
    color: #ff8600;
    border-radius: 8px !important;
}
.btn-edit:hover {
    background-color: #1291ff;
    color: #ffffff;
    border-radius: 8px !important;
}
.btn-delete:active {
    border: 1px solid #ff8600 !important;
    background-color: transparent !important;
    color: #ff8600 !important;
    border-radius: 8px !important;
}
.btn-edit:active {
    background-color: #1291ff !important;
    color: #ffffff !important;
    border-radius: 8px !important;
}
.input-read-only {
    background-color: #f3f5f7 !important;
    border: 1px solid #c1cdd7 !important;
}
.input-read-only:focus {
    background-color: #f3f5f7 !important;
    border: 1px solid #c1cdd7 !important;
}
.input-icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
}
.pl-40 {
    padding-left: 40px;
}
</style>
