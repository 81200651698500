<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="fw-bold">
            <router-link to="/walk-in" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </router-link>
        </div>
        <div class="box mt-3">
            <div
                class="d-flex justify-content-between align-items-center flex-wrap"
            >
                <h4 class="mb-0 fw-bold col-12 col-lg-auto">Data Customer</h4>
                <div class="d-flex align-items-center gap-3 col-12 col-lg-auto">
                    <a
                        href="#"
                        class="d-flex align-items-center btn btn-outline-yellow"
                        @click="modalDelete = true"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="24"
                            viewBox="0 0 29 28"
                            fill="none"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M24.4183 6.11684C24.8722 6.11684 25.25 6.49367 25.25 6.97316V7.41649C25.25 7.88432 24.8722 8.27282 24.4183 8.27282H5.08283C4.62784 8.27282 4.25 7.88432 4.25 7.41649V6.97316C4.25 6.49367 4.62784 6.11684 5.08283 6.11684H8.48449C9.17549 6.11684 9.77685 5.62567 9.9323 4.93268L10.1104 4.13702C10.3873 3.0532 11.2984 2.33337 12.3411 2.33337H17.1588C18.1902 2.33337 19.1116 3.0532 19.3782 4.07986L19.5688 4.93151C19.7231 5.62567 20.3245 6.11684 21.0166 6.11684H24.4183ZM22.6901 22.3231C23.0453 19.0133 23.6671 11.15 23.6671 11.0707C23.6898 10.8304 23.6115 10.6029 23.456 10.4197C23.2892 10.2482 23.0782 10.1467 22.8456 10.1467H6.66328C6.42954 10.1467 6.20715 10.2482 6.05284 10.4197C5.89626 10.6029 5.8191 10.8304 5.83045 11.0707C5.83253 11.0853 5.85484 11.3622 5.89214 11.8253C6.05785 13.8824 6.51936 19.6119 6.81759 22.3231C7.02863 24.3204 8.33915 25.5757 10.2374 25.6212C11.7022 25.655 13.2113 25.6667 14.7544 25.6667C16.2079 25.6667 17.6841 25.655 19.1943 25.6212C21.1584 25.5874 22.4677 24.3542 22.6901 22.3231Z"
                                fill="#FF8600"
                            />
                            <path
                                d="M11.75 14V22"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                            <path
                                d="M17.75 14V22"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                        </svg>
                        <p class="mb-0">Hapus Data</p>
                    </a>
                    <button
                        class="d-flex align-items-center btn btn-primary"
                        @click="goToEdit()"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="24"
                            viewBox="0 0 29 28"
                            fill="none"
                        >
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.1891 23.3659L21.4399 10.1097C21.997 9.39482 22.1951 8.56835 22.0094 7.72682C21.8485 6.9618 21.378 6.2344 20.6723 5.68258L18.9515 4.31557C17.4535 3.12415 15.5965 3.24956 14.5318 4.61657L13.3804 6.11024C13.2319 6.29711 13.269 6.57302 13.4547 6.72351C13.4547 6.72351 16.364 9.0562 16.4259 9.10637C16.624 9.29449 16.7726 9.54532 16.8097 9.84631C16.8716 10.4358 16.4631 10.9876 15.8565 11.0628C15.5717 11.1004 15.2993 11.0127 15.1013 10.8496L12.0434 8.4166C11.8948 8.30498 11.672 8.32881 11.5482 8.4793L4.28099 17.8853C3.81055 18.4748 3.64961 19.2398 3.81055 19.9797L4.73906 24.0055C4.78858 24.2187 4.97428 24.3692 5.19713 24.3692L9.28258 24.319C10.0254 24.3065 10.7187 23.9679 11.1891 23.3659ZM16.9096 22.1121H23.5714C24.2214 22.1121 24.75 22.6476 24.75 23.3061C24.75 23.9657 24.2214 24.5 23.5714 24.5H16.9096C16.2597 24.5 15.731 23.9657 15.731 23.3061C15.731 22.6476 16.2597 22.1121 16.9096 22.1121Z"
                                fill="#FAFCFF"
                            />
                        </svg>
                        <p class="mb-0">Edit Data</p>
                    </button>
                </div>
            </div>
            <div class="row mt-4">
                <div class="col-lg-6">
                    <label class="fw-bold mb-1">
                        Nama Customer
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <input
                        v-model="detail.sender_name"
                        type="text"
                        class="form-control"
                        disabled
                    />
                </div>
                <div class="col-lg-6">
                    <label class="fw-bold mb-1">
                        Nomor Handphone Pengirim
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <span class="input-group-text">+62</span>
                        <input
                            v-model="detail.sender_phone"
                            type="text"
                            class="form-control form-phone"
                            disabled
                        />
                    </div>
                </div>
                <div class="col-lg-6 mt-3">
                    <label class="fw-bold mb-1">
                        Kota/Kabupaten Asal
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <input
                        v-model="detail.origin_regency"
                        type="text"
                        class="form-control"
                        disabled
                    />
                </div>
                <div class="col-lg-6 mt-3">
                    <label class="fw-bold mb-1">
                        Kota/Kabupaten Tujuan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <input
                        v-model="detail.dest_regency"
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nama pengirim"
                        disabled
                    />
                </div>
            </div>
        </div>
        <div class="box mt-4">
            <h4 class="fw-bold mb-0">Hasil Ukur - Timbang Barang</h4>
            <div class="table-responsive-custom">
                <table class="table table-bordered mt-4">
                    <thead class="table-light">
                        <tr>
                            <th class="text-center" scope="col">No</th>
                            <th class="text-center" scope="col">Jumlah Koli</th>
                            <th class="text-center" scope="col">Deskripsi</th>
                            <th class="text-center" scope="col">
                                Dimensi Barang (Cm)
                            </th>
                            <th class="text-center" scope="col">Berat (Kg)</th>
                            <th class="text-center" scope="col">
                                Berat Volume (Kg)
                            </th>
                            <th class="text-center" scope="col">
                                Kubikasi (Kg3)
                            </th>
                            <th class="text-center" scope="col">
                                Perlindungan
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in detail.items" :key="index">
                            <td class="text-center">{{ index + 1 }}</td>
                            <td class="text-center">{{ item.qty }}</td>
                            <td>{{ item.description }}</td>
                            <td class="text-center">
                                {{ item.width }} Cm X {{ item.length }} Cm X
                                {{ item.height }} Cm
                            </td>
                            <td
                                :class="{
                                    'bc-green':
                                        customRound(item.weight) >
                                        customRound(item.weight_volume),
                                    'text-center': true,
                                }"
                            >
                                {{ customRound(item.weight) }} Kg
                            </td>
                            <td
                                :class="{
                                    'bc-green':
                                        customRound(item.weight_volume) >
                                        customRound(item.weight),
                                    'text-center': true,
                                }"
                            >
                                {{ customRound(item.weight_volume) }} Kg
                            </td>
                            <td class="text-center">
                                {{ customRound(item.cubic) }} Kg3
                            </td>
                            <td class="capitalize">
                                <div v-if="item.packing">
                                    <div
                                        v-for="(
                                            pack, index
                                        ) in item.packing.split(',')"
                                        :key="index"
                                    >
                                        <span class="tag gray-new radius pb-0">
                                            {{ pack.replace(/_/g, " ") }}
                                        </span>
                                    </div>
                                </div>
                                <div v-else>
                                    <span>-</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody>
                        <tr>
                            <td class="p-footer" colspan="4">
                                <div class="pull-right fw-bold">Total</div>
                            </td>
                            <td class="text-center fw-bold p-footer">
                                {{ customRound(totalWeightDefault) }} Kg
                            </td>
                            <td class="text-center fw-bold p-footer">
                                {{ customRound(totalVolumeDefault) }} Kg
                            </td>
                            <td class="text-center fw-bold p-footer">
                                {{ customRound(totalCubicDefault) }} Kg3
                            </td>
                            <td class="p-footer"></td>
                        </tr>
                        <tr>
                            <td class="p-footer" colspan="4">
                                <div class="pull-right fw-bold">
                                    Charge Weight (Kg)
                                </div>
                            </td>
                            <td
                                class="text-center bc-green p-footer"
                                colspan="4"
                            >
                                {{ customRound(chargeWeightDefault) }} Kg
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalDelete"></div>
            <div class="modal-body-chat vsm" v-if="modalDelete">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">Data Pesanan Akan Dihapus</h4>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin menghapus data pesanan ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalDelete = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            @click="deleteItem()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_delete"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_delete"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Data Pesanan Berhasil Dihapus
                    </h4>
                </div>
                <div class="mt-4">
                    <router-link
                        to="/walk-in"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";

export default {
    name: "OrderCashierDetail",
    data() {
        return {
            detail: {
                sender_name: "",
                sender_phone: "",
                origin_regency: "",
                dest_regency: "",
                items: [],
            },
            modalDelete: false,
            modalSuccess: false,
            is_delete: false,
        };
    },
    mounted() {
        this.fetchOrderDetail();
    },
    computed: {
        totalWeightDefault() {
            return this.detail.items.reduce(
                (total, item) => total + this.customRound(Number(item.weight)),
                0
            );
        },
        totalVolumeDefault() {
            return this.detail.items.reduce(
                (total, item) =>
                    total + this.customRound(Number(item.weight_volume)),
                0
            );
        },
        totalCubicDefault() {
            return this.detail.items.reduce(
                (total, item) => total + this.customRound(Number(item.cubic)),
                0
            );
        },
        chargeWeightDefault() {
            return this.detail.items.reduce((total, item) => {
                return (
                    total +
                    Math.max(
                        this.customRound(Number(item.weight)),
                        this.customRound(Number(item.weight_volume))
                    )
                );
            }, 0);
        },
    },
    methods: {
        deleteItem() {
            this.is_delete = true;
            const param = this.$route.query;
            const payload = {
                order_id: param.order_id,
            };
            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/order/walkin/delete`,
                payload,
                {
                    headers: {
                        Accept: "application/json",
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.is_delete = false;
                    this.modalDelete = false;
                    this.modalSuccess = true;
                })
                .catch((err) => {
                    this.is_delete = false;
                    this.$toast.error(err.response.data.message, {
                        position: "top-right",
                        timeout: 2000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: false,
                        icon: true,
                        rtl: false,
                    });
                    this.is_request = false;
                    this.modalRequest = false;
                });
        },
        goToEdit() {
            this.$router.push({
                name: "walk-in-edit",
                query: {
                    order_id: this.$route.query.order_id,
                },
            });
        },
        fetchOrderDetail() {
            const orderId = this.$route.query.order_id;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/order/walkin/detail?order_id=${orderId}`,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.detail = res.data.data;
                })
                .catch((err) => {
                    const errorMessage =
                        err.response?.data?.message ||
                        "An error occurred while fetching order details";
                    this.$toast.error(errorMessage, {
                        position: "top-right",
                        timeout: 2000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: false,
                        icon: true,
                        rtl: false,
                    });
                });
        },
    },
};
</script>

<style scoped>
.w-30 {
    width: 30px;
}
.w-120 {
    width: 120px;
}
.not-read-only.table > :not(caption) > * > * {
    border: unset;
}
.not-read-only.table-bordered > :not(caption) > * {
    border: unset;
}
.not-read-only.table > :not(caption) > * > * {
    background-color: unset;
}
tr {
    border-radius: 10px !important;
}
tr td.custom {
    background-color: #ebf6ff !important;
}
.w-handling {
    width: 300px;
}
table tbody tr:first-child td:first-child {
    border-top-left-radius: 6px;
}

table tbody tr:first-child td:last-child {
    border-top-right-radius: 6px;
}

table tbody tr:last-child td:first-child {
    border-bottom-left-radius: 6px;
}

table tbody tr:last-child td:last-child {
    border-bottom-right-radius: 6px;
}
.padding-body::after {
    height: 10px;
    display: table-row;
    content: "";
}
.result.table > :not(caption) > * > * {
    padding: 0.2rem 0.5rem;
}
.form-control.green {
    background: #d9f2e4;
    border: 1px solid #8cd9ad !important;
    color: #329a5f !important;
}
.form-control.green[disabled] {
    background: #d9f2e4;
    border: 1px solid #8cd9ad !important;
    color: #329a5f !important;
}
.w-desc {
    width: 160px;
}
.w-koli {
    width: 60px;
}
.radius-select {
    border-radius: 10px;
    padding: 8px 12px;
}
.w-300 {
    width: 300px;
}
.scroll-select {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}
.dropdown-menu {
    padding: 5px 10px 0px 10px;
}
.form-check-input:checked {
    background-color: #329a5f;
    border: #329a5f;
}
div::-webkit-scrollbar {
    display: none;
}
td.bc-green {
    background-color: #d9f2e4;
    color: #329a5f;
    font-weight: 600 !important;
}
</style>
