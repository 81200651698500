<template>
    <div class="padding-container">
        <div class="box-manifest mt-4 m-mt-0">
            <div class="mb-3">
                <h3 class="fw-bold capitalize">
                    Daftar Follow Up -
                    <template v-if="prospect_type == ''">Semua</template
                    ><template v-else>{{
                        prospect_type.split("_").join(" ")
                    }}</template>
                </h3>
            </div>
            <div class="row gy-3 mt-3">
                <div class="col-12 col-lg-5">
                    <div class="position-relative">
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control form-control-lg pl-search w-search border-search"
                                placeholder="Cari nama customer"
                                v-model="search"
                            />
                            <button
                                class="btn btn-primary px-4"
                                type="button"
                                @click="searchData()"
                            >
                                Cari
                            </button>
                        </div>
                        <span
                            class="search-icon left position-absolute top-50 start-0 translate-middle-y ms-3"
                        >
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                </div>
                <div class="col-12 col-lg-3 col-xl-2">
                    <button
                        class="btn btn-outline-yellow btn-filter transparent w-100"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
            </div>
            <div class="box mt-3 position-relative p-3" v-if="isFilter">
                <div class="row g-3">
                    <div
                        class="col-12"
                        v-bind:class="{
                            'col-lg-6': !isRole(managerRoles),
                            'col-lg-4': isRole(managerRoles),
                        }"
                    >
                        <label class="mb-2 fw-bold" for="serviceDropdown"
                            >Jenis Layanan</label
                        >
                        <a
                            href="javascript:void(0)"
                            class="dropdown-toggle text-gray-dashboard w-100"
                            id="serviceDropdown"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            aria-expanded="false"
                        >
                            <div class="radius-select">
                                <div
                                    class="d-flex align-items-center justify-content-between w-100 gap-2"
                                >
                                    <div
                                        class="scroll-select overflow-hidden text-truncate"
                                    >
                                        <template
                                            v-if="selectedServices.length === 0"
                                        >
                                            <p class="placeholder-custom m-0">
                                                Pilih jenis layanan
                                            </p>
                                        </template>
                                        <template v-else>
                                            {{
                                                selectedServices
                                                    .map((s) =>
                                                        s.name
                                                            .split("_")
                                                            .join(" ")
                                                    )
                                                    .join(", ")
                                            }}
                                        </template>
                                    </div>
                                    <div
                                        class="arrow-custom flex-shrink-0"
                                    ></div>
                                </div>
                            </div>
                        </a>
                        <ul
                            class="dropdown-menu"
                            aria-labelledby="serviceDropdown"
                        >
                            <li
                                v-for="(serviceOption, index) in services"
                                :key="serviceOption.id"
                                class="px-2 py-1"
                                :class="{
                                    'border-bottom':
                                        index !== services.length - 1,
                                }"
                            >
                                <div class="form-check cursor-pointer">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        :id="serviceOption.id"
                                        :value="serviceOption"
                                        v-model="selectedServices"
                                        @change="updateService()"
                                    />
                                    <label
                                        class="form-check-label w-100"
                                        :for="serviceOption.id"
                                    >
                                        {{
                                            serviceOption.name
                                                .split("_")
                                                .join(" ")
                                        }}
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div
                        class="col-12"
                        v-bind:class="{
                            'col-lg-6': !isRole(managerRoles),
                            'col-lg-4': isRole(managerRoles),
                        }"
                    >
                        <label class="mb-2 fw-bold" for="categoryDropdown"
                            >Kategori</label
                        >
                        <a
                            href="javascript:void(0)"
                            class="dropdown-toggle text-gray-dashboard w-100"
                            id="categoryDropdown"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            aria-expanded="false"
                        >
                            <div class="radius-select">
                                <div
                                    class="d-flex align-items-center justify-content-between w-100 gap-2"
                                >
                                    <div
                                        class="scroll-select overflow-hidden text-truncate"
                                    >
                                        <template
                                            v-if="
                                                selectedCategories.length === 0
                                            "
                                        >
                                            <p class="placeholder-custom m-0">
                                                Pilih kategori
                                            </p>
                                        </template>
                                        <template v-else>
                                            {{
                                                selectedCategories
                                                    .map((c) => c.label)
                                                    .join(", ")
                                            }}
                                        </template>
                                    </div>
                                    <div
                                        class="arrow-custom flex-shrink-0"
                                    ></div>
                                </div>
                            </div>
                        </a>
                        <ul
                            class="dropdown-menu"
                            aria-labelledby="categoryDropdown"
                        >
                            <li
                                v-for="(categoryOption, index) in categories"
                                :key="categoryOption.type"
                                class="px-2 py-1"
                                :class="{
                                    'border-bottom':
                                        index !== categories.length - 1,
                                }"
                            >
                                <div class="form-check cursor-pointer">
                                    <input
                                        class="form-check-input"
                                        type="checkbox"
                                        :id="categoryOption.type"
                                        :value="categoryOption"
                                        v-model="selectedCategories"
                                        @change="updateCategory()"
                                    />
                                    <label
                                        class="form-check-label w-100"
                                        :for="categoryOption.type"
                                    >
                                        {{ categoryOption.label }}
                                    </label>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="col-12 col-lg-4" v-show="isRole(managerRoles)">
                        <label class="mb-2 fw-bold">CRM Specialist</label>
                        <multiselect
                            v-model="specialist"
                            :options="specialists"
                            :show-labels="false"
                            label="name"
                            track-by="id"
                            placeholder="Pilih CRM Specialist"
                            class="multi-form-custom"
                        >
                        </multiselect>
                    </div>
                    <div class="col-12 d-flex gap-2">
                        <div class="col-6">
                            <button
                                class="btn btn-outline-yellow w-100"
                                @click="clearFilter()"
                                :disabled="
                                    branch == null &&
                                    service == null &&
                                    specialist == null &&
                                    category == null
                                "
                            >
                                Clear
                            </button>
                        </div>
                        <div class="col-6">
                            <button
                                class="btn btn-green w-100"
                                :disabled="
                                    branch == null &&
                                    service == null &&
                                    specialist == null &&
                                    category == null
                                "
                                @click="filter()"
                            >
                                Filter
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="d-flex flex-column flex-lg-row align-items-lg-center gap-3 mt-3"
            >
                <div class="text-center text-lg-start">
                    Menampilkan
                    <span class="fw-bold">{{ pageStart }}-{{ pageEnd }}</span>
                    Data dari
                    <span class="fw-bold">{{ data.total_data }}</span>
                    Data
                </div>
                <div class="ms-lg-auto">
                    <div
                        class="d-flex align-items-center gap-2 justify-content-center"
                    >
                        <label for="pageSize" class="mb-0"
                            >Baris per halaman</label
                        >
                        <select
                            id="pageSize"
                            class="form-control form-select w-select outline-yellow"
                            style="width: auto; min-width: 80px"
                            v-model="limit"
                            @change="changeLength()"
                        >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="is_load_data">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    <div class="d-flex align-items-center">
                                        Tanggal Follow Up
                                        <div class="ms-auto">
                                            <a
                                                href="javascript:void(0)"
                                                class="dropdown-toggle ms-2"
                                                id="statusRefund"
                                                data-bs-toggle="dropdown"
                                                data-bs-auto-close="true"
                                                aria-expanded="false"
                                            >
                                                <img
                                                    src="../../assets/dashboard/sort.png"
                                                    width="9"
                                                    alt=""
                                                />
                                            </a>
                                            <ul
                                                class="dropdown-menu"
                                                aria-labelledby="statusRefund"
                                            >
                                                <li>
                                                    <a
                                                        class="dropdown-item"
                                                        href="javascript:void(0)"
                                                        @click="sortBy('asc')"
                                                    >
                                                        Terbaru
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        class="dropdown-item"
                                                        href="javascript:void(0)"
                                                        @click="sortBy('desc')"
                                                    >
                                                        Terlama
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </th>
                                <th class="text-center" scope="col">
                                    Nama Customer
                                </th>
                                <th class="text-center" scope="col">
                                    Aktivitas Terakhir
                                </th>
                                <th class="text-center" scope="col">
                                    Jenis Layanan
                                </th>
                                <th class="text-center" scope="col">
                                    Kategori
                                </th>
                                <th class="text-center" scope="col">
                                    Prediksi
                                </th>
                                <th class="text-center" scope="col">
                                    Cust. Lifetime Value
                                </th>
                                <th
                                    class="text-center"
                                    scope="col"
                                    v-show="isRole(managerRoles)"
                                >
                                    CRM Specialist
                                </th>
                                <th class="text-center" scope="col">Result</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(data, index) in data.list_data"
                                :key="index"
                            >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td class="text-center">
                                    {{
                                        data.created_at ? data.created_at : "-"
                                    }}
                                </td>
                                <td class="">
                                    <router-link
                                        class="text-link"
                                        :to="
                                            `/followup/detail?lead_id=` +
                                            data.lead_id +
                                            `&followup_id=` +
                                            data.followup_id
                                        "
                                    >
                                        {{
                                            data.customer_name
                                                ? data.customer_name
                                                : "-"
                                        }}
                                    </router-link>
                                </td>
                                <td class="capitalize">
                                    {{
                                        data.last_activity
                                            ? data.last_activity
                                            : "-"
                                    }}
                                </td>
                                <td class="capitalize">
                                    {{ data.service_type.split("_").join(" ") }}
                                </td>
                                <td class="capitalize text-center">
                                    <span
                                        class="tag me-0"
                                        v-bind:class="{
                                            'yellow-cro':
                                                data.category == 'New Customer',
                                            'blue-cro':
                                                data.category == 'New Order',
                                            'green-cro':
                                                data.category == 'Repeat Order',
                                        }"
                                    >
                                        {{
                                            data.category ? data.category : "-"
                                        }}
                                    </span>
                                </td>
                                <td class="capitalize">
                                    <div class="pull-right">
                                        <router-link
                                            :to="
                                                `/prediction/edit/` +
                                                data.followup_id
                                            "
                                        >
                                            <u>
                                                <template
                                                    v-if="data.prediction == 0"
                                                >
                                                    Rp0
                                                </template>
                                                <template v-else>
                                                    {{
                                                        currency(
                                                            data.prediction
                                                        )
                                                    }}
                                                </template>
                                                <i
                                                    class="pi pi-pencil ms-1"
                                                ></i>
                                            </u>
                                        </router-link>
                                    </div>
                                </td>
                                <td class="capitalize">
                                    <div class="pull-right">
                                        {{ currency(data.lifetime_value) }}
                                    </div>
                                </td>
                                <td
                                    class="capitalize"
                                    v-show="isRole(managerRoles)"
                                >
                                    {{
                                        data.crm_specialist
                                            ? data.crm_specialist
                                            : "-"
                                    }}
                                </td>
                                <td class="text-center">
                                    <div class="dropdown">
                                        <a
                                            class="dropdown-toggle"
                                            role="button"
                                            href="javascript:void(0)"
                                            :id="'dropdownMenuButton' + index"
                                            data-bs-toggle="dropdown"
                                            data-bs-auto-close="true"
                                            aria-expanded="false"
                                        >
                                            <img
                                                src="../../assets/ellips.png"
                                                width="19"
                                                alt=""
                                            />
                                        </a>
                                        <ul
                                            class="dropdown-menu"
                                            :aria-labelledby="
                                                'dropdownMenuButton' + index
                                            "
                                        >
                                            <li>
                                                <router-link
                                                    class="dropdown-item"
                                                    :to="{
                                                        name: 'crm-prospect-deal',
                                                        params: {
                                                            id: data.followup_id,
                                                        },
                                                    }"
                                                    >Deal</router-link
                                                >
                                            </li>
                                            <li>
                                                <router-link
                                                    class="dropdown-item"
                                                    :to="{
                                                        name: 'crm-prospect-lost',
                                                        params: {
                                                            id: data.followup_id,
                                                            type: 'lost',
                                                        },
                                                    }"
                                                    >Lost</router-link
                                                >
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="text-center mt-5" v-if="data.list_data == 0">
                    <img
                        src="../../assets/no-data-green.png"
                        width="100"
                        alt=""
                    />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada daftar follow up
                    </div>
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <b-pagination-nav
                        v-if="data.list_data.length > 0"
                        v-model="currentPage"
                        :number-of-pages="totalPage"
                        base-url="#"
                        first-number
                        align="right"
                        @input="getLists()"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import Multiselect from "vue-multiselect";

export default {
    components: {
        ListLoader,
        Multiselect,
    },
    props: {
        items: {
            type: Object,
            default: () => {},
        },
        roles: [],
    },
    data() {
        return {
            pageStart: 0,
            pageEnd: 0,
            data: {
                list_data: [],
            },
            search: "",
            is_load_data: false,
            currentPage: 1,
            totalPage: 0,
            limit: 5,
            prospect_type: "",
            isFilter: false,
            branch: null,
            branchs: [],
            service: null,
            selectedServices: [],
            services: [
                {
                    name: "Pengiriman Barang",
                    id: "pengiriman_barang",
                },
                {
                    name: "Sewa Truk",
                    id: "pengiriman_truk",
                },
                {
                    name: "Kirim Mobil",
                    id: "pengiriman_mobil",
                },
                {
                    name: "Kirim Kontainer",
                    id: "pengiriman_via_kontainer",
                },
            ],
            specialist: null,
            specialists: [],
            category: null,
            selectedCategories: [],
            categories: [
                {
                    type: "new_customer",
                    label: "New Customer",
                },
                {
                    type: "new_order",
                    label: "New Order",
                },
                {
                    type: "repeat_order",
                    label: "Repeat Order",
                },
            ],
            managerRoles: ["crm-manager"],
            sort_at: "asc",
        };
    },
    created() {
        this.prospect_type = this.items.name;
        this.getLists();
        this.getSpecialists();
    },
    methods: {
        changeLength() {
            this.currentPage = 1;
            this.getLists();
        },
        updateService() {
            this.service = this.selectedServices.map((s) => s.id).join(",");
        },
        updateCategory() {
            this.category = this.selectedCategories
                .map((c) => c.type)
                .join(",");
        },
        getLists() {
            this.is_load_data = true;
            let payload = {
                search: this.search,
                limit: this.limit,
                filter: this.items.prospect_type,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 0,
            };
            if (this.sort_at) {
                payload.sort_at = this.sort_at;
            }
            if (this.service) {
                payload.service = this.service;
            }
            if (this.category) {
                payload.category = this.category;
            }
            if (this.specialist) {
                payload.specialist_id = this.specialist.id;
            }
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/followup/lists`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.data = res.data.data;
                    this.totalPage = res.data.data.total_page;
                    const totalItems = this.data.list_data.length;
                    const totalData = res.data.data.total_data;
                    this.pageStart =
                        totalItems === 0
                            ? 0
                            : (this.currentPage - 1) * this.limit + 1;
                    this.pageEnd = Math.min(
                        Number(this.pageStart) + Number(this.limit) - 1,
                        totalData
                    );
                    this.is_load_data = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_load_data = false;
                });
        },
        searchData() {
            this.getLists();
            this.currentPage = 0;
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
        sortBy(value) {
            this.sort_at = value;
            this.getLists();
        },
        getSpecialists() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/user`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.specialists = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        filter() {
            this.getLists();
        },
        clearFilter() {
            this.selectedServices = [];
            this.service = null;
            this.selectedCategories = [];
            this.category = null;
            this.specialist = null;
            this.getLists();
        },
    },
    computed: {},
};
</script>

<style lang="scss" scoped>
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.table-responsive-custom {
    .table {
        border-radius: 8px;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #c1cdd7;

        th,
        td {
            border: 1px solid #c1cdd7;
        }
        thead {
            tr:first-child {
                th:first-child {
                    border-top-left-radius: 8px;
                }
                th:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        tbody {
            tr:last-child {
                td:first-child {
                    border-bottom-left-radius: 8px;
                }
                td:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}
td {
    background-color: transparent !important;
}
th {
    background-color: #f3f5f7 !important;
}
::v-deep .page-link {
    background-color: #f3f5f7 !important;
}
::v-deep .page-item.active .page-link {
    background-color: #1291ff !important;
}
.outline-yellow {
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    background-color: transparent !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
.outline-yellow:focus {
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    background-color: transparent !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
.btn-filter.transparent {
    background: transparent !important;
}
.arrow-custom::before {
    position: relative;
    right: 10px;
    top: 10px;
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    content: "";
}
div::-webkit-scrollbar {
    display: none;
}
.radius-select {
    display: flex;
    border: 1px solid #d1d0d4 !important;
    padding: 0px 7px 0 7px !important;
    min-height: 35px !important;
    border-radius: 5px;
}
.scroll-select {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
}
.scroll-select .placeholder-custom {
    margin-bottom: 0;
    color: #adadad;
}
.dropdown-menu {
    padding: 5px 10px 0px 10px;
}
.form-check-input:checked {
    background-color: #329a5f;
    border: #329a5f;
}

.bt-size {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 159px !important;
    height: 44px !important;
    border-radius: 8px;
}
.search-icon-left {
    top: 11px;
    left: 10px;
}
.excel {
    width: 20px !important;
    height: 19.05px !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f6faff !important;
    --bs-table-border-color: #f6faff !important;
    --bs-table-bg: #f6faff !important;
}
.button {
    display: flex;
    border-radius: 12px;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
    height: 44px;
    &-save {
        background: #48a2d4;
        border: 1px solid #48a2d4;
        color: white;
    }
    &-save:is(:hover, :focus) {
        background: #176f9e;
    }
    &-delete {
        background: #e60013;
        border: 1px solid #e60013;
        color: white;
    }
    &-delete:is(:hover, :focus) {
        background: #e94b58;
    }
    &-cancel {
        background: #ffffff;
        border: 1px solid #e94b58;
        color: #e94b58;
    }
    &-cancel:is(:hover, :focus) {
        background: #e94b58;
        border: 1px solid #ffffff;
        color: #ffffff;
    }
}
.pill {
    display: inline-block;
    width: 110px;
    height: 24px;
    border-radius: 40px;
    text-align: center;
    color: white;
    &.red {
        background-color: #e60013;
        border: 1px solid #e60013;
        outline: 1px solid #e60013;
    }

    &.green {
        background-color: #3d8824;
        border: 1px solid #3d8824;
        outline: 1px solid #3d8824;
    }
}
.text-link {
    text-decoration: underline;
    color: #24516a;
}
.pill {
    display: inline-block;
    width: 110px;
    height: 24px;
    border-radius: 40px;
    text-align: center;
    color: white !important;
    &.gray {
        color: #000 !important;
        background-color: #f4f6f8;
        border: 1px solid #f4f6f8;
        outline: 1px solid #f4f6f8;
    }
    &.red {
        background-color: #e60013;
        border: 1px solid #e60013;
        outline: 1px solid #e60013;
    }

    &.green {
        background-color: #3d8824;
        border: 1px solid #3d8824;
        outline: 1px solid #3d8824;
    }
    &.yellow {
        background-color: #fb9727;
        border: 1px solid #fb9727;
        outline: 1px solid #fb9727;
    }
    &.blue {
        background-color: #48a2d4;
        border: 1px solid #48a2d4;
        outline: 1px solid #48a2d4;
    }
}
.prediction-color {
    color: #24516a;
}
</style>
