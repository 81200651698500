<template>
    <div class="padding-container">
        <div class="content-load" v-if="isPageLoading"></div>
        <div id="loader" v-if="isPageLoading"></div>
        <div class="fw-bold">
            <div
                class="text-gray-dashboard size-16 cursor-pointer"
                @click="$router.go(-1)"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </div>
        </div>
        <div class="box-manifest mt-4">
            <h5 class="fw-bold mb-4">Route Management</h5>
            <div class="d-flex align-items-start">
                <img
                    src="../assets/location-dash.svg"
                    alt="location-dash"
                    class="me-3 d-none-sm"
                />
                <div class="row w-100">
                    <div class="col-lg-6 mb-4">
                        <label class="mb-2"
                            >Provinsi Asal
                            <span class="text-yellow">*</span></label
                        >
                        <multiselect
                            v-model="originProvince"
                            :options="provinces"
                            label="name"
                            track-by="id"
                            placeholder="Cari & pilih provinsi asal"
                            class="multi-form-custom magnifier"
                            :show-labels="false"
                            @input="getOriginRegencies()"
                        >
                            <template v-slot:caret>
                                <img
                                    :src="
                                        originProvince
                                            ? magnifierActiveIcon
                                            : magnifierIcon
                                    "
                                    alt="icon"
                                    style="
                                        width: 20px;
                                        height: 20px;
                                        position: absolute;
                                        left: 12px;
                                        top: 42%;
                                        transform: translateY(-50%);
                                    "
                                />
                            </template>
                        </multiselect>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <label class="mb-2"
                            >Kota/Kab. Asal
                            <span class="text-yellow">*</span></label
                        >
                        <multiselect
                            v-model="originRegency"
                            :options="originRegencies"
                            label="name"
                            track-by="id"
                            placeholder="Pilih kota/kab. asal"
                            class="multi-form-custom"
                            :show-labels="false"
                            :disabled="!originProvince"
                        >
                        </multiselect>
                    </div>
                </div>
            </div>
            <div
                v-for="(transit, index) in transitPoints"
                :key="index"
                class="row"
            >
                <div class="d-flex align-items-start">
                    <img
                        src="../assets/to-dash.svg"
                        alt="to dash"
                        class="me-3 d-none-sm"
                    />
                    <div class="row w-100">
                        <div class="col-lg-6 mb-4">
                            <label class="mb-2">
                                Kota/Kabupaten transit {{ index + 1 }}
                                <span class="text-yellow">*</span>
                            </label>
                            <multiselect
                                v-model="transit.value"
                                :options="regencies"
                                label="name"
                                track-by="id"
                                placeholder="Cari & pilih kota transit"
                                class="multi-form-custom magnifier"
                                :show-labels="false"
                            >
                                <template v-slot:caret>
                                    <img
                                        :src="
                                            transit.value
                                                ? magnifierActiveIcon
                                                : magnifierIcon
                                        "
                                        alt="icon"
                                        style="
                                            width: 20px;
                                            height: 20px;
                                            position: absolute;
                                            left: 12px;
                                            top: 42%;
                                            transform: translateY(-50%);
                                        "
                                    />
                                </template>
                            </multiselect>
                        </div>
                        <div class="col-lg-4 mb-4">
                            <label class="mb-2 invisible-md">
                                Kota {{ index + 1 }}
                                <span class="text-yellow">*</span>
                            </label>
                            <button
                                class="btn outline-green d-flex align-items-center"
                                @click="removeTransitPoint(index)"
                            >
                                <img
                                    src="../assets/trash-button.svg"
                                    alt="trash"
                                    class="me-2"
                                />
                                Hapus Bagian
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="d-flex align-items-start"
                v-if="transitPoints.length < 3"
            >
                <img
                    src="../assets/to-dash.svg"
                    alt="to dash"
                    class="me-3 d-none-sm"
                />
                <div class="mb-4">
                    <label class="mb-2"
                        >Kota/Kabupaten transit
                        <span class="text-yellow">*</span></label
                    >
                    <button
                        class="btn outline-yellow d-block px-4"
                        @click="addTransitPoint"
                    >
                        <i class="pi pi-plus me-2"></i>
                        Tambah Titik Transit
                    </button>
                </div>
            </div>
            <div class="d-flex align-items-start">
                <img
                    src="../assets/location.svg"
                    alt="location"
                    class="me-3 d-none-sm"
                />
                <div class="row w-100">
                    <div class="col-lg-6 mb-4">
                        <label class="mb-2"
                            >Provinsi Tujuan
                            <span class="text-yellow">*</span></label
                        >
                        <multiselect
                            v-model="destinationProvince"
                            :options="provinces"
                            label="name"
                            track-by="id"
                            placeholder="Cari & pilih provinsi tujuan"
                            class="multi-form-custom magnifier"
                            :show-labels="false"
                            @input="getDestinationRegencies()"
                        >
                            <template v-slot:caret>
                                <img
                                    :src="
                                        destinationProvince
                                            ? magnifierActiveIcon
                                            : magnifierIcon
                                    "
                                    alt="icon"
                                    style="
                                        width: 20px;
                                        height: 20px;
                                        position: absolute;
                                        left: 12px;
                                        top: 42%;
                                        transform: translateY(-50%);
                                    "
                                />
                            </template>
                        </multiselect>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <label class="mb-2"
                            >Kota/Kab. Tujuan
                            <span class="text-yellow">*</span></label
                        >
                        <multiselect
                            v-model="destinationRegency"
                            :options="destinationRegencies"
                            label="name"
                            track-by="id"
                            placeholder="Pilih kota/kab. tujuan"
                            class="multi-form-custom"
                            :show-labels="false"
                            :disabled="!destinationProvince"
                        >
                        </multiselect>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div
                class="col-lg-12"
                v-if="
                    !originRegency ||
                    !destinationRegency ||
                    (transitPoints.length > 0 &&
                        transitPoints.some((point) => !point.value))
                "
            >
                <div class="box-manifest mt-4">
                    <h5 class="fw-bold">Lead Time</h5>
                    <div class="d-flex justify-content-center mt-5">
                        <img src="../assets/empty.svg" alt="empty" />
                    </div>
                    <p class="text-gray text-center mt-5">
                        Isi Kota/Kab. asal dan tujuan terlebih dahulu
                    </p>
                    <button class="btn btn-primary w-100" disabled>
                        Simpan
                    </button>
                </div>
            </div>
            <div class="col-lg-12" v-else>
                <div class="box-manifest mt-4">
                    <h5 class="fw-bold mb-4">Lead Time</h5>
                    <div class="mb-4">
                        <div
                            class="d-flex justify-content-between align-items-center header-sidebar p-3"
                        >
                            <h5 class="fw-bold mb-0">Informasi Lead Time</h5>
                        </div>
                        <div class="body-sidebar px-3 py-2">
                            <div class="row">
                                <div class="col-lg-6 my-3">
                                    <label class="mb-2"
                                        >Jenis Layanan
                                        <span class="text-yellow"
                                            >*</span
                                        ></label
                                    >
                                    <multiselect
                                        v-model="leadTimeInfo.service"
                                        :options="services"
                                        label="name"
                                        track-by="id"
                                        placeholder="Pilih jenis layanan"
                                        class="multi-form-custom"
                                        :show-labels="false"
                                    >
                                    </multiselect>
                                </div>
                                <div class="col-lg-6 my-3">
                                    <label class="mb-2"
                                        >Lead Time
                                        <span class="text-yellow"
                                            >*</span
                                        ></label
                                    >
                                    <div class="input-group mb-3">
                                        <input
                                            type="text"
                                            class="form-control br-0"
                                            placeholder="Masukkan lead time"
                                            v-model="leadTimeInfo.leadTime"
                                        />
                                        <span class="input-group-text"
                                            >Hari</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        class="btn btn-primary w-100"
                        @click="modalConfirm = true"
                        :disabled="!isFormValid"
                    >
                        Simpan
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Data route & Lead Time akan disimpan
                    </h4>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirm = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            @click="saveData()"
                            :disabled="isLoading"
                        >
                            <span
                                v-if="isLoading"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Data route & Lead Time sudah disimpan
                    </h4>
                </div>
                <div class="mt-4">
                    <router-link
                        to="/route-and-lead"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";
import Multiselect from "vue-multiselect";
import magnifierDisabled from "../assets/magnifier-disabled.svg";
import magnifier from "../assets/magnifier.svg";

export default {
    components: {
        Multiselect,
    },
    data() {
        return {
            isLoading: false,
            modalConfirm: false,
            modalSuccess: false,
            leadTimeInfo: {
                service: null,
                leadTime: null,
            },
            services: [
                {
                    name: "Pengiriman Barang",
                    id: "pengiriman_barang",
                },
                {
                    name: "Pengiriman Motor",
                    id: "pengiriman_motor",
                },
                {
                    name: "Pengiriman Mobil",
                    id: "pengiriman_mobil",
                },
                {
                    name: "Sewa Kontainer 20 Feet",
                    id: "sewa_container_20ft",
                },
                {
                    name: "Sewa Kontainer 40 Feet",
                    id: "sewa_container_40ft",
                },
            ],
            transitPoints: [],
            magnifierIcon: magnifierDisabled,
            magnifierActiveIcon: magnifier,
            isPageLoading: false,
            originProvince: null,
            originRegency: null,
            destinationProvince: null,
            destinationRegency: null,
            originRegencies: [],
            destinationRegencies: [],
            provinces: [],
            regencies: [],
            routeId: null,
        };
    },
    created() {
        this.routeId = this.$route.params.id;
        this.isPageLoading = true;
        this.getProvinces()
            .then(() => this.getRegencies())
            .then(() => this.getRouteDetails())
            .catch(() => {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: "Failed to load initial data",
                });
            })
            .finally(() => {
                this.isPageLoading = false;
            });
    },
    computed: {
        isFormValid() {
            if (!this.originProvince || !this.originRegency) {
                return false;
            }
            if (!this.destinationProvince || !this.destinationRegency) {
                return false;
            }
            if (this.transitPoints.some((transit) => !transit.value)) {
                return false;
            }
            if (!this.leadTimeInfo.service || !this.leadTimeInfo.leadTime) {
                return false;
            }
            if (
                isNaN(this.leadTimeInfo.leadTime) ||
                this.leadTimeInfo.leadTime <= 0
            ) {
                return false;
            }
            return true;
        },
    },
    methods: {
        async getRouteDetails() {
            try {
                const response = await Api.get(
                    `${process.env.VUE_APP_SERVICE_URL}/ho/routes/detail`,
                    {
                        params: { route_id: this.routeId },
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                        },
                    }
                );

                const routeData = response.data.data;

                this.originProvince = this.provinces.find(
                    (p) => p.id === routeData.province_id
                );

                this.destinationProvince = this.provinces.find(
                    (p) => p.id === routeData.dooring_province_id
                );

                if (this.originProvince) {
                    await this.getOriginRegencies();
                    this.originRegency = this.originRegencies.find(
                        (r) => r.id === routeData.regency_id
                    );
                }

                if (this.destinationProvince) {
                    await this.getDestinationRegencies();
                    this.destinationRegency = this.destinationRegencies.find(
                        (r) => r.id === routeData.dooring_id
                    );
                }

                this.transitPoints = [];
                [
                    routeData.transit1_id,
                    routeData.transit2_id,
                    routeData.transit3_id,
                ]
                    .filter((id) => id)
                    .forEach((id) => {
                        const transitRegency = this.regencies.find(
                            (r) => r.id === id
                        );
                        if (transitRegency) {
                            this.transitPoints.push({ value: transitRegency });
                        }
                    });

                this.leadTimeInfo.service = this.services.find(
                    (s) => s.id === routeData.service_type
                );
                this.leadTimeInfo.leadTime = routeData.leadtime;
            } catch (err) {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text:
                        err.response?.data?.message ||
                        "Failed to fetch route details",
                });
            }
        },
        async saveData() {
            this.isLoading = true;
            try {
                const payload = {
                    route_id: this.routeId,
                    origin_regency_id: this.originRegency?.id,
                    province_id: this.originProvince?.id,
                    transit_1: this.transitPoints[0]?.value?.id || null,
                    transit_2: this.transitPoints[1]?.value?.id || null,
                    transit_3: this.transitPoints[2]?.value?.id || null,
                    dooring_regency_id: this.destinationRegency?.id,
                    dooring_province_id: this.dooring_province_id?.id,
                    service_type: this.leadTimeInfo.service?.id,
                    leadtime: parseInt(this.leadTimeInfo.leadTime),
                };

                await Api.post(
                    `${process.env.VUE_APP_SERVICE_URL}/ho/routes/edit`,
                    payload,
                    {
                        headers: {
                            Authorization:
                                "Bearer" + localStorage.getItem("token"),
                        },
                    }
                );

                this.modalConfirm = false;
                this.modalSuccess = true;
                this.isLoading = false;
            } catch (err) {
                this.modalConfirm = false;
                this.isLoading = false;
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text:
                        err.response?.data?.message || "Failed to update route",
                });
            }
        },
        addTransitPoint() {
            if (this.transitPoints.length < 3) {
                this.transitPoints.push({
                    value: null,
                });
            }
        },
        removeTransitPoint(index) {
            this.transitPoints.splice(index, 1);
        },
        getOriginRegencies() {
            this.originRegency = null;
            if (!this.originProvince) return Promise.resolve();

            return Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`,
                {
                    params: { province_id: this.originProvince.id },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.originRegencies = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },

        getDestinationRegencies() {
            this.destinationRegency = null;
            if (!this.destinationProvince) return Promise.resolve();

            return Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`,
                {
                    params: { province_id: this.destinationProvince.id },
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.destinationRegencies = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getRegencies() {
            return new Promise((resolve, reject) => {
                Api.get(
                    `${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`,
                    {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                        },
                    }
                )
                    .then((res) => {
                        this.regencies = res.data.data;
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getProvinces() {
            return new Promise((resolve, reject) => {
                Api.get(
                    `${process.env.VUE_APP_SERVICE_URL}/master/geo/province`,
                    {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                        },
                    }
                )
                    .then((res) => {
                        this.provinces = res.data.data;
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.d-none-sm {
    display: none;
}
.invisible-md {
    display: none;
}
@media only screen and (min-width: 992px) {
    .d-none-sm {
        display: block !important;
    }
    .invisible-md {
        visibility: hidden !important;
        display: block;
    }
}
@media only screen and (min-width: 435px) {
    .justify-content-sm-between {
        justify-content: space-between !important;
    }
}
::v-deep .multiselect--disabled .multiselect__select {
    background: transparent !important;
}
.br-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.header-sidebar {
    background-color: #f3f5f7;
    border: 1px solid #c1cdd7;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}
.body-sidebar {
    background-color: #ffffff;
    border: 1px solid #c1cdd7;
    border-top: none;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
.outline-yellow {
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    background-color: transparent !important;
    border-radius: 8px;
}
.outline-green {
    border: 1px solid #ff8600 !important;
    color: #ff8600 !important;
    background-color: transparent !important;
    border-radius: 8px;
}
::v-deep .multi-form-custom.magnifier {
    .multiselect__tags {
        padding-left: 40px !important;
        padding-right: 0 !important;
    }
    .multiselect__select {
        padding: 0;
    }
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
</style>
