<template>
    <div class="padding-container">
        <div class="fw-bold">
            <div
                class="text-gray-dashboard size-16 cursor-pointer"
                @click="$router.go(-1)"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </div>
        </div>
        <div class="box-manifest mt-4">
            <div class="row">
                <div class="col-lg-12 my-2">
                    <div class="row">
                        <div class="col-lg-6">
                            <label class="fw-bold mb-2"
                                >Peran <span class="text-yellow">*</span></label
                            >
                            <multiselect
                                v-model="role"
                                :options="roles"
                                label="name"
                                track-by="id"
                                placeholder="Pilih peran user"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 my-2">
                    <label class="fw-bold mb-2"
                        >Nama <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        v-model="name"
                        placeholder="Masukkan nama"
                    />
                </div>
                <div class="col-lg-6 my-2">
                    <label class="fw-bold mb-2"
                        >Email <span class="text-yellow">*</span></label
                    >
                    <input
                        type="email"
                        class="form-control"
                        v-model="email"
                        placeholder="Masukkan email"
                    />
                    <div class="d-flex align-items-center mt-2">
                        <img
                            src="../assets/mini-warning.svg"
                            alt="mini warning"
                            class="me-2"
                        />
                        <small class="text-yellow">Format email salah</small>
                    </div>
                </div>
                <div class="col-lg-6 my-2">
                    <label class="fw-bold mb-2"
                        >Password Akun <span class="text-yellow">*</span></label
                    >
                    <div class="password-container">
                        <input
                            :type="showPassword ? 'text' : 'password'"
                            class="form-control"
                            v-model="password"
                            placeholder="Buat password akun"
                        />
                        <div class="eye-icons">
                            <img
                                :src="showPassword ? eyeShowIcon : eyeHideIcon"
                                :alt="showPassword ? 'eye show' : 'eye hide'"
                                @click="() => togglePasswordVisibility(account)"
                                class="eye-icon"
                            />
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-2">
                        <img
                            src="../assets/mini-warning.svg"
                            alt="mini warning"
                            class="me-2"
                        />
                        <small class="text-yellow"
                            >Password harus terdiri dari min. 6 karakter</small
                        >
                    </div>
                </div>
                <div class="col-lg-6 my-2">
                    <label class="fw-bold mb-2"
                        >Konfirmasi Password Akun
                        <span class="text-yellow">*</span></label
                    >
                    <div class="password-container">
                        <input
                            :type="showConfirmPassword ? 'text' : 'password'"
                            class="form-control"
                            v-model="passwordConfirmation"
                            placeholder="Konfirmasi password akun"
                        />
                        <div class="eye-icons">
                            <img
                                :src="
                                    showConfirmPassword
                                        ? eyeShowIcon
                                        : eyeHideIcon
                                "
                                :alt="
                                    showConfirmPassword
                                        ? 'eye show'
                                        : 'eye hide'
                                "
                                @click="
                                    () =>
                                        toggleConfirmPasswordVisibility(account)
                                "
                                class="eye-icon"
                            />
                        </div>
                    </div>
                    <div class="d-flex align-items-center mt-2">
                        <img
                            src="../assets/mini-warning.svg"
                            alt="mini warning"
                            class="me-2"
                        />
                        <small class="text-yellow">Password tidak sesuai</small>
                    </div>
                </div>
                <div class="col-lg-12 my-2">
                    <div class="row">
                        <div class="col-lg-3">
                            <label class="fw-bold mb-2">Foto Profile</label>
                            <div
                                class="box d-flex flex-column justify-content-center py-5 position-relative cursor-pointer h-14"
                            >
                                <div class="profile-image-preview">
                                    <img
                                        src="../assets/upload-profile.svg"
                                        alt="Profile Preview"
                                        class="img-fluid rounded border-1-rem"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 my-2 d-flex justify-content-end mt-4">
                    <button class="btn btn-create px-5">
                        Simpan Perubahan
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="editAccount">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Perubahan akun user akan disimpan
                    </h4>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <button class="btn btn-outline-yellow w-100 btn-lg">
                            Kembali
                        </button>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                        >
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="editSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Perubahan akun user berhasil disimpan
                    </h4>
                </div>
                <div class="mt-4">
                    <router-link to="#" class="btn btn-primary w-100 btn-lg">
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import eyeHideIcon from "@/assets/eye-hide.svg";
import eyeShowIcon from "@/assets/eye-show.svg";

export default {
    components: {
        Multiselect,
    },
    data() {
        return {
            role: null,
            name: "",
            email: "",
            emailError: "",
            password: "",
            passwordError: "",
            passwordConfirmation: "",
            passwordConfirmationError: "",
            showPassword: false,
            showConfirmPassword: false,
            profileImage: null,
            eyeHideIcon,
            eyeShowIcon,
            roles: [],
            editSuccess: false,
            editAccount: false,
        };
    },
    methods: {
        validateEmail(account) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(account.email)) {
                account.emailError = "Format email salah";
            } else {
                account.emailError = "";
            }
        },
        validatePassword(account) {
            if (account.password.length < 6) {
                account.passwordError =
                    "Password harus terdiri dari min. 6 karakter";
            } else {
                account.passwordError = "";
            }
            if (account.passwordConfirmation) {
                this.validatePasswordConfirmation(account);
            }
        },
        validatePasswordConfirmation(account) {
            if (account.password !== account.passwordConfirmation) {
                account.passwordConfirmationError = "Password tidak sesuai";
            } else {
                account.passwordConfirmationError = "";
            }
        },
        togglePasswordVisibility(account) {
            account.showPassword = !account.showPassword;
        },
        toggleConfirmPasswordVisibility(account) {
            account.showConfirmPassword = !account.showConfirmPassword;
        },
    },
};
</script>

<style lang="scss" scoped>
.border-1-rem {
    border-radius: 1rem !important;
}
.h-14 {
    height: 14rem;
}
.padding-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.profile-image-preview {
    position: relative;
    width: 100%;
    height: 100%;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .change-photo-overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        text-align: center;
        padding: 10px;
        cursor: pointer;
    }
}
.btn-create {
    background-color: #1291ff;
    color: #ffffff;
}
.btn-create:hover {
    background-color: #1291ff;
    color: #ffffff;
}
.btn-create:disabled {
    background-color: #849bae;
    color: #ffffff;
    cursor: not-allowed;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.password-container {
    position: relative;
    .eye-icons {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
    .eye-icon {
        width: 20px;
        height: 20px;
    }
    input {
        padding-right: 40px;
    }
}
</style>
