<template>
    <div class="padding-container">
        <div class="fw-bold">
            <div
                class="text-gray-dashboard size-16 cursor-pointer"
                @click="$router.go(-1)"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </div>
        </div>
        <div class="box-manifest mt-4">
            <h5 class="fw-bold mb-4">Tambah Vendor</h5>
            <div class="row">
                <div class="col-lg-6 mb-4">
                    <label class="mb-2"
                        >Nama Vendor <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control"
                        placeholder="Masukkan nama vendor"
                        v-model="name"
                    />
                </div>
                <div class="col-lg-6 mb-4">
                    <label class="mb-2"
                        >Nomor Telepon <span class="text-yellow">*</span></label
                    >
                    <input
                        type="number"
                        class="form-control"
                        placeholder="Masukkan nomor telepon"
                        v-model="phone"
                    />
                </div>
                <div class="col-lg-6 mb-4">
                    <label class="mb-2"
                        >Email <span class="text-yellow">*</span></label
                    >
                    <input
                        type="email"
                        class="form-control"
                        placeholder="Masukkan email"
                        v-model="email"
                    />
                </div>
                <div class="col-lg-12 mb-4">
                    <label class="mb-2"
                        >Alamat Vendor <span class="text-yellow">*</span></label
                    >
                    <textarea
                        class="form-control"
                        placeholder="Masukkan alamat vendor"
                        v-model="address"
                    ></textarea>
                </div>
            </div>
            <div class="d-flex justify-content-end">
                <button
                    class="btn btn-primary px-4"
                    :disabled="!name || !phone || !email || !address"
                    @click="modalConfirm = true"
                >
                    Simpan Data Vendor
                </button>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">Data vendor akan disimpan</h4>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirm = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            @click="saveData()"
                            :disabled="isLoading"
                        >
                            <span
                                v-if="isLoading"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Data vendor berhasil dibuat
                    </h4>
                </div>
                <router-link
                    to="/vendor-management"
                    href="javascript:void(0)"
                    class="btn btn-primary w-100 btn-lg mt-4"
                >
                    Tutup
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";

export default {
    data() {
        return {
            isLoading: false,
            modalConfirm: false,
            modalSuccess: false,
            name: null,
            phone: null,
            email: null,
            address: null,
        };
    },
    methods: {
        saveData() {
            this.isLoading = true;
            var data = {
                name: this.name,
                phone: this.phone,
                email: this.email,
                address: this.address,
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/vendors/create`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isLoading = false;
                    this.modalSuccess = true;
                    this.modalConfirm = false;
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.modalConfirm = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
</style>
