<template>
    <div class="padding-container">
        <div class="content-load" v-if="is_loading_data"></div>
        <div id="loader" v-if="is_loading_data"></div>
        <notifications group="foo" position="top right" />
        <div class="fw-bold">
            <a
                href="javascript:void(0)"
                @click="$router.go(-1)"
                class="text-gray-dashboard size-16"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </a>
        </div>
        <div class="box mt-4">
            <div class="d-flex align-items-center">
                <h3 class="fw-bold mb-0">Tambah Aktivitas Follow Up</h3>
            </div>
            <div class="row mt-5">
                <div class="col-lg-12">
                    <label class="fw-bold mb-1">
                        Aktivitas
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <select
                        class="form-control form-select"
                        v-model="req.activity"
                    >
                        <option value="">Pilih aktivitas</option>
                        <option value="call">Telfon</option>
                        <option value="whatsapp">Chat WhatsApp</option>
                        <option value="other">Lainnya</option>
                    </select>
                </div>
                <div class="col-lg-12 mt-3">
                    <label class="fw-bold mb-1">
                        Deskripsi Aktivitas
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <textarea
                        cols="30"
                        rows="2"
                        class="form-control"
                        placeholder="Masukkan deskripsi aktivitas"
                        v-model="req.description_activity"
                    ></textarea>
                </div>
                <div class="col-lg-6 mt-3">
                    <label class="fw-bold mb-1">
                        Nama Customer
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <input
                        type="text"
                        class="form-control"
                        :value="detailLeads.name"
                        placeholder="Masukkan nama customer"
                        disabled
                    />
                </div>
                <div class="col-lg-6 mt-3">
                    <label class="fw-bold mb-1"
                        >Nomor Handphone
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <div class="input-group mb-0">
                        <span class="input-group-text">+62</span>
                        <input
                            type="text"
                            class="form-control form-phone"
                            :value="detailLeads.phone_parser"
                            placeholder="Masukkan nomor telepon"
                            disabled
                        />
                    </div>
                </div>
                <div class="col-lg-6 mt-3">
                    <label class="fw-bold mb-1">
                        Prioritas
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <select
                        class="form-control form-select"
                        v-model="req.priority"
                    >
                        <option value="">Pilih prioritas</option>
                        <option value="low">Rendah</option>
                        <option value="high">Tinggi</option>
                    </select>
                </div>
                <div class="col-lg-6 mt-3" v-show="isRole(managerRoles)">
                    <label class="fw-bold mb-1">
                        CRM Specialist
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <multiselect
                        v-model="specialist"
                        :options="specialists"
                        label="name"
                        track-by="id"
                        placeholder="Pilih CRM specialist"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                    </multiselect>
                </div>
                <div class="col-lg-12 mt-3">
                    <label class="fw-bold mb-2"> Apakah ada prediksi? </label>
                    <div class="d-flex align-items-center">
                        <div class="form-check me-4">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="presiction_question"
                                id="true"
                                :value="true"
                                v-model="req.is_prediction"
                                :disabled="!canSelectPrediction"
                            />
                            <label class="form-check-label" for="true">
                                Ada
                            </label>
                        </div>
                        <div class="form-check">
                            <input
                                class="form-check-input"
                                type="radio"
                                name="presiction_question"
                                id="false"
                                :value="false"
                                v-model="req.is_prediction"
                                :disabled="!canSelectPrediction"
                            />
                            <label class="form-check-label" for="false">
                                Tidak Ada
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="row"
                v-if="isBasicFormFilled && req.is_prediction === true"
            >
                <div class="col-lg-12 mt-3">
                    <label class="fw-bold mb-1">
                        Jenis Layanan
                        <span class="text-danger fw-bold">*</span>
                    </label>
                    <select
                        class="form-control form-select"
                        v-model="req.service"
                    >
                        <option value="">Pilih jenis layanan</option>
                        <option value="pengiriman_barang">
                            Pengiriman Barang
                        </option>
                        <option value="pengiriman_motor">Kirim Motor</option>
                        <option value="pengiriman_mobil">Kirim Mobil</option>
                        <option value="sewa_container_20ft">
                            Sewa Kontainer 20 Feet
                        </option>
                        <option value="sewa_container_40ft">
                            Sewa Kontainer 40 Feet
                        </option>
                    </select>
                </div>
                <div class="col-lg-12" v-if="req.service">
                    <div class="row mt-3">
                        <div class="col-lg-6 mt-3">
                            <label class="fw-bold mb-1">
                                Kota/Kab. Asal
                                <span class="text-danger fw-bold">*</span>
                            </label>
                            <multiselect
                                v-model="req.regency"
                                :options="regencies"
                                label="name"
                                track-by="id"
                                placeholder="Cari kota/kabupaten"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div class="col-lg-6 mt-3">
                            <label class="fw-bold mb-1">
                                Kota/Kab. Tujuan
                                <span class="text-danger fw-bold">*</span>
                            </label>
                            <multiselect
                                v-model="req.destination"
                                :options="destinations"
                                label="name"
                                track-by="id"
                                placeholder="Cari kota/kabupaten"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div class="col-lg-12 mt-2">
                            <label class="fw-bold mb-1">
                                Detail Alamat
                                <span class="text-danger fw-bold">*</span>
                            </label>
                            <textarea
                                cols="30"
                                rows="2"
                                class="form-control"
                                placeholder="Masukkan daerah"
                                v-model="req.address"
                            ></textarea>
                        </div>
                        <div
                            class="mt-3 col-lg-6"
                            v-if="
                                req.service &&
                                req.service === 'pengiriman_motor'
                            "
                        >
                            <label class="mb-2"
                                >Tipe Motor
                                <span class="text-yellow">*</span></label
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="req.bikeType"
                                @input="handleTypeSearch()"
                                @focus="handleFocus()"
                                placeholder="Masukkan tipe motor"
                            />
                            <div
                                class="suggestions-container"
                                v-if="showSuggestions"
                            >
                                <p class="suggestion-title">
                                    Pilih atau Tambahkan Tipe Motor
                                </p>
                                <div class="suggestion-item-container">
                                    <div
                                        class="suggestion-item-none"
                                        v-if="typeSuggestions.length === 0"
                                    >
                                        <i>Tipe motor tidak ditemukan</i>
                                    </div>
                                    <div
                                        v-else
                                        v-for="(
                                            suggestion, index
                                        ) in typeSuggestions"
                                        :key="index"
                                        class="suggestion-item"
                                        @click="selectType(suggestion)"
                                    >
                                        {{ suggestion.name }}
                                    </div>
                                </div>
                                <hr class="hr-suggestion" />
                                <p
                                    class="suggestion-title d-flex align-items-center"
                                >
                                    <span>Tambahkan:</span>
                                    <span
                                        v-if="isAddVehicle"
                                        class="spinner-border text-primary ms-2"
                                        role="status"
                                    >
                                    </span>
                                    <span
                                        v-else
                                        class="text-suggestion-dark of-y pe-3 ps-1 cursor-pointer"
                                        @click="createNewVehicle('bike')"
                                        >{{ req.bikeType }}</span
                                    >
                                </p>
                            </div>
                        </div>
                        <div
                            class="col-lg-6 mt-3"
                            v-if="
                                req.service &&
                                req.service === 'pengiriman_motor'
                            "
                        >
                            <label class="mb-2"
                                >Kapasitas Motor
                                <span class="text-yellow">*</span></label
                            >
                            <multiselect
                                v-model="req.capacity"
                                :options="capacities"
                                label="name"
                                track-by="id"
                                placeholder="Pilih kapasitas motor"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div
                            class="mt-3 col-lg-6"
                            v-if="
                                req.service &&
                                req.service === 'pengiriman_mobil'
                            "
                        >
                            <label class="mb-2"
                                >Tipe Mobil
                                <span class="text-yellow">*</span></label
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="req.carType"
                                @input="handleCarTypeSearch()"
                                @focus="handleCarFocus()"
                                placeholder="Masukkan tipe mobil"
                            />
                            <div
                                class="suggestions-container"
                                v-if="showCarSuggestions"
                            >
                                <p class="suggestion-title">
                                    Pilih atau Tambahkan Tipe Mobil
                                </p>
                                <div class="suggestion-item-container">
                                    <div
                                        class="suggestion-item-none"
                                        v-if="carTypeSuggestions.length === 0"
                                    >
                                        <i>Tipe mobil tidak ditemukan</i>
                                    </div>
                                    <div
                                        v-else
                                        v-for="(
                                            suggestion, index
                                        ) in carTypeSuggestions"
                                        :key="index"
                                        class="suggestion-item"
                                        @click="selectCarType(suggestion)"
                                    >
                                        {{ suggestion.name }}
                                    </div>
                                </div>
                                <hr class="hr-suggestion" />
                                <p
                                    class="suggestion-title d-flex align-items-center"
                                >
                                    <span>Tambahkan:</span>
                                    <span
                                        v-if="isAddCarVehicle"
                                        class="spinner-border text-primary ms-2"
                                        role="status"
                                    >
                                    </span>
                                    <span
                                        v-else
                                        class="text-suggestion-dark of-y pe-3 ps-1 cursor-pointer"
                                        @click="createNewVehicle('car')"
                                        >{{ req.carType }}</span
                                    >
                                </p>
                            </div>
                        </div>
                        <div
                            class="col-lg-12 mt-3"
                            v-if="
                                req.service &&
                                req.service === 'pengiriman_barang'
                            "
                        >
                            <label class="fw-bold mb-1"> Dimensi Barang </label>
                            <div class="row">
                                <div class="col-lg-4">
                                    <label class="fw-bold mb-1">
                                        Panjang
                                    </label>
                                    <div class="input-group mb-0">
                                        <input
                                            type="text"
                                            class="form-control form-format"
                                            placeholder="Panjang"
                                            v-model="req.length"
                                            @input="inputNumber()"
                                        />
                                        <span class="input-group-text">Cm</span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <label class="fw-bold mb-1"> Lebar </label>
                                    <div class="input-group mb-0">
                                        <input
                                            type="text"
                                            class="form-control form-format"
                                            placeholder="Lebar"
                                            v-model="req.width"
                                            @input="inputNumber()"
                                        />
                                        <span class="input-group-text">Cm</span>
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <label class="fw-bold mb-1"> Tinggi </label>
                                    <div class="input-group mb-0">
                                        <input
                                            type="text"
                                            class="form-control form-format"
                                            placeholder="Tinggi"
                                            v-model="req.height"
                                            @input="inputNumber()"
                                        />
                                        <span class="input-group-text">Cm</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            class="col-lg-6 mt-3"
                            v-if="
                                req.service &&
                                req.service === 'pengiriman_barang'
                            "
                        >
                            <label class="fw-bold mb-1">
                                Berat
                                <span class="text-danger fw-bold">*</span>
                            </label>
                            <div class="input-group mb-0">
                                <input
                                    type="text"
                                    class="form-control form-format"
                                    placeholder="Berat"
                                    v-model="req.weight"
                                    @input="inputNumber()"
                                />
                                <span class="input-group-text">Kg</span>
                            </div>
                        </div>
                        <div class="col-lg-6 mt-3">
                            <label class="fw-bold mb-1">
                                Harga Pengiriman
                                <span class="text-danger fw-bold">*</span>
                            </label>
                            <div class="input-group mb-0">
                                <span class="input-group-text">Rp</span>
                                <input
                                    type="text"
                                    placeholder="Masukkan harga pengiriman"
                                    class="form-control b-l-0"
                                    v-model="req.price"
                                    @input="formatPrice"
                                />
                            </div>
                        </div>
                        <div
                            class="col-lg-12 mt-3"
                            v-if="
                                this.req.service !== 'sewa_container_20ft' &&
                                this.req.service !== 'sewa_container_40ft'
                            "
                        >
                            <label class="fw-bold mb-1">
                                Deskripsi
                                <span class="text-danger fw-bold">*</span>
                            </label>
                            <textarea
                                cols="30"
                                rows="2"
                                class="form-control"
                                placeholder="Isi deskripsi"
                                v-model="req.prediction_description"
                            ></textarea>
                        </div>
                    </div>
                </div>
            </div>
            <div class="d-flex mt-5">
                <div class="ms-auto">
                    <div class="d-flex">
                        <a
                            href="javascript:void(0)"
                            class="btn btn-outline-yellow px-5 btn-lg me-3"
                            @click="$router.go(-1)"
                        >
                            Batal
                        </a>
                        <button
                            type="button"
                            :disabled="!isFormValid"
                            class="btn btn-primary px-5 btn-lg"
                            @click="storeConfirmation()"
                        >
                            Simpan
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- MODAL -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalConfirmStore"></div>
            <div class="modal-body-chat vsm" v-if="modalConfirmStore">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Data Aktivitas Follow Up akan disimpan
                    </h4>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirmStore = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            @click="storeActifity()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_save"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_save"
                            ></span>
                            Ya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalConfirmStorePerdiction"></div>
            <div class="modal-body-chat vsm" v-if="modalConfirmStorePerdiction">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Data Aktivitas Follow Up akan disimpan
                    </h4>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirmStorePerdiction = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            @click="storeActifity()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_save"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_save"
                            ></span>
                            Ya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccess"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccess">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Aktivitas follow up Berhasil disimpan
                    </h4>
                </div>
                <div class="mt-4">
                    <router-link
                        to="/followup/all"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Ok
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import Multiselect from "vue-multiselect";

export default {
    components: {
        Multiselect,
    },
    props: ["roles"],
    name: "MakeAProspectLeads",
    data() {
        return {
            carTypeSuggestions: [],
            showCarSuggestions: false,
            isAddCarVehicle: false,
            typeSuggestions: [],
            showSuggestions: false,
            searchTimeout: null,
            capacities: [
                {
                    name: "100 CC - 149 CC",
                    id: "lower",
                },
                {
                    name: "150 CC - 249 CC",
                    id: "middle",
                },
                {
                    name: "Diatas 250 CC",
                    id: "high",
                },
            ],
            vehicleSearch: "",
            isAddVehicle: false,
            specialist: null,
            specialists: [],
            req: {
                bikeType: "",
                carType: "",
                capacity: "",
                activity: "",
                specialist_id: null,
                priority: "",
                description_activity: "",
                is_prediction: null,
                service: "",
                regency: null,
                destination: null,
                address: "",
                height: "",
                length: "",
                width: "",
                weight: "",
                price: "",
                prediction_description: "",
            },
            regencies: [],
            destinations: [],
            is_save: false,
            detailLeads: {},
            followup_id: null,
            modalConfirmStore: false,
            modalSuccess: false,
            modalConfirmStorePerdiction: false,
            managerRoles: ["crm-manager"],
            is_loading_data: false,
        };
    },
    created() {
        this.getGeo();
        this.getSpecialists();
        this.getDetailLeads();
        document.addEventListener("click", (e) => {
            const carSuggestionsContainer = document.querySelector(
                ".suggestions-container"
            );
            const carTypeInput = document.querySelector(
                'input[placeholder="Masukkan tipe mobil"]'
            );

            if (carSuggestionsContainer && carTypeInput) {
                if (
                    !carSuggestionsContainer.contains(e.target) &&
                    !carTypeInput.contains(e.target)
                ) {
                    this.showCarSuggestions = false;
                }
            }
        });
        document.addEventListener("click", (e) => {
            const suggestionsContainer = document.querySelector(
                ".suggestions-container"
            );
            const typeInput = document.querySelector(
                'input[placeholder="Masukkan tipe motor"]'
            );

            if (suggestionsContainer && typeInput) {
                if (
                    !suggestionsContainer.contains(e.target) &&
                    !typeInput.contains(e.target)
                ) {
                    this.showSuggestions = false;
                }
            }
        });
    },
    computed: {
        canSelectPrediction() {
            const baseFieldsFilled =
                this.req.activity !== "" &&
                this.req.activity !== undefined &&
                this.req.description_activity !== "" &&
                this.req.description_activity !== undefined &&
                this.req.priority !== "" &&
                this.req.priority !== undefined;

            if (this.isRole(this.managerRoles)) {
                return baseFieldsFilled && this.specialist !== null;
            }

            return baseFieldsFilled;
        },
        isBasicFormFilled() {
            const baseFieldsFilled =
                this.req.activity !== "" &&
                this.req.activity !== undefined &&
                this.req.description_activity !== "" &&
                this.req.description_activity !== undefined &&
                this.req.priority !== "" &&
                this.req.priority !== undefined &&
                this.req.is_prediction !== null &&
                this.req.is_prediction !== undefined;

            if (this.isRole(this.managerRoles)) {
                return baseFieldsFilled && this.specialist !== null;
            }

            return baseFieldsFilled;
        },
        isFormValid() {
            const baseValidation =
                this.req.activity !== "" &&
                this.req.activity !== undefined &&
                this.req.description_activity !== "" &&
                this.req.description_activity !== undefined &&
                this.req.priority !== "" &&
                this.req.priority !== undefined &&
                this.req.is_prediction !== null &&
                this.req.is_prediction !== undefined;

            const specialistValidation = this.isRole(this.managerRoles)
                ? this.specialist !== null
                : true;

            if (!baseValidation || !specialistValidation) {
                return false;
            }

            if (this.req.is_prediction === false) {
                return true;
            }

            if (this.req.is_prediction === true) {
                const predictionBaseValidation =
                    this.req.service !== "" &&
                    this.req.service !== undefined &&
                    this.req.regency !== null &&
                    this.req.destination !== null &&
                    this.req.address !== "" &&
                    this.req.address !== undefined &&
                    this.req.price !== "" &&
                    this.req.price !== undefined;

                if (!predictionBaseValidation) {
                    return false;
                }

                switch (this.req.service) {
                    case "pengiriman_barang":
                        return (
                            this.req.weight !== "" &&
                            this.req.weight !== undefined &&
                            this.req.prediction_description !== "" &&
                            this.req.prediction_description !== undefined
                        );

                    case "pengiriman_motor":
                        return (
                            this.req.bikeType !== "" &&
                            this.req.bikeType !== undefined &&
                            this.req.capacity !== "" &&
                            this.req.capacity !== undefined &&
                            this.req.prediction_description !== "" &&
                            this.req.prediction_description !== undefined
                        );

                    case "pengiriman_mobil":
                        return (
                            this.req.carType !== "" &&
                            this.req.carType !== undefined &&
                            this.req.prediction_description !== "" &&
                            this.req.prediction_description !== undefined
                        );

                    case "sewa_container_20ft":
                    case "sewa_container_40ft":
                        return true;

                    default:
                        return false;
                }
            }

            return false;
        },
    },
    methods: {
        formatPrice() {
            let value = this.req.price.replace(/\D/g, "");

            if (value === "0") {
                this.req.price = "";
            } else {
                this.req.price = value.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            }
        },
        handleCarFocus() {
            this.handleCarTypeSearch();
            this.showCarSuggestions = true;
        },
        handleCarTypeSearch() {
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
            }
            this.searchTimeout = setTimeout(() => {
                this.getListVehicle("car");
            }, 300);
        },
        selectCarType(suggestion) {
            this.req.carType = suggestion.name;
            this.showCarSuggestions = false;
        },
        handleFocus() {
            this.handleTypeSearch();
            this.showSuggestions = true;
        },
        createNewVehicle(type) {
            if (type === "car") {
                this.isAddCarVehicle = true;
            } else {
                this.isAddVehicle = true;
            }
            const payload = {
                name: type === "car" ? this.req.carType : this.req.bikeType,
                type: type,
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/vehicles/create`,
                payload,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    if (type === "car") {
                        this.handleCarTypeSearch();
                        this.isAddCarVehicle = false;
                    } else {
                        this.isAddVehicle = false;
                        this.handleTypeSearch();
                    }
                })
                .catch((err) => {
                    if (type === "car") {
                        this.isAddCarVehicle = false;
                    } else {
                        this.isAddVehicle = false;
                    }
                    this.showSuggestions = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        handleTypeSearch() {
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout);
            }
            this.searchTimeout = setTimeout(() => {
                this.getListVehicle("bike");
            }, 300);
        },
        selectType(suggestion) {
            this.req.bikeType = suggestion.name;
            this.showSuggestions = false;
        },
        getListVehicle(type) {
            const payload = {
                search: type === "car" ? this.req.carType : this.req.bikeType,
                type: type,
            };

            Api.get(`${process.env.VUE_APP_SERVICE_URL}/vehicles`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    if (type === "car") {
                        this.carTypeSuggestions = res.data.data;
                    } else {
                        this.typeSuggestions = res.data.data;
                    }
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        inputNumber() {
            this.req.length = this.req.length.replace(/[^\d]/g, "");
            this.req.width = this.req.width.replace(/[^\d]/g, "");
            this.req.height = this.req.height.replace(/[^\d]/g, "");
            this.req.weight = this.req.weight.replace(/[^\d]/g, "");
        },
        getGeo() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    var data = res.data.data;
                    this.regencies = data;
                    this.destinations = data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getSpecialists() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/user`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.specialists = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getDetailLeads() {
            this.is_loading_data = true;
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/leads/detail?id=${this.$route.params.id}`,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )

                .then((res) => {
                    this.is_loading_data = false;
                    this.detailLeads = res.data?.data;
                })
                .catch((err) => {
                    this.is_loading_data = false;
                    console.log(err);
                });
        },
        storeConfirmation() {
            if (this.req.is_prediction == false) {
                this.modalConfirmStore = true;
            }
            if (this.req.is_prediction == true) {
                this.modalConfirmStorePerdiction = true;
            }
        },
        storeActifity() {
            this.is_save = true;

            var data = {
                lead_id: this.$route.params.id,
                activity: this.req.activity,
                specialist_id: this.specialist?.id,
                priority: this.req.priority,
                description_activity: this.req.description_activity,
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/followup/store`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.followup_id = res.data.data.prospect_id;
                    this.is_save = false;
                    if (this.req.is_prediction == true) {
                        this.storePrediction();
                    }
                    if (this.req.is_prediction == false) {
                        this.modalConfirmStore = false;
                        this.modalSuccess = true;
                    }
                })
                .catch((err) => {
                    this.is_save = false;
                    if (err.response.data.data == null) {
                        this.$toast.error(err.response.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    } else {
                        this.$toast.error(err.response.data.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    }
                });
        },
        storePrediction() {
            let data = {
                followup_id: this.followup_id,
                service: this.req.service,
                origin_regency_id: this.req?.regency?.id,
                destination_regency_id: this.req?.destination?.id,
                address: this.req.address,
                price: parseFloat(this.req.price.replace(/\./g, "")),
            };
            if (this.req.service === "pengiriman_barang") {
                data = {
                    ...data,
                    length: parseFloat(this.req.length),
                    width: parseFloat(this.req.width),
                    height: parseFloat(this.req.height),
                    weight: parseFloat(this.req.weight),
                    description: this.req.prediction_description,
                };
            } else if (this.req.service === "pengiriman_mobil") {
                data = {
                    ...data,
                    vehicle: this.req.carType,
                    description: this.req.prediction_description,
                };
            } else if (this.req.service === "pengiriman_motor") {
                data = {
                    ...data,
                    vehicle: this.req.bikeType,
                    description: this.req.prediction_description,
                    cc: this.req.capacity.id,
                };
            }

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/predictions/store`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    console.log(res);
                    this.modalSuccess = true;
                    this.modalConfirmStorePerdiction = false;
                })
                .catch((err) => {
                    if (err.response.data.data == null) {
                        this.$toast.error(err.response.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    } else {
                        this.$toast.error(err.response.data.data.message, {
                            position: "top-right",
                            timeout: 2000,
                            closeOnClick: true,
                            pauseOnFocusLoss: true,
                            pauseOnHover: true,
                            draggable: true,
                            draggablePercent: 0.6,
                            showCloseButtonOnHover: false,
                            hideProgressBar: true,
                            closeButton: false,
                            icon: true,
                            rtl: false,
                        });
                    }
                });
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
    },
    beforeDestroy() {
        document.removeEventListener("click");
    },
};
</script>

<style scoped>
.b-l-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.of-y::-webkit-scrollbar {
    display: none;
}
hr.hr-suggestion {
    margin: 0;
    margin-top: 5px;
}
.suggestions-container {
    position: static;
    top: 100%;
    left: 0;
    right: 0;
    background-color: white;
    border: 1px solid #c1cdd7;
    border-radius: 7px;
    margin-top: 2px;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.suggestion-title {
    padding: 8px 12px !important;
    margin-bottom: 0 !important;
    font-size: 12px;
    color: #a3b4c2;
}

.text-suggestion-dark {
    color: #212529;
    font-weight: 500;
}

.suggestion-item-container {
    max-height: 200px;
    overflow-y: auto;
}

.suggestion-item {
    padding: 5px 24px;
    cursor: pointer;

    &:hover {
        background-color: #f3f5f7;
    }
}
.suggestion-item-none {
    padding: 5px 24px;
}

.hr-suggestion {
    margin: 0;
    margin-top: 5px;
}
</style>
