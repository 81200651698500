<template>
    <div class="padding-container">
        <div class="box-manifest">
            <div class="mb-3">
                <h3 class="fw-bold">Daftar User</h3>
            </div>
            <div class="row gy-3 mt-3">
                <div class="col-12 col-lg-5">
                    <div class="position-relative">
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control form-control-lg pl-search w-search border-search"
                                placeholder="Cari nama user"
                                v-model="search"
                            />
                            <button
                                class="btn btn-primary px-4"
                                type="button"
                                @click="filterData()"
                            >
                                Cari
                            </button>
                        </div>
                        <span
                            class="search-icon left position-absolute top-50 start-0 translate-middle-y ms-3"
                        >
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                </div>
                <div class="col-12 col-lg-3 col-xl-2">
                    <button
                        class="btn btn-outline-yellow btn-filter transparent w-100"
                        @click="isFilter = !isFilter"
                    >
                        <img
                            src="../assets/dashboard/filter.png"
                            class="me-1"
                            width="18"
                            alt=""
                        />
                        Filter
                    </button>
                </div>
                <div
                    class="col-12 col-lg-4 col-xl-5 d-flex justify-content-end"
                >
                    <router-link
                        to="/user-management/add"
                        class="btn btn-primary px-3 btn-pesanan d-flex align-items-center justify-content-center"
                    >
                        <i class="pi pi-plus me-1 size-12"></i> Buat Akun User
                    </router-link>
                </div>
            </div>
            <div class="box mt-3 position-relative" v-if="isFilter">
                <div class="container-fluid">
                    <div class="row gy-3">
                        <div class="col-12 col-lg-4">
                            <label class="fw-bold mb-1">Peran</label>
                            <multiselect
                                v-model="role"
                                :options="roles"
                                label="name"
                                track-by="id"
                                placeholder="Pilih peran"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div class="col-12 col-lg-8">
                            <label class="fw-bold mb-1">Tanggal Dibuat</label>
                            <div class="row gy-3">
                                <div class="col-12 col-lg-6">
                                    <InputRangePicker
                                        v-model="date"
                                        placeholder-text="Pilih tanggal"
                                    />
                                </div>
                                <div class="col-12 col-lg-6">
                                    <div
                                        class="d-flex flex-column flex-lg-row gap-2"
                                    >
                                        <button
                                            class="btn btn-outline-yellow flex-grow-1"
                                            @click="clearFilter()"
                                            :disabled="
                                                role == null || date == null
                                            "
                                        >
                                            Clear
                                        </button>
                                        <button
                                            class="btn btn-green flex-grow-1"
                                            :disabled="
                                                role == null || date == null
                                            "
                                            @click="filterData()"
                                        >
                                            Filter
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row gy-3 align-items-center mt-3">
                <div class="col-12 col-lg-6">
                    <div class="text-center text-lg-start">
                        Menampilkan
                        <span class="fw-bold"
                            >{{ pageStart }}-{{ pageEnd }}</span
                        >
                        Data dari
                        <span class="fw-bold">{{ lists.total_data }}</span>
                        Data
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <div
                        class="d-flex flex-column flex-lg-row align-items-center justify-content-lg-end"
                    >
                        <div class="mb-2 mb-lg-0 me-lg-2">
                            Baris per halaman
                        </div>
                        <select
                            class="form-control form-select w-select outline-yellow"
                            style="max-width: 100px"
                            v-model="limit"
                            @change="changeLength()"
                        >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="mt-4" v-if="list_loading">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Nama User
                                </th>
                                <th class="text-center" scope="col">Email</th>
                                <th class="text-center" scope="col">Peran</th>
                                <th class="text-center" scope="col">
                                    Tanggal Dibuat
                                </th>
                                <th class="text-center" scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in lists.list_data"
                                :key="index"
                            >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td>
                                    <router-link to="#" class="text-link">
                                        {{
                                            item.customer_name
                                                ? item.customer_name
                                                : "-"
                                        }}
                                    </router-link>
                                </td>
                                <td>
                                    {{ item.order_id ? item.order_id : "-" }}
                                </td>
                                <td>
                                    <span
                                        class="tag mb-0 capitalize badge-role rounded-pill d-block"
                                    >
                                        {{
                                            item.order_type === "reg"
                                                ? "Regular"
                                                : item.order_type === "walk_in"
                                                ? "Walk In"
                                                : "-"
                                        }}
                                    </span>
                                </td>
                                <td>{{ item.origin ? item.origin : "-" }}</td>
                                <td class="text-center">
                                    <router-link to="#">
                                        <img
                                            src="../assets/dashboard/edit-icon.png"
                                            width="20"
                                        />
                                    </router-link>
                                    <a href="javascript:void(0)" class="ms-2">
                                        <img
                                            src="../assets/dashboard/trash-icon.png"
                                            width="20"
                                        />
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    v-if="lists.list_data.length == 0"
                    class="text-center mt-5"
                >
                    <img src="../assets/no-data-green.png" width="100" alt="" />
                    <div class="mt-4 mb-4 text-gray">Belum ada daftar user</div>
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <b-pagination-nav
                        v-if="lists.list_data.length > 0"
                        v-model="currentPage"
                        :number-of-pages="totalPage"
                        base-url="#"
                        first-number
                        align="right"
                        @input="getLists()"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="detailAccount">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm account-modal">
                <div class="d-flex align-items-center position-relative">
                    <h5
                        class="fw-bold mb-0 position-absolute start-50 translate-middle-x"
                    >
                        Detail Akun User
                    </h5>
                    <img
                        src="../assets/close-icon.svg"
                        alt="close icon"
                        class="ms-auto"
                    />
                </div>
                <div class="d-flex justify-content-center my-3">
                    <img
                        src="../assets/klik-user-empty.svg"
                        alt="klik user empty"
                    />
                </div>
                <p class="fw-bold text-center">Najwa Khodijah</p>
                <div class="d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                        <img
                            src="../assets/calendar-modal.svg"
                            alt="calendar modal"
                            class="me-2"
                        />
                        21 Mei 2024
                    </div>
                    <div class="d-flex align-items-center">
                        <img
                            src="../assets/gmail-modal.svg"
                            alt="gmail modal"
                            class="me-2"
                        />
                        naj.awa@gmail.com
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <button class="btn btn-outline-yellow w-100 btn-lg">
                            Hapus Akun
                        </button>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                        >
                            Edit Akun
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="deleteAccount">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">Akun User akan dihapus</h4>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin menghapus akun ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <button class="btn btn-outline-yellow w-100 btn-lg">
                            Kembali
                        </button>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                        >
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="successDelete">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Akun User Berhasil Dihapus
                    </h4>
                </div>
                <div class="mt-4">
                    <router-link to="#" class="btn btn-primary w-100 btn-lg">
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import InputRangePicker from "../components/InputRangePicker.vue";
import Api from "../api/Api";
import ListLoader from "../components/ListLoader.vue";

export default {
    components: {
        Multiselect,
        ListLoader,
        InputRangePicker,
    },
    data() {
        return {
            successDelete: false,
            deleteAccount: false,
            detailAccount: false,
            role: null,
            roles: [],
            date: {
                from: null,
                to: null,
            },
            pageStart: 0,
            pageEnd: 0,
            isFilter: false,
            search: "",
            limit: 5,
            totalPage: 0,
            currentPage: 1,
            lists: {
                list_data: [],
            },
            list_loading: false,
        };
    },
    created() {
        this.getLists();
    },
    methods: {
        changeLength() {
            this.currentPage = 1;
            this.getLists();
        },
        clearFilter() {
            this.role = null;
            this.date.from = null;
            this.date.to = null;
            this.getLists();
        },
        filterData() {
            this.getLists();
        },
        getLists() {
            let payload = {
                search: this.search,
                limit: this.limit,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 0,
            };
            if (this.role) {
                payload.role = this.role?.id;
            }
            if (this.date) {
                payload.date = this.date?.id;
            }
            this.list_loading = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/order`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.lists = res.data.data;
                    this.totalPage = res.data.data.total_page;
                    const totalItems = this.lists.list_data.length;
                    const totalData = res.data.data.total_data;
                    this.pageStart =
                        totalItems === 0
                            ? 0
                            : (this.currentPage - 1) * this.limit + 1;
                    this.pageEnd = Math.min(
                        Number(this.pageStart) + Number(this.limit) - 1,
                        totalData
                    );
                    this.list_loading = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.list_loading = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.badge-role {
    border: 1px solid #b2e6c8 !important;
    background-color: #d9f2e4 !important;
    color: #267347 !important;
}
.modal-vue .modal-body-chat.vsm.account-modal {
    width: 500px !important;
}
.btn-filter.transparent {
    background: transparent !important;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.table-responsive-custom {
    .table {
        border-radius: 8px;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #c1cdd7;

        th,
        td {
            border: 1px solid #c1cdd7;
        }
        thead {
            tr:first-child {
                th:first-child {
                    border-top-left-radius: 8px;
                }
                th:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        tbody {
            tr:last-child {
                td:first-child {
                    border-bottom-left-radius: 8px;
                }
                td:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}
td {
    background-color: transparent !important;
}
th {
    background-color: #f3f5f7 !important;
}
::v-deep .page-link {
    background-color: #f3f5f7 !important;
}
::v-deep .page-item.active .page-link {
    background-color: #1291ff !important;
}
.filter-button {
    display: flex;
    gap: 1rem;
}
.btn-pesanan {
    width: 100%;
}
@media only screen and (min-width: 992px) {
    .btn-pesanan {
        width: fit-content;
    }
}
.outline-yellow {
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    background-color: transparent !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
.outline-yellow:focus {
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    background-color: transparent !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
</style>
