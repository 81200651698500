<template>
    <div class="padding-container">
        <div class="content-load" v-if="isPageLoading"></div>
        <div id="loader" v-if="isPageLoading"></div>
        <div class="fw-bold">
            <div
                class="text-gray-dashboard size-16 cursor-pointer"
                @click="$router.go(-1)"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </div>
        </div>
        <div class="box-manifest mt-4">
            <h5 class="fw-bold mb-4">Tambah Route Management & Lead Time</h5>
            <div class="d-flex align-items-start">
                <img
                    src="../assets/location-dash.svg"
                    alt="location-dash"
                    class="me-3 d-none-sm"
                />
                <div class="row w-100">
                    <div class="col-lg-6 mb-4">
                        <label class="mb-2"
                            >Provinsi Asal
                            <span class="text-yellow">*</span></label
                        >
                        <multiselect
                            v-model="originProvince"
                            :options="provinces"
                            label="name"
                            track-by="id"
                            placeholder="Cari & pilih provinsi asal"
                            class="multi-form-custom magnifier"
                            :show-labels="false"
                            @input="getOriginRegencies()"
                        >
                            <template v-slot:caret>
                                <img
                                    :src="
                                        originProvince
                                            ? magnifierActiveIcon
                                            : magnifierIcon
                                    "
                                    alt="icon"
                                    style="
                                        width: 20px;
                                        height: 20px;
                                        position: absolute;
                                        left: 12px;
                                        top: 42%;
                                        transform: translateY(-50%);
                                    "
                                />
                            </template>
                        </multiselect>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <label class="mb-2"
                            >Kota/Kab. Asal
                            <span class="text-yellow">*</span></label
                        >
                        <multiselect
                            v-model="originRegency"
                            :options="originRegencies"
                            label="name"
                            track-by="id"
                            placeholder="Pilih kota/kab. asal"
                            class="multi-form-custom"
                            :show-labels="false"
                            :disabled="!originProvince"
                        >
                        </multiselect>
                    </div>
                </div>
            </div>
            <div
                v-for="(transit, index) in transitPoints"
                :key="index"
                class="row"
            >
                <div class="d-flex align-items-start">
                    <img
                        src="../assets/to-dash.svg"
                        alt="to dash"
                        class="me-3 d-none-sm"
                    />
                    <div class="row w-100">
                        <div class="col-lg-6 mb-4">
                            <label class="mb-2">
                                Kota/Kabupaten transit {{ index + 1 }}
                                <span class="text-yellow">*</span>
                            </label>
                            <multiselect
                                v-model="transit.value"
                                :options="regencies"
                                label="name"
                                track-by="id"
                                placeholder="Cari & pilih kota transit"
                                class="multi-form-custom magnifier"
                                :show-labels="false"
                            >
                                <template v-slot:caret>
                                    <img
                                        :src="
                                            transit.value
                                                ? magnifierActiveIcon
                                                : magnifierIcon
                                        "
                                        alt="icon"
                                        style="
                                            width: 20px;
                                            height: 20px;
                                            position: absolute;
                                            left: 12px;
                                            top: 42%;
                                            transform: translateY(-50%);
                                        "
                                    />
                                </template>
                            </multiselect>
                        </div>
                        <div class="col-lg-4 mb-4">
                            <label class="mb-2 invisible-md">
                                Kota {{ index + 1 }}
                                <span class="text-yellow">*</span>
                            </label>
                            <button
                                class="btn outline-green d-flex align-items-center"
                                @click="removeTransitPoint(index)"
                            >
                                <img
                                    src="../assets/trash-button.svg"
                                    alt="trash"
                                    class="me-2"
                                />
                                Hapus Bagian
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div
                class="d-flex align-items-start"
                v-if="transitPoints.length < 3"
            >
                <img
                    src="../assets/to-dash.svg"
                    alt="to dash"
                    class="me-3 d-none-sm"
                />
                <div class="mb-4">
                    <label class="mb-2"
                        >Kota/Kabupaten transit
                        <span class="text-yellow">*</span></label
                    >
                    <button
                        class="btn outline-yellow d-block px-4"
                        @click="addTransitPoint"
                    >
                        <i class="pi pi-plus me-2"></i>
                        Tambah Titik Transit
                    </button>
                </div>
            </div>
            <div class="d-flex align-items-start">
                <img
                    src="../assets/location.svg"
                    alt="location"
                    class="me-3 d-none-sm"
                />
                <div class="row w-100">
                    <div class="col-lg-6 mb-4">
                        <label class="mb-2"
                            >Provinsi Tujuan
                            <span class="text-yellow">*</span></label
                        >
                        <multiselect
                            v-model="destinationProvince"
                            :options="provinces"
                            label="name"
                            track-by="id"
                            placeholder="Cari & pilih provinsi tujuan"
                            class="multi-form-custom magnifier"
                            :show-labels="false"
                            @input="getDestinationRegencies()"
                            :disabled="!originRegency"
                        >
                            <template v-slot:caret>
                                <img
                                    :src="
                                        destinationProvince
                                            ? magnifierActiveIcon
                                            : magnifierIcon
                                    "
                                    alt="icon"
                                    style="
                                        width: 20px;
                                        height: 20px;
                                        position: absolute;
                                        left: 12px;
                                        top: 42%;
                                        transform: translateY(-50%);
                                    "
                                />
                            </template>
                        </multiselect>
                    </div>
                    <div class="col-lg-6 mb-4">
                        <label class="mb-2"
                            >Kota/Kab. Tujuan
                            <span class="text-yellow">*</span></label
                        >
                        <multiselect
                            v-model="destinationRegency"
                            :options="destinationRegencies"
                            label="name"
                            track-by="id"
                            placeholder="Pilih kota/kab. tujuan"
                            class="multi-form-custom"
                            :show-labels="false"
                            :disabled="!destinationProvince"
                        >
                        </multiselect>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div
                class="col-lg-12"
                v-if="
                    !originRegency ||
                    !destinationRegency ||
                    (transitPoints.length > 0 &&
                        transitPoints.some((point) => !point.value))
                "
            >
                <div class="box-manifest mt-4">
                    <h5 class="fw-bold">Lead Time</h5>
                    <div class="d-flex justify-content-center mt-5">
                        <img src="../assets/empty.svg" alt="empty" />
                    </div>
                    <p class="text-gray text-center mt-5">
                        Isi Kota/Kab. asal dan tujuan terlebih dahulu
                    </p>
                    <button class="btn btn-primary w-100" disabled>
                        Simpan
                    </button>
                </div>
            </div>
            <div class="col-lg-12" v-else>
                <div class="box-manifest mt-4">
                    <h5 class="fw-bold mb-4">Lead Time</h5>
                    <div
                        v-for="(leadTimeInfo, index) in leadTimeInfos"
                        :key="index"
                        class="mb-4"
                    >
                        <div
                            class="d-flex justify-content-sm-between justify-content-center flex-wrap align-items-center header-sidebar px-3 py-2"
                        >
                            <h5 class="fw-bold my-2">
                                Informasi Lead Time {{ index + 1 }}
                            </h5>
                            <div class="d-flex my-2">
                                <button
                                    class="btn outline-green"
                                    @click="removeLeadTimeInfo(index)"
                                >
                                    <img
                                        src="../assets/trash-button.svg"
                                        alt="trash"
                                    />
                                    Hapus Bagian
                                </button>
                            </div>
                        </div>
                        <div class="body-sidebar px-3 py-2">
                            <div class="row">
                                <div class="col-lg-6 my-3">
                                    <label class="mb-2"
                                        >Jenis Layanan
                                        <span class="text-yellow"
                                            >*</span
                                        ></label
                                    >
                                    <multiselect
                                        v-model="leadTimeInfo.service"
                                        :options="services"
                                        label="name"
                                        track-by="id"
                                        placeholder="Pilih jenis layanan"
                                        class="multi-form-custom"
                                        :show-labels="false"
                                    >
                                    </multiselect>
                                </div>
                                <div class="col-lg-6 my-3">
                                    <label class="mb-2"
                                        >Lead Time
                                        <span class="text-yellow"
                                            >*</span
                                        ></label
                                    >
                                    <div class="input-group mb-3">
                                        <input
                                            type="text"
                                            class="form-control br-0"
                                            placeholder="Masukkan lead time"
                                            v-model="leadTimeInfo.leadTime"
                                        />
                                        <span class="input-group-text"
                                            >Hari</span
                                        >
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button
                        class="btn outline-yellow w-100 mb-4"
                        @click="addLeadTimeInfo"
                    >
                        <i class="pi pi-plus me-2"></i>
                        Tambah Informasi Lead Time
                    </button>
                    <button
                        class="btn btn-primary w-100"
                        :disabled="!isFormValid"
                        @click="modalConfirm = true"
                    >
                        Simpan
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Data route & Lead Time akan disimpan
                    </h4>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirm = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            @click="saveData()"
                            :disabled="isLoading"
                        >
                            <span
                                v-if="isLoading"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Data route & Lead Time sudah disimpan
                    </h4>
                </div>
                <div class="mt-4">
                    <router-link
                        to="/route-and-lead"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";
import Multiselect from "vue-multiselect";
import magnifierDisabled from "../assets/magnifier-disabled.svg";
import magnifier from "../assets/magnifier.svg";

export default {
    components: {
        Multiselect,
    },
    data() {
        return {
            modalConfirm: false,
            modalSuccess: false,
            leadTimeInfos: [
                {
                    service: null,
                    leadTime: null,
                },
            ],
            services: [
                {
                    name: "Pengiriman Barang",
                    id: "pengiriman_barang",
                },
                {
                    name: "Pengiriman Motor",
                    id: "pengiriman_motor",
                },
                {
                    name: "Pengiriman Mobil",
                    id: "pengiriman_mobil",
                },
                {
                    name: "Sewa Kontainer 20 Feet",
                    id: "sewa_container_20ft",
                },
                {
                    name: "Sewa Kontainer 40 Feet",
                    id: "sewa_container_40ft",
                },
            ],
            transitPoints: [],
            magnifierIcon: magnifierDisabled,
            magnifierActiveIcon: magnifier,
            isPageLoading: false,
            originProvince: null,
            originRegency: null,
            destinationProvince: null,
            destinationRegency: null,
            originRegencies: [],
            destinationRegencies: [],
            provinces: [],
            regencies: [],
            isLoading: false,
        };
    },
    created() {
        this.getProvinces();
        this.getRegencies();
    },
    computed: {
        isFormValid() {
            return this.leadTimeInfos.every(
                (info) =>
                    info.service && info.leadTime && info.leadTime.trim() !== ""
            );
        },
    },
    methods: {
        saveData() {
            this.isLoading = true;
            const routes = this.leadTimeInfos.map((info) => ({
                service_type: info.service.id,
                leadtime: parseInt(info.leadTime),
            }));

            const data = {
                origin_regency_id: this.originRegency.id,
                dooring_regency_id: this.destinationRegency.id,
                province_id: this.originProvince.id,
                dooring_province_id: this.destinationProvince.id,
                routes: routes,
            };

            if (this.transitPoints.length > 0) {
                this.transitPoints.forEach((transit, index) => {
                    if (transit.value) {
                        data[`transit_${index + 1}`] = transit.value.id;
                    }
                });
            }

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/ho/routes/create`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isLoading = false;
                    this.modalSuccess = true;
                    this.modalConfirm = false;
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.modalConfirm = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        addLeadTimeInfo() {
            this.leadTimeInfos.push({
                service: null,
                leadTime: null,
            });
        },
        removeLeadTimeInfo(index) {
            this.leadTimeInfos.splice(index, 1);
        },
        addTransitPoint() {
            if (this.transitPoints.length < 3) {
                this.transitPoints.push({
                    value: null,
                });
            }
        },
        removeTransitPoint(index) {
            this.transitPoints.splice(index, 1);
        },
        getOriginRegencies() {
            this.originRegency = null;
            this.destinationProvince = null;
            this.destinationRegency = null;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                params: { province_id: this.originProvince.id },
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.originRegencies = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getRegencies() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.regencies = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getDestinationRegencies() {
            this.destinationRegency = null;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                params: { province_id: this.destinationProvince.id },
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.destinationRegencies = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        getProvinces() {
            this.isPageLoading = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/province`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.provinces = res.data.data;
                    this.isPageLoading = false;
                })
                .catch((err) => {
                    this.isPageLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.d-none-sm {
    display: none;
}
.invisible-md {
    display: none;
}
@media only screen and (min-width: 992px) {
    .d-none-sm {
        display: block !important;
    }
    .invisible-md {
        visibility: hidden !important;
        display: block;
    }
}
@media only screen and (min-width: 435px) {
    .justify-content-sm-between {
        justify-content: space-between !important;
    }
}
::v-deep .multiselect--disabled .multiselect__select {
    background: transparent !important;
}
.br-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.header-sidebar {
    background-color: #f3f5f7;
    border: 1px solid #c1cdd7;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}
.body-sidebar {
    background-color: #ffffff;
    border: 1px solid #c1cdd7;
    border-top: none;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
.outline-yellow {
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    background-color: transparent !important;
    border-radius: 8px;
}
.outline-green {
    border: 1px solid #ff8600 !important;
    color: #ff8600 !important;
    background-color: transparent !important;
    border-radius: 8px;
}
::v-deep .multi-form-custom.magnifier {
    .multiselect__tags {
        padding-left: 40px !important;
        padding-right: 0 !important;
    }
    .multiselect__select {
        padding: 0;
    }
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
</style>
