<template>
    <div class="padding-container">
        <div class="box-manifest">
            <h5 class="fw-bold">Daftar Rute</h5>
            <div
                class="d-flex align-items-center justify-content-between mt-4 flex-column flex-lg-row"
            >
                <div class="mb-3 mb-lg-0 width-m-100">
                    <div class="position-relative">
                        <div class="input-group">
                            <input
                                type="text"
                                class="form-control form-control-lg pl-search w-search border-search"
                                placeholder="Cari rute asal/nama vendor"
                                v-model="search"
                                @keyup.enter="getSearch()"
                            />
                            <button
                                class="btn btn-primary px-4"
                                type="button"
                                @click="getSearch()"
                            >
                                Cari
                            </button>
                        </div>
                        <span class="search-icon left">
                            <i class="pi pi-search"></i>
                        </span>
                    </div>
                </div>
                <router-link
                    to="/route/add"
                    class="btn btn-primary px-3 mt-3 mt-lg-0 width-m-100"
                >
                    <i class="pi pi-plus me-2"></i>
                    Tambah Rute
                </router-link>
            </div>

            <div class="d-flex align-items-center mt-4 flex-column flex-lg-row">
                <div>
                    Menampilkan
                    <span class="fw-bold">{{ pageStart }}-{{ pageEnd }}</span>
                    Data dari
                    <span class="fw-bold">{{ lists.total_data }}</span>
                    Data
                </div>
                <div class="ms-auto ml-0-mobile mt-3 mt-lg-0">
                    <div class="d-flex align-items-center">
                        <div class="me-2">Baris per halaman</div>
                        <select
                            class="form-control form-select w-select outline-yellow"
                            v-model="limit"
                            @change="onLimitChange"
                        >
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="mt-4" v-if="isLoader">
                <ListLoader />
            </div>
            <div v-else>
                <div class="table-responsive-custom">
                    <table class="table table-bordered mt-4">
                        <thead class="table-light">
                            <tr>
                                <th class="text-center" scope="col">No</th>
                                <th class="text-center" scope="col">
                                    Rute Asal
                                </th>
                                <th class="text-center" scope="col">
                                    Rute Tujuan
                                </th>
                                <th class="text-center" scope="col">
                                    Nama Vendor
                                </th>
                                <th class="text-center" scope="col">
                                    Jenis Vendor
                                </th>
                                <th class="text-center" scope="col">
                                    Jenis Armada
                                </th>
                                <th class="text-center" scope="col">
                                    Jadwal Terdekat
                                    <img
                                        src="../assets/sort.svg"
                                        alt="sort"
                                        class="ms-2 cursor-pointer"
                                        @click="handleSort()"
                                    />
                                </th>
                                <th class="text-center" scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(vendor, index) in lists.list_data"
                                :key="index"
                            >
                                <td class="text-center">{{ index + 1 }}</td>
                                <td>{{ vendor.origin_regency }}</td>
                                <td>{{ vendor.destination_regency }}</td>
                                <td>{{ vendor.vendor_name }}</td>
                                <td class="text-center">
                                    <p
                                        class="darat rounded-pill"
                                        v-if="vendor.vendor_type === 'darat'"
                                    >
                                        Darat
                                    </p>
                                    <p
                                        class="udara rounded-pill"
                                        v-if="vendor.vendor_type === 'udara'"
                                    >
                                        Udara
                                    </p>
                                    <p
                                        class="laut rounded-pill"
                                        v-if="vendor.vendor_type === 'laut'"
                                    >
                                        Laut
                                    </p>
                                </td>
                                <td class="text-capitalize">
                                    {{ vendor.vehicle }}
                                </td>
                                <td>
                                    <div
                                        class="d-flex cursor-pointer"
                                        v-if="!vendor.nearest_schedule"
                                        @click="openSidebarSchedule(vendor)"
                                    >
                                        <p class="mb-0 text-link me-2">
                                            Edit Jadwal
                                        </p>
                                        <img
                                            src="../assets/edit.svg"
                                            width="20"
                                        />
                                    </div>
                                    <div
                                        class="d-flex cursor-pointer"
                                        v-else
                                        @click="openSidebarSchedule(vendor)"
                                    >
                                        <p class="mb-0 text-link me-2">
                                            {{ vendor.nearest_schedule }}
                                        </p>
                                        <img
                                            src="../assets/edit.svg"
                                            width="20"
                                        />
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div class="d-flex">
                                        <div class="cursor-pointer">
                                            <img
                                                src="../assets/dashboard/edit-icon.png"
                                                width="20"
                                                @click="openSidebar(vendor.id)"
                                            />
                                        </div>
                                        <div
                                            class="ms-2 d-inline-block cursor-pointer"
                                            @click="openModalConfirm(vendor.id)"
                                        >
                                            <img
                                                src="../assets/dashboard/trash-icon.png"
                                                width="20"
                                            />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div
                    class="text-center mt-5"
                    v-if="lists.list_data.length === 0"
                >
                    <img src="../assets/no-data-green.png" width="100" alt="" />
                    <div class="mt-4 mb-4 text-gray">
                        Belum ada daftar vendor
                    </div>
                </div>
            </div>
            <div class="d-flex mt-4">
                <div class="ms-auto">
                    <b-pagination-nav
                        v-if="lists.list_data.length > 0"
                        v-model="currentPage"
                        :number-of-pages="totalPage"
                        base-url="#"
                        first-number
                        align="right"
                        @input="getLists()"
                    ></b-pagination-nav>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirmSchedule">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Update Informasi jadwal keberangkatan akan disimpan
                    </h4>
                    <div class="text-gray-dashboard">
                        Pastikan data sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirmSchedule = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="isLoading"
                            @click="updateSchedule()"
                        >
                            <span
                                v-if="isLoading"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccessSchedule">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Informasi jadwal keberangkatan berhasil diupdate
                    </h4>
                </div>
                <div class="mt-4">
                    <div
                        class="btn btn-primary w-100 btn-lg"
                        @click="
                            (modalSuccessSchedule = false),
                                getLists(),
                                (isSidebarScheduleOpen = false)
                        "
                    >
                        Tutup
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Informasi rute vendor ini akan dihapus
                    </h4>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin menghapus data ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirm = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="isLoading"
                            @click="deleteData()"
                        >
                            <span
                                v-if="isLoading"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Informasi rute vendor berhasil dihapus
                    </h4>
                </div>
                <div class="mt-4">
                    <div
                        class="btn btn-primary w-100 btn-lg"
                        @click="
                            modalSuccess = false;
                            getLists();
                            isSidebarOpen = false;
                        "
                    >
                        Tutup
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirmEdit">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Perubahan Informasi Rute Vendor Ini Akan Disimpan
                    </h4>
                    <div class="text-gray-dashboard">
                        Pastikan data sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirmEdit = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            @click="editData()"
                            :disabled="isLoading"
                        >
                            <span
                                v-if="isLoading"
                                class="spinner-border spinner-border-sm me-2"
                            ></span>
                            OK
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccessEdit">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Perubahan Informasi rute vendor ini berhasil disimpan
                    </h4>
                </div>
                <div class="mt-4">
                    <div
                        class="btn btn-primary w-100 btn-lg"
                        @click="
                            modalSuccessEdit = false;
                            getLists();
                            isSidebarOpen = false;
                        "
                    >
                        Tutup
                    </div>
                </div>
            </div>
        </div>
        <div
            class="sidebar-overlay"
            v-if="isSidebarOpen"
            @click="closeSidebar()"
        ></div>
        <div
            class="sidebar"
            v-if="isSidebarOpen"
            :class="{ 'sidebar-open': isSidebarOpen }"
        >
            <div
                class="d-flex justify-content-between align-items-center p-3 border-bottom sticky-header"
            >
                <h5 class="mb-0 fw-bold">Edit Informasi Rute</h5>
                <div
                    type="button"
                    class="btn-close"
                    @click="closeSidebar()"
                ></div>
            </div>
            <div class="p-3" v-if="isLoadingDetail">
                <div class="d-flex justify-content-center mt-5">
                    <img src="../assets/empty.svg" alt="empty" />
                </div>
                <p class="text-gray text-center mt-5">
                    Sedang mengambil data...
                </p>
            </div>
            <div class="p-3 mb-5-rem sidebar-body" v-if="!isLoadingDetail">
                <h5 class="text-primary my-3 text-center text-capitalize">
                    {{ detailRoute.vendor_name }}
                </h5>
                <a
                    :href="'tel:' + detailRoute.vendor_phone"
                    class="d-flex align-items-center mb-3"
                >
                    <img class="me-3" src="../assets/call.svg" alt="call" />
                    <p class="text-link mb-0">{{ detailRoute.vendor_phone }}</p>
                </a>
                <a
                    :href="'mailto:' + detailRoute.vendor_email"
                    class="d-flex align-items-center mb-3"
                >
                    <img class="me-3" src="../assets/mail.svg" alt="mail" />
                    <p class="text-link mb-0">{{ detailRoute.vendor_email }}</p>
                </a>
                <div class="d-flex align-items-center mb-3">
                    <img class="me-3" src="../assets/pin.svg" alt="pin" />
                    <p class="mb-0">
                        {{ detailRoute.vendor_address }}
                    </p>
                </div>
                <hr />
                <div class="header-sidebar px-3 py-2">
                    <div class="d-flex align-items-center">
                        <img
                            v-if="detailRoute.vendor_type === 'udara'"
                            src="../assets/plane-active.svg"
                            alt="plane active"
                            class="me-2"
                        />
                        <img
                            v-if="detailRoute.vendor_type === 'darat'"
                            src="../assets/truck-active.svg"
                            alt="truck active"
                            class="me-2"
                        />
                        <img
                            v-if="detailRoute.vendor_type === 'laut'"
                            src="../assets/ship-active.svg"
                            alt="ship active"
                            class="me-2"
                        />
                        {{ detailRoute.origin_regency }} -
                        {{ detailRoute.destination_regency }}
                    </div>
                </div>
                <div class="body-sidebar px-3 py-2">
                    <label class="mb-2"
                        >Jenis Vendor <span class="text-yellow">*</span></label
                    >
                    <multiselect
                        v-model="vendorType"
                        :options="vendorTypes"
                        label="name"
                        track-by="id"
                        placeholder="Pilih jenis vendor"
                        class="multi-form-custom mb-3"
                        :show-labels="false"
                    >
                    </multiselect>
                    <label class="mb-2"
                        >Rute Asal <span class="text-yellow">*</span></label
                    >
                    <multiselect
                        v-model="originRegency"
                        :options="regencies"
                        label="name"
                        track-by="id"
                        placeholder="Cari rute asal"
                        class="multi-form-custom magnifier mb-3"
                        :show-labels="false"
                    >
                        <template v-slot:caret>
                            <img
                                :src="
                                    originRegency
                                        ? magnifierActiveIcon
                                        : magnifierIcon
                                "
                                alt="icon"
                                style="
                                    width: 20px;
                                    height: 20px;
                                    position: absolute;
                                    left: 12px;
                                    top: 42%;
                                    transform: translateY(-50%);
                                "
                            />
                        </template>
                    </multiselect>
                    <label class="mb-2"
                        >Rute Tujuan <span class="text-yellow">*</span></label
                    >
                    <multiselect
                        v-model="destinationRegency"
                        :options="regencies"
                        label="name"
                        track-by="id"
                        placeholder="Cari rute tujuan"
                        class="multi-form-custom magnifier mb-3"
                        :show-labels="false"
                    >
                        <template v-slot:caret>
                            <img
                                :src="
                                    destinationRegency
                                        ? magnifierActiveIcon
                                        : magnifierIcon
                                "
                                alt="icon"
                                style="
                                    width: 20px;
                                    height: 20px;
                                    position: absolute;
                                    left: 12px;
                                    top: 42%;
                                    transform: translateY(-50%);
                                "
                            />
                        </template>
                    </multiselect>
                    <label class="mb-2"
                        >Harga Pengiriman
                        <span class="text-yellow">*</span></label
                    >
                    <div class="input-group mb-3">
                        <span class="input-group-text">Rp</span>
                        <input
                            type="text"
                            class="form-control b-l-0"
                            placeholder="Masukkan harga pengiriman"
                            v-model="price"
                            @input="formattedTime('price', $event)"
                        />
                    </div>
                    <label class="mb-2" v-if="vendorType?.id === 'darat'"
                        >Jenis Armada <span class="text-yellow">*</span></label
                    >
                    <multiselect
                        v-if="vendorType?.id === 'darat'"
                        v-model="fleet"
                        :options="fleets"
                        label="name"
                        track-by="id"
                        placeholder="Pilih jenis armada"
                        class="multi-form-custom mb-3"
                        :show-labels="false"
                    ></multiselect>
                    <label class="mb-2" v-if="vendorType?.id === 'udara'"
                        >Nama Pesawat <span class="text-yellow">*</span></label
                    >
                    <input
                        v-if="vendorType?.id === 'udara'"
                        type="text"
                        class="form-control mb-3"
                        placeholder="Masukkan nama pesawat"
                        v-model="planeName"
                    />
                    <label class="mb-2" v-if="vendorType?.id === 'laut'"
                        >Nama Kapal <span class="text-yellow">*</span></label
                    >
                    <input
                        v-if="vendorType?.id === 'laut'"
                        type="text"
                        class="form-control mb-3"
                        placeholder="Masukkan nama kapal"
                        v-model="shipName"
                    />
                    <label class="mb-2">Lead Time</label>
                    <div class="input-group mb-3">
                        <input
                            type="text"
                            class="form-control b-r-0"
                            placeholder="Masukkan lead time"
                            v-model="leadtime"
                            @input="formattedTime('leadtime', $event)"
                        />
                        <span class="input-group-text">Hari</span>
                    </div>
                    <label class="mb-2"
                        >Jadwal Keberangkatan Terdekat
                        <span class="text-yellow">*</span></label
                    >
                    <input
                        type="text"
                        class="form-control mb-3"
                        disabled
                        v-model="schedule"
                    />
                </div>
            </div>
            <div class="p-3 sticky-footer" v-if="!isLoadingDetail">
                <button
                    class="btn btn-primary w-100"
                    @click="modalConfirmEdit = true"
                    :disabled="!isFormValid"
                >
                    Simpan Perubahan
                </button>
            </div>
        </div>
        <div
            class="sidebar-overlay"
            v-if="isSidebarScheduleOpen"
            @click="closeScheduleSidebar()"
        ></div>
        <div
            class="sidebar"
            v-if="isSidebarScheduleOpen"
            :class="{ 'sidebar-open': isSidebarScheduleOpen }"
        >
            <div
                class="d-flex justify-content-between align-items-center p-3 border-bottom sticky-header"
            >
                <h5 class="mb-0 fw-bold">Edit Jadwal Keberangkatan</h5>
                <div
                    type="button"
                    class="btn-close"
                    @click="closeScheduleSidebar()"
                ></div>
            </div>
            <div class="p-3 mb-5-rem">
                <h5 class="text-primary my-3 mb-4 text-center text-capitalize">
                    {{ isSidebarScheduleData.vendor_name }}
                </h5>
                <div class="d-flex align-items-center">
                    <img
                        v-if="isSidebarScheduleData.vendor_type === 'udara'"
                        src="../assets/plane-active.svg"
                        alt="plane active"
                        class="me-2"
                    />
                    <img
                        v-if="isSidebarScheduleData.vendor_type === 'darat'"
                        src="../assets/truck-active.svg"
                        alt="truck active"
                        class="me-2"
                    />
                    <img
                        v-if="isSidebarScheduleData.vendor_type === 'laut'"
                        src="../assets/ship-active.svg"
                        alt="ship active"
                        class="me-2"
                    />
                    <p class="mb-0">
                        {{ isSidebarScheduleData.origin_regency }} -
                        {{ isSidebarScheduleData.destination_regency }}
                    </p>
                </div>
                <hr />
                <div
                    class="d-flex justify-content-between align-items-center mb-3"
                >
                    <h5 class="fw-bold mb-0">Jadwal Keberangkatan</h5>
                    <div
                        class="d-flex align-items-center cursor-pointer"
                        v-if="
                            isSidebarScheduleData.schedules &&
                            isSidebarScheduleData.schedules.length !== 0
                        "
                        @click="toggleUpdate()"
                    >
                        <img
                            src="../assets/update-icon.svg"
                            alt="update icon"
                            class="me-2"
                        />
                        <p class="mb-0">Update</p>
                    </div>
                </div>
                <div class="d-flex mb-3" v-if="isUpdate">
                    <img
                        src="../assets/schedule-dash-active.svg"
                        alt="schedule active"
                        class="me-3"
                    />
                    <div class="d-flex flex-column w-100">
                        <label class="mb-2"
                            >Jadwal Keberangkatan
                            <span class="text-yellow">*</span></label
                        >
                        <DatePicker
                            v-model="scheduleDate"
                            placeholder-text="Pilih tanggal dan jam"
                            disabled-past
                            with-time
                        />
                    </div>
                </div>
                <div
                    class="d-flex"
                    v-if="
                        isSidebarScheduleData.schedules &&
                        isSidebarScheduleData.schedules.length === 0
                    "
                >
                    <img
                        src="../assets/schedule-active.svg"
                        alt="schedule active"
                        class="me-3"
                    />
                    <div class="d-flex flex-column w-100">
                        <label class="mb-2"
                            >Jadwal Keberangkatan
                            <span class="text-yellow">*</span></label
                        >
                        <DatePicker
                            v-model="scheduleDate"
                            placeholder-text="Pilih tanggal dan jam"
                            disabled-past
                            with-time
                        />
                    </div>
                </div>
                <div
                    class="d-flex"
                    v-else
                    v-for="(schedule, index) in isSidebarScheduleData.schedules"
                    :key="index"
                >
                    <img
                        :src="getScheduleImage(schedule, index)"
                        :alt="getScheduleImageAlt(schedule, index)"
                        class="me-3"
                    />
                    <p class="mb-0 text-schedule">{{ schedule.schedule }}</p>
                </div>
            </div>
            <div class="p-3 sticky-footer">
                <button
                    class="btn btn-primary w-100"
                    @click="modalConfirmSchedule = true"
                    :disabled="!scheduleDate"
                >
                    Update Jadwal
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";
import ListLoader from "../components/ListLoader.vue";
import magnifierDisabled from "../assets/magnifier-disabled.svg";
import magnifier from "../assets/magnifier.svg";
import scheduleActive from "../assets/schedule-active.svg";
import schedulePassive from "../assets/schedule-passive.svg";
import scheduleDashActive from "../assets/schedule-dash-active.svg";
import scheduleDashPassive from "../assets/schedule-dash-passive.svg";
import Multiselect from "vue-multiselect";
import DatePicker from "../components/DatePicker.vue";
import moment from "moment";
import "moment/locale/id";

export default {
    components: {
        ListLoader,
        Multiselect,
        DatePicker,
    },
    data() {
        return {
            sortDirection: null,
            isLoadingDetail: false,
            isUpdate: false,
            scheduleDate: null,
            modalConfirmSchedule: false,
            modalSuccessSchedule: false,
            isLoading: false,
            destinationRegency: null,
            schedule: null,
            vendor: null,
            leadtime: null,
            hasLoadedRegencies: false,
            hasLoadedFleets: false,
            originRegency: null,
            regencies: [],
            vendorType: {
                name: "",
                id: "",
            },
            fleet: null,
            fleets: [],
            planeName: null,
            shipName: null,
            vendorTypes: [
                {
                    name: "Darat",
                    id: "darat",
                },
                {
                    name: "Laut",
                    id: "laut",
                },
                {
                    name: "Udara",
                    id: "udara",
                },
            ],
            magnifierIcon: magnifierDisabled,
            magnifierActiveIcon: magnifier,
            scheduleActive: scheduleActive,
            schedulePassive: schedulePassive,
            scheduleDashActive: scheduleDashActive,
            scheduleDashPassive: scheduleDashPassive,
            isSidebarOpen: false,
            isSidebarScheduleOpen: false,
            modalConfirm: false,
            modalSuccess: false,
            modalConfirmEdit: false,
            modalSuccessEdit: false,
            price: null,
            pageStart: 0,
            pageEnd: 0,
            limit: 5,
            totalPage: 0,
            currentPage: 1,
            search: null,
            isLoader: false,
            lists: {
                list_data: [],
            },
            detailRoute: {},
            deletedData: null,
            isSidebarScheduleData: {},
        };
    },
    created() {
        this.getLists();
        moment.locale("id");
    },
    computed: {
        isFormValid() {
            if (
                !this.vendorType ||
                !this.originRegency ||
                !this.destinationRegency ||
                !this.price
            ) {
                return false;
            }

            switch (this.vendorType.id) {
                case "darat":
                    if (!this.fleet) {
                        return false;
                    }
                    break;
                case "udara":
                    if (!this.planeName) {
                        return false;
                    }
                    break;
                case "laut":
                    if (!this.shipName) {
                        return false;
                    }
                    break;
            }

            return true;
        },
    },
    watch: {
        isUpdate(newValue) {
            if (!newValue) {
                this.scheduleDate = null;
            }
        },
        vendorType: {
            async handler(newValue, oldValue) {
                if (newValue?.id !== oldValue?.id) {
                    if (newValue?.id === "darat" && !this.hasLoadedFleets) {
                        await this.getFleets();
                        this.hasLoadedFleets = true;
                    }
                }
            },
            deep: true,
        },
    },
    methods: {
        getSearch() {
            this.currentPage = 1;
            this.getLists();
        },
        handleSort() {
            this.currentPage = 1;
            this.sortDirection = this.sortDirection === "asc" ? "desc" : "asc";
            this.getLists();
        },
        getScheduleImage(schedule, index) {
            const isLastSchedule =
                index === this.isSidebarScheduleData.schedules.length - 1;
            const isSingleSchedule =
                this.isSidebarScheduleData.schedules.length === 1;

            const scheduleDate = moment(
                schedule.schedule,
                "DD MMMM YYYY | HH:mm"
            );
            const currentDate = moment();

            if (!scheduleDate.isValid()) {
                return this.scheduleDashPassive;
            }

            if (scheduleDate.isBefore(currentDate)) {
                if (isLastSchedule) {
                    return this.schedulePassive;
                }
                return this.scheduleDashPassive;
            }

            return isSingleSchedule || isLastSchedule
                ? this.scheduleActive
                : this.scheduleDashActive;
        },

        getScheduleImageAlt(schedule, index) {
            const isLastSchedule =
                index === this.isSidebarScheduleData.schedules.length - 1;
            const isSingleSchedule =
                this.isSidebarScheduleData.schedules.length === 1;

            const scheduleDate = moment(
                schedule.schedule,
                "DD MMMM YYYY | HH:mm"
            );
            const currentDate = moment();

            if (!scheduleDate.isValid()) {
                console.error("Invalid date parsing:", schedule.schedule);
                return "schedule dash passive";
            }

            if (scheduleDate.isBefore(currentDate)) {
                if (isLastSchedule) {
                    return "schedule passive";
                }
                return "schedule dash passive";
            }

            return isSingleSchedule || isLastSchedule
                ? "schedule active"
                : "schedule dash active";
        },
        formattedTime(type, event) {
            let value = event.target.value.replace(/\D/g, "");
            let number = parseInt(value);
            if (!isNaN(number)) {
                const formattedValue = number.toLocaleString("id-ID");
                if (type === "leadtime") {
                    this.leadtime = formattedValue;
                } else if (type === "price") {
                    this.price = formattedValue;
                }
            } else {
                if (type === "leadtime") {
                    this.leadtime = null;
                } else if (type === "price") {
                    this.price = null;
                }
            }
        },
        toggleUpdate() {
            this.isUpdate = !this.isUpdate;
        },
        updateSchedule() {
            this.isLoading = true;
            const data = {
                route_id: this.isSidebarScheduleData.id,
                schedule: moment(this.scheduleDate).format(
                    "YYYY-MM-DD HH:mm:ss"
                ),
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/vendors/routes/schedule/create`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isLoading = false;
                    this.modalSuccessSchedule = true;
                    this.modalConfirmSchedule = false;
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.modalConfirmSchedule = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        openModalConfirm(value) {
            this.modalConfirm = true;
            this.deletedData = value;
        },
        getRegencies() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`, {
                headers: {
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.regencies = res.data.data;
                })
                .catch((err) => {
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        async getFleets() {
            try {
                const response = await Api.get(
                    `${process.env.VUE_APP_SERVICE_URL}/master/fleet_type`,
                    {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                        },
                    }
                );
                this.fleets = response.data.data;
            } catch (err) {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: err.response.data.message,
                });
            }
        },
        async openSidebar(value) {
            this.isSidebarOpen = true;

            if (!this.hasLoadedRegencies) {
                this.getRegencies();
                this.hasLoadedRegencies = true;
            }

            await this.getDetailRoute(value);
        },
        closeSidebar() {
            this.isUpdate = false;
            this.isSidebarOpen = false;
            this.resetForm();
        },
        openSidebarSchedule(value) {
            this.isUpdate = false;
            this.isSidebarScheduleOpen = true;
            this.isSidebarScheduleData = value;
            console.log(this.isSidebarScheduleData);
        },
        closeScheduleSidebar() {
            this.isSidebarScheduleOpen = false;
            this.isSidebarScheduleData = {};
        },
        deleteData() {
            this.isLoading = true;
            var data = {
                route_id: this.deletedData,
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/vendors/routes/delete`,
                data,
                {
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isLoading = false;
                    this.modalSuccess = true;
                    this.modalConfirm = false;
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.modalConfirm = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        editData() {
            this.isLoading = true;
            let vehicle = "";
            if (this.vendorType.id === "darat") {
                vehicle = this.fleet.name;
            } else if (this.vendorType.id === "udara") {
                vehicle = this.planeName;
            } else if (this.vendorType.id === "laut") {
                vehicle = this.shipName;
            }

            const data = {
                route_id: this.detailRoute.id,
                vendor_type: this.vendorType.id,
                origin_regency_id: this.originRegency.id,
                destination_regency_id: this.destinationRegency.id,
                price: this.price ? this.price.replace(/\./g, "") : null,
                vehicle: vehicle,
                leadtime: this.leadtime
                    ? this.leadtime.replace(/\./g, "")
                    : null,
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/vendors/routes/edit`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isLoading = false;
                    this.modalSuccessEdit = true;
                    this.modalConfirmEdit = false;
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.modalConfirmEdit = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        onLimitChange() {
            this.currentPage = 1;
            this.getLists();
        },
        getLists() {
            let payload = {
                page: this.currentPage,
                search: this.search ? this.search : null,
                limit: this.limit ? this.limit : null,
                sort: this.sortDirection,
            };
            this.isLoader = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/vendors/routes`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.isLoader = false;
                    this.lists = res.data.data;
                    this.totalPage = res.data.data.total_page;
                    const totalItems = this.lists.list_data.length;
                    const totalData = res.data.data.total_data;
                    this.pageStart =
                        totalItems === 0
                            ? 0
                            : (this.currentPage - 1) * this.limit + 1;
                    this.pageEnd = Math.min(
                        Number(this.pageStart) + Number(this.limit) - 1,
                        totalData
                    );
                })
                .catch((err) => {
                    this.isLoader = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
        async getDetailRoute(value) {
            this.isLoadingDetail = true;
            let payload = {
                route_id: value,
            };
            try {
                const response = await Api.get(
                    `${process.env.VUE_APP_SERVICE_URL}/vendors/routes/detail`,
                    {
                        params: payload,
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("token"),
                        },
                    }
                );

                this.detailRoute = response.data.data;

                this.vendorType = this.vendorTypes.find(
                    (type) => type.id === this.detailRoute.vendor_type
                );

                this.originRegency = {
                    id: this.detailRoute.origin_regency_id,
                    name: this.detailRoute.origin_regency,
                };

                this.destinationRegency = {
                    id: this.detailRoute.destination_regency_id,
                    name: this.detailRoute.destination_regency,
                };

                this.price =
                    Number(this.detailRoute.price).toLocaleString("id-ID") ||
                    "";
                this.leadtime =
                    Number(this.detailRoute.leadtime).toLocaleString("id-ID") ||
                    "-";

                if (this.detailRoute.vendor_type === "darat") {
                    if (!this.hasLoadedFleets) {
                        await this.getFleets();
                        this.hasLoadedFleets = true;
                    }
                    this.fleet = this.fleets.find(
                        (fleet) =>
                            fleet.name.toLowerCase() ===
                            this.detailRoute.vehicle.toLowerCase()
                    );
                } else if (this.detailRoute.vendor_type === "udara") {
                    this.planeName = this.detailRoute.vehicle;
                } else if (this.detailRoute.vendor_type === "laut") {
                    this.shipName = this.detailRoute.vehicle;
                }

                this.schedule = this.detailRoute.schedules[0]?.schedule || "-";
                this.isLoadingDetail = false;
            } catch (err) {
                this.isLoadingDetail = false;
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: err.response.data.message,
                });
            }
        },
        resetForm() {
            this.vendorType = null;
            this.originRegency = null;
            this.destinationRegency = null;
            this.price = null;
            this.leadtime = null;
            this.fleet = null;
            this.planeName = null;
            this.shipName = null;
            this.schedule = null;
            this.hasLoadedFleets = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.width-m-100 {
    width: 100%;
    @media (min-width: 992px) {
        width: fit-content;
    }
}
.ml-0-mobile {
    margin-left: 0 !important;
}
@media only screen and (min-width: 992px) {
    .ml-0-mobile {
        margin-left: auto !important;
    }
}
.sticky-header {
    position: sticky;
    top: 0;
    background-color: #f9fafb;
    z-index: 1;
}
.sticky-footer {
    position: fixed;
    bottom: 0;
    background: #f9fafb;
    width: 100%;
    max-width: 500px;
    padding: 1rem;
    border-top: 1px solid #dee2e6;

    @media (max-width: 576px) {
        max-width: 100%;
        padding: 1rem;
    }
}
.text-schedule {
    color: #40bf76;
}
::v-deep .multi-form-custom.magnifier {
    .multiselect__tags {
        padding-left: 40px !important;
    }
    .multiselect__select {
        padding: 0;
    }
}
.table-responsive-custom {
    .table {
        border-radius: 8px;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #c1cdd7;

        th,
        td {
            border: 1px solid #c1cdd7;
        }
        thead {
            tr:first-child {
                th:first-child {
                    border-top-left-radius: 8px;
                }
                th:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        tbody {
            tr:last-child {
                td:first-child {
                    border-bottom-left-radius: 8px;
                }
                td:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}
.b-l-0 {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.b-r-0 {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.darat {
    border: 1px solid #c2cdd6;
    background-color: #e0e6eb;
    color: #3e4e5c;
}
.udara {
    border: 1px solid #f1eb74;
    background-color: #faf8d1;
    color: #8b850e;
}
.laut {
    border: 1px solid #99d0ff;
    background-color: #cce7ff;
    color: #005299;
}
.rotate-180 {
    transform: rotate(180deg);
}

.transition-transform {
    transition: transform 0.3s ease;
}
.header-sidebar {
    background-color: #f3f5f7;
    border: 1px solid #c1cdd7;
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
}
.body-sidebar {
    background-color: #ffffff;
    border: 1px solid #c1cdd7;
    border-top: none;
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
hr {
    color: #dee2e6 !important;
    margin: 1.5rem 0;
}
.input-sidebar {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}
.bb-r {
    border-bottom-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
}
.sidebar-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 1040;
}
.sidebar {
    overflow-y: auto;
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    max-width: 500px;
    height: 100vh;
    background-color: #f9fafb;
    z-index: 1050;
    transition: right 0.3s ease-in-out;
    box-shadow: -8px 0 10px -6px rgba(0, 0, 0, 0.1);
    margin-top: 55px;

    @media (max-width: 576px) {
        margin-top: 0;
        width: 100%;
        max-width: 100%;
    }
}

.sidebar-open {
    right: 0;
}
.modal-vue {
    position: fixed;
    z-index: 9999;
}
.btn-green:disabled {
    background: #e0e6eb !important;
    border: none !important;
    color: #849bae !important;
}
td {
    background-color: transparent !important;
}
th {
    background-color: #f3f5f7 !important;
}
.service {
    background-color: #cce7ff !important;
    border: 1px solid #99d0ff !important;
    color: #005299 !important;
}
::v-deep .page-link {
    background-color: #f3f5f7 !important;
}
::v-deep .page-item.active .page-link {
    background-color: #1291ff !important;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.outline-yellow {
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    background-color: transparent !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
.outline-yellow:focus {
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    background-color: transparent !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
.mb-5-rem {
    margin-bottom: 20rem;
}
</style>
