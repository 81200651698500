<template>
    <div class="padding-container">
        <div class="content-load" v-if="isPageLoading"></div>
        <div id="loader" v-if="isPageLoading"></div>
        <div
            class="d-flex flex-wrap justify-content-between align-items-center"
        >
            <div class="mb-3">
                <h4 class="fw-bold">
                    Halo {{ user.name }} 👋,
                    <span>Selamat Datang Kembali!</span>
                </h4>
                <p class="text-gray mb-0">
                    Menampilkan data tanggal
                    <span v-if="hasDateRange" class="fw-bold text-primary">
                        {{ formatDateRange(dateRange.from, dateRange.to) }}
                    </span>
                    <span class="fw-bold" v-else>{{
                        date.format("DD MMMM YYYY")
                    }}</span>
                </p>
            </div>
            <DateRangePicker
                v-model="dateRange"
                :onFilter="filterData"
                :onReset="resetData"
            />
        </div>
        <div class="row my-3">
            <div class="col-lg-4 my-3">
                <div class="box-orange h-100">
                    <p class="fw-bold">Resi Hari Ini</p>
                    <div class="box-danger">
                        <div class="d-flex align-items-center">
                            <img
                                src="../../assets/document-dashboard.svg"
                                alt="bell dashboard"
                                class="me-3"
                            />
                            <router-link
                                to="/order/cashier"
                                class="d-flex align-items-center"
                            >
                                <h5 class="text-white border-bottom me-2 mb-0">
                                    {{ datas.receipt_today }} Resi
                                </h5>
                                <img
                                    src="../../assets/arrow-dashboard.svg"
                                    alt="arrow dashboard"
                                    width="20"
                                    height="20"
                                />
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8 my-3">
                <div
                    class="box h-100 d-flex"
                    :class="{ 'box-shadow': hasDateRange }"
                >
                    <div
                        class="d-flex flex-column flex-lg-row justify-content-between align-items-center gap-3 w-100"
                    >
                        <div class="d-flex w-100">
                            <img
                                src="../../assets/total-item-dashboard.svg"
                                alt="total item dashboard"
                                class="me-3"
                            />
                            <div class="d-flex flex-column">
                                <p class="mb-2">Total Barang</p>
                                <h5 class="fw-bold">
                                    {{ datas.item.total_item }} Koli
                                </h5>
                            </div>
                        </div>
                        <hr class="d-none d-md-block vertical-divider" />
                        <div class="d-flex w-100">
                            <img
                                src="../../assets/weight-dashboard.svg"
                                alt="weight dashboard"
                                class="me-3"
                            />
                            <div class="d-flex flex-column">
                                <p class="mb-2">Total Berat Barang</p>
                                <h5 class="fw-bold">
                                    {{ datas.item.total_weight }} Kg
                                </h5>
                            </div>
                        </div>
                        <hr class="d-none d-md-block vertical-divider" />
                        <div class="d-flex w-100">
                            <img
                                src="../../assets/cubic-dashboard.svg"
                                alt="cubic dashboard"
                                class="me-3"
                            />
                            <div class="d-flex flex-column">
                                <p class="mb-2">Total Kubikasi</p>
                                <h5 class="fw-bold">
                                    {{ datas.item.total_cubic }} M3
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 my-3">
                <div class="box h-100" :class="{ 'box-shadow': hasDateRange }">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex flex-column justify-content-center">
                            <p v-if="hasDateRange">Total Ukur-Timbang</p>
                            <p v-else>Total Ukur-Timbang Hari Ini</p>
                            <h5 class="text-black me-2 fw-bold mb-3">
                                {{
                                    datas.total_scale.pending +
                                    datas.total_scale.success
                                }}
                                Barang
                            </h5>
                            <div class="d-flex align-items-center mb-3">
                                <img
                                    src="../../assets/item-pending-dashboard.svg"
                                    alt="item pending dashboard"
                                    class="me-3"
                                />
                                <div class="d-flex flex-column">
                                    <p class="mb-0">
                                        {{ datas.total_scale.pending }} Barang
                                    </p>
                                    <p class="text-gray mb-0">Pending</p>
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <img
                                    src="../../assets/item-success-dashboard.svg"
                                    alt="item success dashboard"
                                    class="me-3"
                                />
                                <div class="d-flex flex-column">
                                    <p class="mb-0">
                                        {{ datas.total_scale.success }} Barang
                                    </p>
                                    <p class="text-gray mb-0">Telah Selesai</p>
                                </div>
                            </div>
                        </div>
                        <donut-chart
                            :data="pickupData"
                            customCenterTextLabel="Barang"
                        />
                    </div>
                </div>
            </div>
            <div class="col-lg-5 my-3">
                <div
                    class="box h-100 d-flex flex-column justify-content-center"
                    :class="{ 'box-shadow': hasDateRange }"
                >
                    <div class="d-flex">
                        <img
                            src="../../assets/in-dashboard.svg"
                            alt="in dashboard"
                            class="me-3"
                        />
                        <div class="d-flex flex-column justify-content-center">
                            <p class="mb-2">Total Barang Diukur</p>
                            <h5 class="fw-bold mb-0">
                                {{ datas.calculate_count.total_calculate }}
                                Barang
                            </h5>
                        </div>
                    </div>
                    <hr />
                    <div class="d-flex">
                        <img
                            src="../../assets/out-dashboard.svg"
                            alt="out dashboard"
                            class="me-3"
                        />
                        <div class="d-flex flex-column justify-content-center">
                            <p class="mb-2">Total Barang Selesai Diklaim</p>
                            <h5 class="fw-bold mb-0">
                                {{ datas.calculate_count.total_claimed }} Barang
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 my-3">
                <div
                    class="box h-100 d-flex flex-column justify-content-center"
                    :class="{ 'box-shadow': hasDateRange }"
                >
                    <p>Dalam Proses</p>
                    <div class="d-flex">
                        <img
                            src="../../assets/route-dashboard.svg"
                            alt="route dashboard"
                            class="me-3"
                        />
                        <div class="d-flex flex-column justify-content-center">
                            <p class="mb-2">Proses Transit</p>
                            <h5 class="fw-bold mb-0">
                                {{ datas.process_receipt.transit }} Resi
                            </h5>
                        </div>
                    </div>
                    <hr />
                    <div class="d-flex">
                        <img
                            src="../../assets/ontheway-dashboard.svg"
                            alt="ontheway dashboard"
                            class="me-3"
                        />
                        <div class="d-flex flex-column justify-content-center">
                            <p class="mb-2">Dalam Perjalanan</p>
                            <h5 class="fw-bold mb-0">
                                {{ datas.process_receipt.ontheway }} Resi
                            </h5>
                        </div>
                    </div>
                    <hr />
                    <div class="d-flex">
                        <img
                            src="../../assets/manifest-dashboard.svg"
                            alt="manifest dashboard"
                            class="me-3"
                        />
                        <div class="d-flex flex-column justify-content-center">
                            <p class="mb-2">Total Manifest</p>
                            <h5 class="fw-bold mb-0">
                                {{ datas.process_receipt.total_manifest }}
                                Manifest
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 my-3">
                <div
                    class="box h-100 d-flex flex-column justify-content-center"
                    :class="{ 'box-shadow': hasDateRange }"
                >
                    <div class="d-flex justify-content-between">
                        <div class="d-flex flex-column justify-content-center">
                            <p v-if="hasDateRange">
                                Total Resi
                                {{
                                    formatDateRange(
                                        dateRange.from,
                                        dateRange.to
                                    )
                                }}
                            </p>
                            <p v-else>Total Resi</p>
                            <h5 class="text-black me-2 fw-bold mb-3">
                                {{
                                    datas.total_receipt.deal +
                                    datas.total_receipt.success +
                                    datas.total_receipt.cancel
                                }}
                                Resi
                            </h5>
                            <div class="d-flex align-items-center mb-3">
                                <img
                                    src="../../assets/deal-dashboard.svg"
                                    alt="item pending dashboard"
                                    class="me-3"
                                />
                                <div class="d-flex flex-column">
                                    <div class="d-flex">
                                        <router-link
                                            to="/deal-order"
                                            class="mb-2 border-black text-black border-bottom me-2"
                                            >{{
                                                datas.total_receipt.deal
                                            }}
                                            Resi</router-link
                                        >
                                        <img
                                            src="../../assets/arrow-dashboard-2.svg"
                                            alt="arrow dashboard"
                                            width="20"
                                            height="20"
                                        />
                                    </div>
                                    <p class="text-gray mb-0">Deal</p>
                                </div>
                            </div>
                            <div class="d-flex align-items-center mb-3">
                                <img
                                    src="../../assets/done-dashboard.svg"
                                    alt="item success dashboard"
                                    class="me-3"
                                />
                                <div class="d-flex flex-column">
                                    <p class="mb-0">
                                        {{ datas.total_receipt.success }} Resi
                                    </p>
                                    <p class="text-gray mb-0">Selesai</p>
                                </div>
                            </div>
                        </div>
                        <donut-chart
                            :data="resiData"
                            customCenterTextLabel="Resi"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import DonutChart from "../../components/DonutChart.vue";
import moment from "moment";
import Api from "../../api/Api";
import "moment/locale/id";
import DateRangePicker from "../../components/DateRangePicker.vue";

moment.locale("id");

export default {
    components: {
        DonutChart,
        DateRangePicker,
    },
    data() {
        return {
            dateRange: {
                from: null,
                to: null,
            },
            date: moment(),
            datas: {
                receipt_today: null,
                item: {
                    total_item: null,
                    total_weight: null,
                    total_cubic: null,
                },
                total_scale: {
                    pending: null,
                    success: null,
                },
                calculate_count: {
                    total_calculate: null,
                    total_claimed: null,
                },
                process_receipt: {
                    transit: null,
                    ontheway: null,
                    total_manifest: null,
                },
                total_receipt: {
                    deal: null,
                    success: null,
                    cancel: null,
                },
            },
        };
    },
    computed: {
        hasDateRange() {
            return this.dateRange && this.dateRange.from && this.dateRange.to;
        },
        pickupData() {
            return [
                {
                    label: "Belum Diukur - Timbang",
                    value: this.datas.total_scale.pending || 0,
                    color: "#FF8600",
                },
                {
                    label: "Telah Selesai Diukur - Timbang",
                    value: this.datas.total_scale.success || 0,
                    color: "#40BF76",
                },
            ];
        },
        resiData() {
            return [
                {
                    label: "Resi Deal",
                    value: this.datas.total_receipt.deal || 0,
                    color: "#1291FF",
                },
                {
                    label: "Resi Selesai",
                    value: this.datas.total_receipt.success || 0,
                    color: "#40BF76",
                },
            ];
        },
    },
    created() {
        this.getDatas();
        const userFromLocalStorage = localStorage.getItem("user");

        if (userFromLocalStorage) {
            this.user = JSON.parse(userFromLocalStorage);
        }
    },
    methods: {
        formatDateRange(fromDate, toDate) {
            if (!fromDate || !toDate) return "";

            const from = moment(fromDate);
            const to = moment(toDate);

            return `${from.format("D")} ${from.format(
                "MMMM YYYY"
            )} - ${to.format("D")} ${to.format("MMMM YYYY")}`;
        },
        filterData(dateRange) {
            this.dateRange = dateRange;
            this.getDatas();
        },
        resetData() {
            this.dateRange.from = null;
            this.dateRange.to = null;
            this.getDatas();
        },
        getDatas() {
            this.isPageLoading = true;

            let payload;

            if (this.dateRange && this.dateRange.from && this.dateRange.to) {
                payload = {
                    start_date: moment(this.dateRange.from).format(
                        "YYYY-MM-DD"
                    ),
                    end_date: moment(this.dateRange.to).format("YYYY-MM-DD"),
                };
            } else {
                payload = {
                    start_date: this.date.format("YYYY-MM-DD"),
                    end_date: this.date.format("YYYY-MM-DD"),
                };
            }

            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/dashboard/ops_manager`,
                {
                    params: payload,
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then((res) => {
                    this.datas = res.data.data;
                    this.isPageLoading = false;
                })
                .catch((err) => {
                    this.isPageLoading = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.box-shadow {
    box-shadow: 1px 1px 10px 0px rgba(18, 145, 255, 0.3);
}
.table-responsive-custom {
    &.table-scrollable {
        max-height: 410px;
        overflow-y: auto;
        &::-webkit-scrollbar {
            display: none;
        }
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .table {
        border-radius: 8px;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #c1cdd7;

        th {
            border: 1px solid #c1cdd7;
            background-color: #f3f5f7;
        }

        td {
            border: 1px solid #c1cdd7;
            background-color: #f9fafb;
        }

        thead {
            tr:first-child {
                th:first-child {
                    border-top-left-radius: 8px;
                }
                th:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        tbody {
            tr:last-child {
                td:first-child {
                    border-bottom-left-radius: 8px;
                }
                td:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}
.vertical-divider {
    height: 100%;
    border: 1px solid #c1cdd7;
    margin: 0;
}
@media (max-width: 800px) {
    .d-flex.w-100:not(:last-child) {
        border-bottom: 1px solid #c1cdd7;
        padding-bottom: 1rem;
    }
}
hr {
    border-top: 1px solid #c1cdd7 !important;
}
hr.vertical-divider {
    height: 100%;
    border: 1px solid #c1cdd7;
}
.text-black {
    color: #212529 !important;
}
.col-md-5.col-md-5-custom {
    width: 36.666667% !important;
}
.col-md-7.col-md-7-custom {
    width: 63.333333% !important;
}
@media only screen and (max-width: 800px) {
    .col-md-5.col-md-5-custom {
        width: 100% !important;
    }
    .col-md-7.col-md-7-custom {
        width: 100% !important;
    }
}
.dashboard-container {
    padding: 48px 20px 40px 20px;
}

.box-orange {
    border: 1px solid #99d0ff;
    background-color: #cce5ff;
    border-radius: 16px;
    padding: 15px 15px;
}
.box-danger {
    background-color: #1291ff;
    border-radius: 16px;
    padding: 15px 15px;
}
</style>
