<template>
    <div class="padding-container">
        <div class="fw-bold">
            <div
                class="text-gray-dashboard size-16 cursor-pointer"
                @click="$router.go(-1)"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </div>
        </div>
        <div class="box-manifest mt-4">
            <div class="row">
                <div
                    class="col-lg-12"
                    v-for="(account, index) in accounts"
                    :key="index"
                >
                    <div class="d-flex justify-content-between">
                        <h5 class="fw-bold">Akun User {{ index + 1 }}</h5>
                        <button
                            class="btn btn-delete d-flex align-items-center outline-green"
                            v-if="accounts.length > 1 && index === 1"
                            @click="removeAccount(index)"
                        >
                            <img
                                src="../assets/delete.svg"
                                alt="trash"
                                class="me-2"
                            />
                            Hapus Bagian
                        </button>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 my-2">
                            <div class="row">
                                <div class="col-lg-6">
                                    <label class="fw-bold mb-2"
                                        >Peran
                                        <span class="text-yellow"
                                            >*</span
                                        ></label
                                    >
                                    <multiselect
                                        v-model="account.role"
                                        :options="roles"
                                        label="name"
                                        track-by="id"
                                        placeholder="Pilih peran user"
                                        class="multi-form-custom"
                                        :show-labels="false"
                                    >
                                    </multiselect>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 my-2">
                            <label class="fw-bold mb-2"
                                >Nama <span class="text-yellow">*</span></label
                            >
                            <input
                                type="text"
                                class="form-control"
                                v-model="account.name"
                                placeholder="Masukkan nama"
                            />
                        </div>
                        <div class="col-lg-6 my-2">
                            <label class="fw-bold mb-2"
                                >Email <span class="text-yellow">*</span></label
                            >
                            <input
                                type="email"
                                class="form-control"
                                v-model="account.email"
                                placeholder="Masukkan email"
                                @input="validateEmail(account)"
                            />
                            <div
                                v-if="account.emailError"
                                class="d-flex align-items-center mt-2"
                            >
                                <img
                                    src="../assets/mini-warning.svg"
                                    alt="mini warning"
                                    class="me-2"
                                />
                                <small class="text-yellow">{{
                                    account.emailError
                                }}</small>
                            </div>
                        </div>
                        <div class="col-lg-6 my-2">
                            <label class="fw-bold mb-2"
                                >Password Akun
                                <span class="text-yellow">*</span></label
                            >
                            <div class="password-container">
                                <input
                                    :type="
                                        account.showPassword
                                            ? 'text'
                                            : 'password'
                                    "
                                    class="form-control"
                                    v-model="account.password"
                                    placeholder="Buat password akun"
                                    @input="validatePassword(account)"
                                />
                                <div class="eye-icons">
                                    <img
                                        :src="
                                            account.showPassword
                                                ? eyeShowIcon
                                                : eyeHideIcon
                                        "
                                        :alt="
                                            account.showPassword
                                                ? 'eye show'
                                                : 'eye hide'
                                        "
                                        @click="
                                            () =>
                                                togglePasswordVisibility(
                                                    account
                                                )
                                        "
                                        class="eye-icon"
                                    />
                                </div>
                            </div>
                            <div
                                v-if="account.passwordError"
                                class="d-flex align-items-center mt-2"
                            >
                                <img
                                    src="../assets/mini-warning.svg"
                                    alt="mini warning"
                                    class="me-2"
                                />
                                <small class="text-yellow">{{
                                    account.passwordError
                                }}</small>
                            </div>
                        </div>
                        <div class="col-lg-6 my-2">
                            <label class="fw-bold mb-2"
                                >Konfirmasi Password Akun
                                <span class="text-yellow">*</span></label
                            >
                            <div class="password-container">
                                <input
                                    :type="
                                        account.showConfirmPassword
                                            ? 'text'
                                            : 'password'
                                    "
                                    class="form-control"
                                    v-model="account.passwordConfirmation"
                                    placeholder="Konfirmasi password akun"
                                    @input="
                                        validatePasswordConfirmation(account)
                                    "
                                />
                                <div class="eye-icons">
                                    <img
                                        :src="
                                            account.showConfirmPassword
                                                ? eyeShowIcon
                                                : eyeHideIcon
                                        "
                                        :alt="
                                            account.showConfirmPassword
                                                ? 'eye show'
                                                : 'eye hide'
                                        "
                                        @click="
                                            () =>
                                                toggleConfirmPasswordVisibility(
                                                    account
                                                )
                                        "
                                        class="eye-icon"
                                    />
                                </div>
                            </div>
                            <div
                                v-if="account.passwordConfirmationError"
                                class="d-flex align-items-center mt-2"
                            >
                                <img
                                    src="../assets/mini-warning.svg"
                                    alt="mini warning"
                                    class="me-2"
                                />
                                <small class="text-yellow">{{
                                    account.passwordConfirmationError
                                }}</small>
                            </div>
                        </div>
                        <div class="col-lg-12 my-2">
                            <div class="row">
                                <div class="col-lg-3">
                                    <label class="fw-bold mb-2"
                                        >Foto Profile</label
                                    >
                                    <div
                                        class="box d-flex flex-column justify-content-center py-5 position-relative cursor-pointer h-14"
                                        :class="{
                                            'padding-0': account.profileImage,
                                        }"
                                        @click="triggerFileInput(index)"
                                    >
                                        <input
                                            type="file"
                                            :ref="`fileInput${index}`"
                                            @change="
                                                handleFileUpload(index, $event)
                                            "
                                            accept="image/*"
                                            class="d-none"
                                        />
                                        <div
                                            v-if="!account.profileImage"
                                            class="text-center"
                                        >
                                            <img
                                                src="../assets/upload-profile.svg"
                                                alt="upload profile"
                                            />
                                            <p
                                                class="text-gray text-center mb-0"
                                            >
                                                Upload foto profile
                                            </p>
                                        </div>
                                        <div
                                            v-else
                                            class="profile-image-preview"
                                        >
                                            <img
                                                :src="account.profileImage"
                                                alt="Profile Preview"
                                                class="img-fluid rounded border-1-rem"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr class="my-5" />
                    </div>
                </div>
                <div class="col-lg-12 my-2">
                    <button
                        class="btn w-100 btn-add d-flex align-items-center justify-content-center"
                        @click="addAccount"
                        disabled
                    >
                        <i class="fa fa-plus me-2"></i>
                        Tambah Akun User
                    </button>
                </div>
                <div class="col-lg-12 my-2 d-flex justify-content-end mt-4">
                    <button
                        class="btn btn-create px-5"
                        :disabled="!isFormValid"
                    >
                        Buat Akun User
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="addLimit">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Limit akun untuk peran ini sudah tercapai
                    </h4>
                </div>
                <button type="button" class="btn btn-primary w-100 btn-lg mt-4">
                    Tutup
                </button>
            </div>
        </div>
        <div class="modal-vue" v-if="deleteSection">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        Informasi Akun Ini Akan Dihapus
                    </h4>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin menghapus informasi ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <button class="btn btn-outline-yellow w-100 btn-lg">
                            Kembali
                        </button>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                        >
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="createAccount">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">
                        2 akun CRM specialist akan dibuat
                    </h4>
                    <div class="text-gray-dashboard">
                        Pastikan data yang diisi sudah benar
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <button class="btn btn-outline-yellow w-100 btn-lg">
                            Kembali
                        </button>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                        >
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="createSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Akun User Berhasil Dibuat</h4>
                </div>
                <div class="mt-4">
                    <router-link to="#" class="btn btn-primary w-100 btn-lg">
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import eyeHideIcon from "@/assets/eye-hide.svg";
import eyeShowIcon from "@/assets/eye-show.svg";

export default {
    components: {
        Multiselect,
    },
    data() {
        return {
            accounts: [
                {
                    role: null,
                    name: "",
                    email: "",
                    emailError: "",
                    password: "",
                    passwordError: "",
                    passwordConfirmation: "",
                    passwordConfirmationError: "",
                    showPassword: false,
                    showConfirmPassword: false,
                    profileImage: null,
                },
            ],
            eyeHideIcon,
            eyeShowIcon,
            roles: [],
            addLimit: false,
            deleteSection: false,
            createAccount: false,
            createSuccess: false,
        };
    },
    computed: {
        isFormValid() {
            return this.accounts.every(
                (account) =>
                    account.role !== null &&
                    account.name.trim() !== "" &&
                    account.email.trim() !== "" &&
                    account.emailError === "" &&
                    account.password.trim() !== "" &&
                    account.passwordError === "" &&
                    account.passwordConfirmation.trim() !== "" &&
                    account.passwordConfirmationError === ""
            );
        },
    },
    methods: {
        validateEmail(account) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(account.email)) {
                account.emailError = "Format email salah";
            } else {
                account.emailError = "";
            }
        },
        validatePassword(account) {
            if (account.password.length < 6) {
                account.passwordError =
                    "Password harus terdiri dari min. 6 karakter";
            } else {
                account.passwordError = "";
            }
            if (account.passwordConfirmation) {
                this.validatePasswordConfirmation(account);
            }
        },
        validatePasswordConfirmation(account) {
            if (account.password !== account.passwordConfirmation) {
                account.passwordConfirmationError = "Password tidak sesuai";
            } else {
                account.passwordConfirmationError = "";
            }
        },
        togglePasswordVisibility(account) {
            account.showPassword = !account.showPassword;
        },
        toggleConfirmPasswordVisibility(account) {
            account.showConfirmPassword = !account.showConfirmPassword;
        },
        triggerFileInput(index) {
            this.$refs[`fileInput${index}`][0].click();
        },
        handleFileUpload(index, event) {
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                this.accounts[index].profileImage = e.target.result;
            };
            reader.readAsDataURL(file);
        },
        addAccount() {
            this.accounts.push({
                role: null,
                name: "",
                email: "",
                emailError: "",
                password: "",
                passwordError: "",
                passwordConfirmation: "",
                passwordConfirmationError: "",
                showPassword: false,
                showConfirmPassword: false,
                profileImage: null,
            });
        },
        removeAccount(index) {
            if (this.accounts.length > 1) {
                this.accounts.splice(index, 1);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.outline-green {
    border: 1px solid #ff8600 !important;
    color: #ff8600 !important;
    background-color: transparent !important;
    border-radius: 8px;
}
.border-1-rem {
    border-radius: 1rem !important;
}
hr {
    color: #c1cdd7 !important;
}
.h-14 {
    height: 14rem;
}
.padding-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}
.profile-image-preview {
    position: relative;
    width: 100%;
    height: 100%;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .change-photo-overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.5);
        color: white;
        text-align: center;
        padding: 10px;
        cursor: pointer;
    }
}
.btn-create {
    background-color: #1291ff;
    color: #ffffff;
}
.btn-create:hover {
    background-color: #1291ff;
    color: #ffffff;
}
.btn-create:disabled {
    background-color: #849bae;
    color: #ffffff;
    cursor: not-allowed;
}
.btn-add {
    background-color: transparent;
    border: 1px solid #40bf76;
    color: #40bf76;
}
.btn-add:disabled {
    background-color: transparent !important;
    border: 1px solid #849bae !important;
    color: #849bae !important;
}
.btn-add:hover {
    background-color: transparent;
    border: 1px solid #849bae;
    color: #849bae;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.password-container {
    position: relative;
    .eye-icons {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
    }
    .eye-icon {
        width: 20px;
        height: 20px;
    }
    input {
        padding-right: 40px;
    }
}
</style>
