<template>
    <div class="padding-container">
        <div class="content-load" v-if="is_loading_data"></div>
        <div id="loader" v-if="is_loading_data"></div>
        <div class="fw-bold">
            <router-link to="/data/leads" class="text-gray-dashboard size-16">
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </router-link>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-6 mt-4">
                <div class="box">
                    <center>
                        <div class="size-20 fw-bold">Informasi Driver</div>
                        <div
                            class="user-avatar-sidebar mt-3"
                            :style="{
                                'background-image':
                                    'url(' +
                                    require('../../assets/user.jpg') +
                                    ')',
                            }"
                        ></div>
                    </center>
                    <table class="w-100 ps-0 fw-bold mt-3" cellpadding="10">
                        <tr class="border-bottom">
                            <td class="ps-0 w-80 fw-medium">Nama Driver</td>
                            <td class="w-30">:</td>
                            <td class="fw-bold">
                                {{ detail.driver_information.name }}
                            </td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="ps-0 w-80 fw-medium">No. Telp Driver</td>
                            <td class="w-30">:</td>
                            <td class="fw-bold">
                                {{ detail.driver_information.phone }}
                            </td>
                        </tr>
                        <tr>
                            <td class="ps-0 w-80 fw-medium">Armada</td>
                            <td class="w-30">:</td>
                            <td class="fw-bold">
                                {{ detail.driver_information.fleet }}
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
        <div class="box mt-4">
            <div class="d-flex">
                <h3 class="fw-bold">Daftar Orderan</h3>
                <div class="ms-auto text-primary">
                    {{ order_list.length }} Lokasi Tujuan
                </div>
            </div>
            <div>
                Tanggal Penugasan Driver : {{ dataParams.assigned_driver }}
            </div>
            <ul class="nav nav-pills mt-4" id="pills-tab" role="tablist">
                <li class="nav-item w-50" role="presentation">
                    <button
                        class="nav-link dashboard left w-100 active"
                        id="pills-penjemputan-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-penjemputan"
                        type="button"
                        role="tab"
                        aria-controls="pills-penjemputan"
                        aria-selected="true"
                    >
                        Penjemputan
                    </button>
                </li>
                <li class="nav-item w-50" role="presentation">
                    <button
                        class="nav-link dashboard right w-100 cursor-drop"
                        id="pills-dooring-tab"
                        data-bs-target="#pills-dooring"
                        type="button"
                        role="tab"
                        aria-controls="pills-dooring"
                        aria-selected="false"
                    >
                        Dooring
                    </button>
                </li>
            </ul>
            <div
                class="box mt-4 p-0"
                v-for="(item, index) in order_list"
                :key="index"
            >
                <div class="header-card">
                    <div class="d-flex align-items-center">
                        <div class="me-1">
                            <img
                                src="../../assets/truck.png"
                                width="20"
                                alt=""
                            />
                        </div>
                        <div class="fw-bold size-16 capitalize">
                            {{ item.type }}
                        </div>
                        <div class="ms-auto">
                            <button
                                class="tag red no-border cursor-drop"
                                disabled
                            >
                                <i class="fa fa-trash"></i>
                                Hapus
                            </button>
                        </div>
                    </div>
                </div>
                <div class="p-4 pt-2 pb-2">
                    <table class="w-100 ps-0 fw-bold" cellpadding="10">
                        <tr class="border-bottom">
                            <td class="ps-0 w-80 fw-normal">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="d-flex">
                                            <div class="me-1">
                                                <img
                                                    src="../../assets/location.png"
                                                    width="16"
                                                    alt=""
                                                />
                                            </div>
                                            <div class="text-green fw-bold">
                                                {{ item.regency }},
                                                {{ item.province }}
                                                <div class="text-primary">
                                                    <u>
                                                        {{ item.pickup }}
                                                    </u>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div>
                                            Status
                                            <div class="mt-1">
                                                <span
                                                    class="tag yellow-cro radius px-3"
                                                >
                                                    {{ item.status }}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="ps-0 w-80 fw-normal">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="d-flex">
                                            <div class="me-1">
                                                <img
                                                    src="../../assets/profile.png"
                                                    width="16"
                                                    alt=""
                                                />
                                            </div>
                                            <div>{{ item.customer }}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="d-flex">
                                            <div class="me-1">
                                                <img
                                                    src="../../assets/wa.png"
                                                    width="16"
                                                    alt=""
                                                />
                                            </div>
                                            <div>+{{ item.phone }}</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="ps-0 w-80 fw-normal">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="d-flex">
                                            <div class="me-1">
                                                <img
                                                    src="../../assets/dimension.png"
                                                    width="16"
                                                    alt=""
                                                />
                                            </div>
                                            <div>
                                                {{
                                                    item?.dimension?.width
                                                        ? item?.dimension?.width
                                                        : 0
                                                }}cm X
                                                {{
                                                    item?.dimension?.length
                                                        ? item?.dimension
                                                              ?.length
                                                        : 0
                                                }}cm X
                                                {{
                                                    item?.dimension?.height
                                                        ? item?.dimension
                                                              ?.height
                                                        : 0
                                                }}cm
                                            </div>
                                        </div>
                                        <div class="d-flex mt-2">
                                            <div class="me-1">
                                                <img
                                                    src="../../assets/notes.png"
                                                    width="16"
                                                    alt=""
                                                />
                                            </div>
                                            <div>{{ item.description }}</div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="d-flex">
                                            <div class="me-1">
                                                <img
                                                    src="../../assets/kg.png"
                                                    width="16"
                                                    alt=""
                                                />
                                            </div>
                                            <div>{{ item.weight }} Kg</div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr class="border-top" v-if="item.isShow">
                            <td class="ps-0 w-80 fw-normal">
                                <div class="size-16 fw-bold">
                                    Status Penjemputan
                                </div>
                                <div class="experience mt-3">
                                    <div
                                        class="item"
                                        v-bind:class="{
                                            active: index == 0,
                                        }"
                                        v-for="(
                                            timeline, index
                                        ) in item.pickup_status"
                                        :key="index"
                                    >
                                        <div
                                            class="fw-semibold"
                                            v-bind:class="{
                                                'text-green': index == 0,
                                            }"
                                        >
                                            {{ timeline.status }}
                                        </div>
                                        <div class="job-info">
                                            <div
                                                class="size-12 text-gray-dashboard"
                                            >
                                                {{ timeline.date }}
                                            </div>
                                            <div
                                                class="size-12 text-gray-dashboard"
                                            >
                                                {{ timeline.time }}
                                            </div>
                                        </div>
                                        <div
                                            class="text-gray-dashboard size-12"
                                        >
                                            {{ timeline.description }}
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div class="border-top">
                    <div class="p-3 text-center">
                        <a
                            href="javascript:void(0)"
                            @click="showContent(index)"
                            class="text-dark"
                        >
                            <div
                                class="d-flex align-items-center justify-content-center"
                            >
                                <template v-if="item.isShow == true">
                                    <div>Lihat Lebih Sedikit</div>
                                    <i class="fa fa-angle-up size-20 ms-1"></i>
                                </template>
                                <template v-if="item.isShow == false">
                                    <div>Lihat Lebih Banyak</div>
                                    <i
                                        class="fa fa-angle-down size-20 ms-1"
                                    ></i>
                                </template>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Api from "../../api/Api";
import moment from "moment";

export default {
    name: "assignmentDetail",
    components: {},
    data() {
        return {
            moment: moment,
            detail: {
                driver_information: {},
            },
            order_list: [],
            is_loading_data: false,
            dataParams: {},
        };
    },
    created() {
        this.getDetail();
    },
    mounted() {
        const data = sessionStorage.getItem("dataParams");
        this.dataParams = JSON.parse(data);
    },
    computed: {},
    methods: {
        showContent(index) {
            this.order_list[index].isShow = !this.order_list[index].isShow;
        },
        getDetail() {
            const params = sessionStorage.getItem("dataParams");
            const dataParams = JSON.parse(params);
            this.is_loading_data = true;
            var data = {
                driver_fleet_id: dataParams.driver_fleet_id,
                date: dataParams.assigned_driver,
            };
            Api.get(
                `${process.env.VUE_APP_SERVICE_URL}/pickup/assigned/detail`,
                {
                    params: data,
                    headers: {
                        Authorization: "Bearer" + localStorage.getItem("token"),
                    },
                }
            )

                .then((res) => {
                    this.detail = res.data.data;
                    this.order_list = res.data.data.order_list.map((item) => {
                        return {
                            ...item,
                            isShow: false,
                        };
                    });
                    this.is_loading_data = false;
                })
                .catch((err) => {
                    this.is_loading_data = false;
                    console.log(err);
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.user-avatar-sidebar {
    width: 60px;
    height: 60px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50px;
    position: relative;
    border: 2px solid #f8f9fa;
}
.w-80 {
    width: 150px;
}
.w-30 {
    width: 30px;
}
.nav-link.dashboard {
    background: #f5f5f5;
    color: #8e8e94;
}
.nav-link.dashboard.right {
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    border-bottom-right-radius: 20px !important;
    border-top-right-radius: 20px !important;
}
.nav-link.dashboard.left {
    border-bottom-right-radius: 0px !important;
    border-top-right-radius: 0px !important;
    border-bottom-left-radius: 20px !important;
    border-top-left-radius: 20px !important;
}
.nav-link.dashboard.active {
    background-color: #3782e6 !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
    color: #fff !important;
}
.header-card {
    background: #f5f5f5;
    width: 100%;
    padding: 14px;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}
.no-border {
    border: none !important;
}
.experience {
    border-left: 3px dashed #d9f2e4;
    padding: 0 30px;
    margin-left: 60px;
}
.experience .item {
    position: relative;
    margin-bottom: 16px;
}
.experience .item::before {
    content: "";
    position: absolute;
    left: -39px;
    top: 0;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #d9f2e4;
}
.experience .item.active::before {
    background: #40bf76;
    border: 5px solid #d9f2e4;
    width: 20px;
    height: 20px;
    left: -41px;
}
.experience .item::after {
    content: "";
    position: absolute;
    left: -37px;
    top: 6px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.experience .job-info {
    position: absolute;
    left: -92px;
    top: 0;
}
</style>
