<template>
    <div class="padding-container">
        <div class="content-load" v-if="isPageLoading"></div>
        <div id="loader" v-if="isPageLoading"></div>
        <div class="fw-bold">
            <div
                class="text-gray-dashboard size-16 cursor-pointer"
                @click="$router.go(-1)"
            >
                <i class="fa fa-angle-left me-2"></i>
                Kembali
            </div>
        </div>
        <div class="box-manifest mt-4">
            <h5 class="fw-bold mb-4">Buat Manifest</h5>
            <div class="row">
                <div class="mb-4 col-lg-6">
                    <label class="mb-2"
                        >Kota/Kabupaten Asal Manifest
                        <span class="text-yellow">*</span></label
                    >
                    <multiselect
                        v-model="originRegency"
                        :options="regencies"
                        label="name"
                        track-by="id"
                        placeholder="Cari dan pilih kota/kab. asal"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                        <template v-slot:caret>
                            <img
                                :src="
                                    originRegency
                                        ? magnifierActiveIcon
                                        : magnifierIcon
                                "
                                alt="icon"
                                style="
                                    width: 20px;
                                    height: 20px;
                                    position: absolute;
                                    left: 12px;
                                    top: 42%;
                                    transform: translateY(-50%);
                                "
                            />
                        </template>
                    </multiselect>
                </div>
                <div class="mb-4 col-lg-6">
                    <label class="mb-2"
                        >Kota/Kabupaten Tujuan Manifest
                        <span class="text-yellow">*</span></label
                    >
                    <multiselect
                        v-model="destinationRegency"
                        :options="regencies"
                        label="name"
                        track-by="id"
                        placeholder="Cari dan pilih kota/kab. tujuan"
                        class="multi-form-custom"
                        :show-labels="false"
                    >
                        <template v-slot:caret>
                            <img
                                :src="
                                    destinationRegency
                                        ? magnifierActiveIcon
                                        : magnifierIcon
                                "
                                alt="icon"
                                style="
                                    width: 20px;
                                    height: 20px;
                                    position: absolute;
                                    left: 12px;
                                    top: 42%;
                                    transform: translateY(-50%);
                                "
                            />
                        </template>
                    </multiselect>
                </div>
                <div class="mb-4 col-lg-12">
                    <label class="mb-3"
                        >Pilih resi yang yang akan ditambahkan pada manifest
                        <span class="text-yellow">*</span></label
                    >
                    <div class="box">
                        <div
                            class="d-flex justify-content-between align-items-center flex-wrap"
                        >
                            <h5 class="fw-bold mb-0">Daftar Resi</h5>
                            <div class="position-relative my-2">
                                <div class="input-group">
                                    <input
                                        type="text"
                                        class="form-control form-control-lg pl-filter-search w-search border-search"
                                        placeholder="Cari kota tujuan/nomor resi"
                                        v-model="search"
                                        :disabled="
                                            originRegency === null ||
                                            destinationRegency === null
                                        "
                                        @keyup.enter="handleSearch()"
                                    />
                                    <button
                                        class="btn btn-search px-4"
                                        type="button"
                                        :disabled="
                                            originRegency === null ||
                                            destinationRegency === null
                                        "
                                        @click="handleSearch()"
                                    >
                                        Cari
                                    </button>
                                </div>
                                <span class="magnifier-icon left">
                                    <img
                                        :src="
                                            originRegency === null ||
                                            destinationRegency === null ||
                                            search === null ||
                                            search === ''
                                                ? magnifierIcon
                                                : magnifierActiveIcon
                                        "
                                        alt="icon magnifier"
                                        width="20"
                                        height="20"
                                    />
                                </span>
                            </div>
                        </div>
                        <div
                            class="overflow-auto"
                            v-if="
                                originRegency !== null &&
                                destinationRegency !== null &&
                                filteredReceipts.length !== 0
                            "
                        >
                            <div
                                class="d-flex justify-content-between align-items-center mt-4"
                            >
                                <div class="d-flex align-items-center">
                                    <img
                                        src="../assets/arrow-right.svg"
                                        alt="arrow-right"
                                        class="me-3"
                                    />
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        :checked="selectAll"
                                        @change="toggleSelectAll"
                                    />
                                    <p class="mb-0 ms-3">Tandai Semua</p>
                                </div>
                                <div class="d-flex align-items-center">
                                    <p class="mb-0 me-3">
                                        <span class="fw-bold"
                                            >{{ pageStart }}-{{ pageEnd }}</span
                                        >
                                        Data dari
                                        <span class="fw-bold">{{ rows }}</span>
                                        Data
                                    </p>
                                    <b-pagination
                                        v-model="currentPage"
                                        :total-rows="rows"
                                        :per-page="perPage"
                                    ></b-pagination>
                                </div>
                            </div>
                            <div
                                v-for="(receipt, index) in paginatedReceipts"
                                :key="index"
                            >
                                <div class="d-flex align-items-center mt-4">
                                    <img
                                        src="../assets/arrow-right.svg"
                                        alt="arrow-right"
                                        class="me-3 cursor-pointer"
                                        :class="{
                                            'rotate-90': receipt.isExpanded,
                                        }"
                                        @click="toggleReceipt(receipt)"
                                    />
                                    <input
                                        type="checkbox"
                                        class="form-check-input"
                                        :checked="isReceiptSelected(receipt)"
                                        @change="
                                            toggleReceiptSelection(receipt)
                                        "
                                    />
                                    <p class="mb-0 ms-3 fw-bold">
                                        {{ receipt.receipt_code }}
                                    </p>
                                </div>
                                <div class="d-flex mt-2">
                                    <img
                                        src="../assets/arrow-right.svg"
                                        alt="arrow-right"
                                        class="me-3 invisible"
                                    />
                                    <input
                                        type="checkbox"
                                        class="form-check-input invisible"
                                    />

                                    <table
                                        class="w-100 mt-2 ms-3"
                                        cellpadding="10"
                                    >
                                        <thead
                                            class="thead fw-bold"
                                            v-show="receipt.isExpanded"
                                        >
                                            <tr class="border-bottom">
                                                <td class="text-center">
                                                    Koli
                                                </td>
                                                <td class="text-center">
                                                    Berat (Kg)
                                                </td>
                                                <td class="text-center">
                                                    Berat Volume (Kg)
                                                </td>
                                                <td class="text-center">
                                                    Kubikasi (Kg3)
                                                </td>
                                            </tr>
                                        </thead>

                                        <tbody v-show="receipt.isExpanded">
                                            <tr
                                                class="border-bottom"
                                                v-for="(
                                                    item, index
                                                ) in receipt.items"
                                                :key="index"
                                            >
                                                <td class="text-center">
                                                    {{ item.qty }} Koli
                                                </td>
                                                <td class="text-center">
                                                    {{ item.weight }} Kg
                                                </td>
                                                <td class="text-center">
                                                    {{ item.weight_volume }} Kg
                                                </td>
                                                <td class="text-center">
                                                    {{ item.cubic }} Kg3
                                                </td>
                                            </tr>
                                        </tbody>

                                        <tfoot
                                            class="fw-bold"
                                            :class="{
                                                tfoot: receipt.isExpanded,
                                            }"
                                        >
                                            <tr>
                                                <td>
                                                    <div
                                                        class="d-flex justify-content-center align-items-center"
                                                    >
                                                        <img
                                                            src="../assets/koli.svg"
                                                            alt="koli"
                                                            class="me-2"
                                                        />
                                                        {{ receipt.total_qty }}
                                                        Koli
                                                    </div>
                                                </td>
                                                <td>
                                                    <div
                                                        class="d-flex justify-content-center align-items-center"
                                                    >
                                                        <img
                                                            src="../assets/berat.svg"
                                                            alt="berat"
                                                            class="me-2"
                                                        />
                                                        {{
                                                            receipt.total_weight
                                                        }}
                                                        Kg
                                                    </div>
                                                </td>
                                                <td>
                                                    <div
                                                        class="d-flex justify-content-center align-items-center"
                                                    >
                                                        <img
                                                            src="../assets/berat-volume.svg"
                                                            alt="berat volume"
                                                            class="me-2"
                                                        />
                                                        {{
                                                            receipt.total_weight_volume
                                                        }}
                                                        Kg
                                                    </div>
                                                </td>
                                                <td>
                                                    <div
                                                        class="d-flex justify-content-center align-items-center"
                                                    >
                                                        <img
                                                            src="../assets/kubikasi.svg"
                                                            alt="kubikasi"
                                                            class="me-2"
                                                        />
                                                        {{
                                                            receipt.total_cubic
                                                        }}
                                                        Kg3
                                                    </div>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                                <hr
                                    v-if="index < paginatedReceipts.length - 1"
                                />
                            </div>
                        </div>
                        <div v-else>
                            <div class="d-flex justify-content-center mt-5">
                                <img src="../assets/empty.svg" alt="empty" />
                            </div>
                            <p
                                class="text-gray text-center mt-5"
                                v-if="paginatedReceipts.length !== 0"
                            >
                                Masukkan kota asal dan kota tujuan terlebih
                                dahulu
                            </p>
                            <p class="text-gray text-center mt-5" v-else>
                                Resi tidak tersedia
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-5">
            <div class="mb-4 col-lg-6">
                <div class="box-manifest">
                    <h5 class="fw-bold mb-4">Nomor Resi Yang Dipilih</h5>
                    <div v-if="selectedReceipts.length > 0">
                        <div
                            v-for="(receipt, index) in selectedReceipts"
                            :key="receipt.receipt_code"
                            :class="{
                                'border-bottom':
                                    index < selectedReceipts.length - 1,
                            }"
                            class="d-flex justify-content-between align-items-center py-2"
                        >
                            <p class="mb-0 fw-bold">
                                {{ receipt.receipt_code }}
                            </p>
                            <div class="d-flex align-items-center">
                                <p class="mb-0 me-2">{{ receipt.dest }}</p>
                                <img
                                    src="../assets/trash.svg"
                                    alt="trash"
                                    class="cursor-pointer"
                                    @click="removeSelectedReceipt(receipt)"
                                />
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="d-flex justify-content-center">
                            <img src="../assets/empty.svg" alt="empty" />
                        </div>
                        <p class="text-gray text-center mt-5">
                            Belum ada resi yang dipilih
                        </p>
                    </div>
                </div>
            </div>

            <div class="mb-4 col-lg-6">
                <div class="box-manifest">
                    <h5 class="fw-bold">Ringkasan Manifest</h5>
                    <table class="w-100 mt-3" cellpadding="10">
                        <tr class="border-bottom">
                            <td class="pl-0">
                                <div class="d-flex align-items-center">
                                    <img
                                        src="../assets/rute.svg"
                                        alt="rute"
                                        class="me-2"
                                    />
                                    Rute Manifest
                                </div>
                            </td>
                            <td>:</td>
                            <td class="fw-bold">
                                {{
                                    originRegency && destinationRegency
                                        ? originRegency.name +
                                          " - " +
                                          destinationRegency.name
                                        : "-"
                                }}
                            </td>
                        </tr>
                        <tr class="border-bottom">
                            <td class="pl-0">
                                <div class="d-flex align-items-center">
                                    <img
                                        src="../assets/jumlah.svg"
                                        alt="jumlah"
                                        class="me-2"
                                    />
                                    Jumlah Resi
                                </div>
                            </td>
                            <td>:</td>
                            <td class="fw-bold">
                                {{ selectedReceipts.length }} Resi
                            </td>
                        </tr>
                        <tr>
                            <td class="pl-0">
                                <div class="d-flex align-items-center">
                                    <img
                                        src="../assets/arrive.svg"
                                        alt="arrive"
                                        class="me-2"
                                    />
                                    Waktu Tiba
                                </div>
                            </td>
                            <td>:</td>
                            <td class="fw-bold">
                                <DatePicker
                                    v-model="timeArrive"
                                    placeholder-text="Atur perkiraan waktu tiba"
                                    disabled-past
                                    :disabled="selectedReceipts.length === 0"
                                    no-icon
                                />
                            </td>
                        </tr>
                    </table>
                    <button
                        :class="{
                            'btn-create':
                                selectedReceipts.length > 0 ||
                                timeArrive === null,
                            'btn-create-disabled':
                                selectedReceipts.length === 0,
                        }"
                        class="w-100 mt-3 btn"
                        :disabled="
                            selectedReceipts.length === 0 || timeArrive === null
                        "
                        @click="modalConfirm = true"
                    >
                        Buat Manifest
                    </button>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalConfirm">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img src="../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">Manifest Akan Dibuat</h4>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin membuat manifest ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirm = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Kembali
                        </a>
                    </div>
                    <div class="col-lg-6 mt-4">
                        <button
                            @click="createManifest()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="isLoading"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="isLoading"
                            ></span>
                            Iya
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue" v-if="modalSuccess">
            <div class="overlay"></div>
            <div class="modal-body-chat vsm">
                <div class="mt-4 text-center">
                    <img
                        src="../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">Manifest Berhasil Dibuat</h4>
                </div>
                <div class="mt-4">
                    <router-link
                        to="/manifest"
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../api/Api";
import Multiselect from "vue-multiselect";
import moment from "moment";
import magnifierDisabled from "../assets/magnifier-disabled.svg";
import magnifier from "../assets/magnifier.svg";
import DatePicker from "../components/DatePicker.vue";

export default {
    components: {
        Multiselect,
        DatePicker,
    },
    data() {
        return {
            magnifierIcon: magnifierDisabled,
            magnifierActiveIcon: magnifier,
            isPageLoading: true,
            search: null,
            modalConfirm: false,
            modalSuccess: false,
            isLoading: false,
            moment: moment,
            originRegency: null,
            destinationRegency: null,
            regencies: [],
            timeArrive: null,
            receipts: [],
            filteredReceipts: [],
            selectedReceipts: [],
            selectAll: false,
            currentPage: 1,
            perPage: 5,
            rows: 0,
        };
    },
    computed: {
        paginatedReceipts() {
            const startIndex = (this.currentPage - 1) * this.perPage;
            const endIndex = startIndex + this.perPage;
            return this.filteredReceipts.slice(startIndex, endIndex);
        },
        totalPages() {
            return Math.ceil(this.filteredReceipts.length / this.perPage);
        },
        pageStart() {
            return this.paginatedReceipts === 0
                ? 0
                : (this.currentPage - 1) * this.perPage + 1;
        },
        pageEnd() {
            return Math.min(
                Number(this.pageStart) + Number(this.perPage) - 1,
                this.rows
            );
        },
    },
    created() {
        this.initializeData();
    },
    methods: {
        updatePaginationRows() {
            this.rows = this.filteredReceipts.length;
            if (this.currentPage > this.totalPages) {
                this.currentPage = 1;
            }
        },
        toggleReceipt(receipt) {
            receipt.isExpanded = !receipt.isExpanded;
        },
        toggleReceiptSelection(receipt) {
            const index = this.selectedReceipts.findIndex(
                (item) => item.receipt_code === receipt.receipt_code
            );

            if (index === -1) {
                this.selectedReceipts.push(receipt);
            } else {
                this.selectedReceipts.splice(index, 1);
            }

            this.updateSelectAllState();
        },
        updateSelectAllState() {
            this.selectAll =
                this.paginatedReceipts.length > 0 &&
                this.paginatedReceipts.every((receipt) =>
                    this.selectedReceipts.some(
                        (selected) =>
                            selected.receipt_code === receipt.receipt_code
                    )
                );
        },
        toggleSelectAll() {
            this.selectAll = !this.selectAll;

            if (this.selectAll) {
                this.paginatedReceipts.forEach((receipt) => {
                    if (!this.isReceiptSelected(receipt)) {
                        this.selectedReceipts.push(receipt);
                    }
                });
            } else {
                this.selectedReceipts = this.selectedReceipts.filter(
                    (selected) =>
                        !this.paginatedReceipts.some(
                            (paginated) =>
                                paginated.receipt_code === selected.receipt_code
                        )
                );
            }
        },
        removeSelectedReceipt(receipt) {
            const index = this.selectedReceipts.findIndex(
                (item) => item.receipt_code === receipt.receipt_code
            );

            if (index !== -1) {
                this.selectedReceipts.splice(index, 1);
                this.updateSelectAllState();
            }
        },
        isReceiptSelected(receipt) {
            return this.selectedReceipts.some(
                (item) => item.receipt_code === receipt.receipt_code
            );
        },
        handleSearch() {
            if (!this.search) {
                this.filteredReceipts = this.receipts;
            } else {
                const searchTerm = this.search.toLowerCase();

                this.filteredReceipts = this.receipts.filter((receipt) => {
                    const matchReceiptCode = receipt.receipt_code
                        .toLowerCase()
                        .includes(searchTerm);
                    const matchDestination = receipt.dest
                        ?.toLowerCase()
                        .includes(searchTerm);

                    return matchReceiptCode || matchDestination;
                });
            }

            this.currentPage = 1;
            this.updatePaginationRows();
            this.updateSelectAllState();
        },
        async getRegencies() {
            try {
                const res = await Api.get(
                    `${process.env.VUE_APP_SERVICE_URL}/master/geo/regency`,
                    {
                        headers: {
                            Authorization:
                                "Bearer" + localStorage.getItem("token"),
                        },
                    }
                );
                this.regencies = res.data.data;
            } catch (err) {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: err.response.data.message,
                });
            }
        },

        async getReceipts() {
            try {
                const res = await Api.get(
                    `${process.env.VUE_APP_SERVICE_URL}/manifest/receipts`,
                    {
                        headers: {
                            Authorization:
                                "Bearer" + localStorage.getItem("token"),
                        },
                    }
                );
                this.receipts = res.data.data.map((receipt) => ({
                    ...receipt,
                    isExpanded: false,
                }));
                this.filteredReceipts = this.receipts;
                this.updatePaginationRows();
            } catch (err) {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: err.response.data.message,
                });
            }
        },
        async initializeData() {
            try {
                await Promise.all([this.getRegencies(), this.getReceipts()]);
                this.isPageLoading = false;
            } catch (error) {
                this.$notify({
                    group: "foo",
                    type: "error",
                    title: "Error",
                    text: error.response.data.message,
                });
                this.isPageLoading = false;
            }
        },
        createManifest() {
            this.isLoading = true;
            const receiptIds = this.selectedReceipts.map(
                (receipt) => receipt.order_id
            );
            var data = {
                origin_regency_id: this.originRegency.id,
                destination_regency_id: this.destinationRegency.id,
                order_id: receiptIds,
                estimated_time: moment(this.timeArrive).format("YYYY-MM-DD"),
            };

            Api.post(
                `${process.env.VUE_APP_SERVICE_URL}/manifest/create`,
                data,
                {
                    headers: {
                        Authorization:
                            "Bearer " + localStorage.getItem("token"),
                    },
                }
            )
                .then(() => {
                    this.isLoading = false;
                    this.modalConfirm = false;
                    this.modalSuccess = true;
                })
                .catch((err) => {
                    this.isLoading = false;
                    this.modalConfirm = false;
                    this.$notify({
                        group: "foo",
                        type: "error",
                        title: "Error",
                        text: err.response.data.message,
                    });
                });
        },
    },
    watch: {
        originRegency() {
            if (this.search) {
                this.handleSearch();
            }

            if (
                this.originRegency === null ||
                this.destinationRegency === null
            ) {
                this.selectedReceipts = [];
                this.timeArrive = null;
                this.selectAll = false;
            }
        },
        destinationRegency() {
            if (this.search) {
                this.handleSearch();
            }

            if (
                this.originRegency === null ||
                this.destinationRegency === null
            ) {
                this.selectedReceipts = [];
                this.timeArrive = null;
                this.selectAll = false;
            }
        },
        selectedReceipts: {
            handler(newVal) {
                if (newVal.length === 0) {
                    this.timeArrive = null;
                }
            },
            deep: true,
        },
        filteredReceipts: {
            handler() {
                this.updatePaginationRows();
            },
            deep: true,
        },
        currentPage() {
            this.updateSelectAllState();
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep .page-link {
    background-color: #f3f5f7 !important;
}
::v-deep .page-item.active .page-link {
    background-color: #1291ff !important;
}
.magnifier-icon.left {
    position: absolute;
    top: 7px;
    left: 12px;
    z-index: 999;
}
.pl-filter-search {
    padding-left: 40px !important;
}
::v-deep .multi-form-custom {
    .multiselect__tags {
        padding-left: 40px !important;
    }
    .multiselect__select {
        padding: 0;
    }
}
.btn-search {
    background-color: #1291ff !important;
    border: 1px solid #c1cdd7 !important;
    color: #ffffff !important;
}
.btn-search:disabled {
    background-color: #1291ff !important;
    border: 1px solid #c1cdd7 !important;
    color: #ffffff !important;
    opacity: 1 !important;
}
.btn-search:focus {
    background-color: #1291ff !important;
    border: 1px solid #c1cdd7 !important;
    color: #ffffff !important;
    opacity: 1 !important;
}
::v-deep .mx-icon-calendar {
    display: none;
}
.rotate-90 {
    transform: rotate(90deg);
    transition: transform 0.3s ease;
}
.form-check-input {
    width: 20px;
    height: 20px;
}
.form-check-input:checked {
    background-color: #d9f2e4 !important;
    border-color: #329a5f !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='14' viewBox='0 0 20 14' fill='none'%3E%3Cpath d='M1.83398 7.16667L6.42553 12.0862C6.88676 12.5804 7.6701 12.5804 8.13133 12.0862L18.1673 1.33334' stroke='%23329A5F' stroke-width='1.75' stroke-linecap='round'/%3E%3C/svg%3E") !important;
}
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.thead,
.tfoot {
    background-color: #f9fafb;
}
.btn-create {
    background-color: #1291ff;
    color: #ffffff;
}
.btn-create:hover {
    background-color: #1291ff;
    color: #ffffff;
}
.btn-create-disabled {
    background-color: #e0e6eb !important;
    color: #849bae !important;
}
</style>
