<template>
    <div class="padding-container">
        <notifications group="foo" position="top right" />
        <div class="tab-content" id="pills-tabContent">
            <div class="box">
                <div class="mb-3">
                    <h3 class="fw-bold">Daftar Leads</h3>
                </div>
                <div class="row justify-content-between gy-3">
                    <div class="col-lg-8">
                        <div class="row">
                            <div class="col-12 col-lg-6 my-2">
                                <div class="position-relative">
                                    <div class="input-group">
                                        <input
                                            type="text"
                                            class="form-control form-control-lg pl-search w-search border-search"
                                            placeholder="Cari nama customer"
                                            v-model="keySearch"
                                        />
                                        <button
                                            class="btn btn-primary px-4"
                                            type="button"
                                            @click="searchData()"
                                        >
                                            Cari
                                        </button>
                                    </div>
                                    <span
                                        class="search-icon left position-absolute top-50 start-0 translate-middle-y ms-3"
                                    >
                                        <i class="pi pi-search"></i>
                                    </span>
                                </div>
                            </div>
                            <div class="col-12 col-lg-3 my-2">
                                <button
                                    class="btn btn-outline-yellow btn-filter transparent w-100"
                                    @click="isFilter = !isFilter"
                                >
                                    <img
                                        src="../../assets/dashboard/filter.png"
                                        class="me-1"
                                        width="18"
                                        alt=""
                                    />
                                    Filter
                                </button>
                            </div>
                            <div class="col-12 col-lg-3 my-2">
                                <div class="dropdown w-100">
                                    <button
                                        class="btn btn-primary btn-with-search dropdown-toggle w-100"
                                        type="button"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                        disabled
                                    >
                                        Actions
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li>
                                            <button
                                                class="dropdown-item"
                                                disabled
                                            >
                                                Download Data
                                            </button>
                                        </li>
                                        <li>
                                            <button
                                                class="dropdown-item"
                                                disabled
                                            >
                                                Upload Data
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 d-flex justify-content-end">
                        <router-link
                            :to="{ name: 'crm-customers-form' }"
                            class="btn btn-primary px-3 btn-with-search my-2 width-m-100"
                        >
                            <i class="pi pi-plus"></i>

                            Tambah Data
                        </router-link>
                    </div>
                </div>
                <div class="box mt-3 position-relative" v-if="isFilter">
                    <div class="row">
                        <div
                            class="col-md-6 mb-2"
                            v-show="isRole(managerRoles)"
                        >
                            <label class="fw-bold mb-1">CRM Specialist</label>
                            <multiselect
                                v-model="specialist"
                                :options="specialists"
                                label="name"
                                track-by="id"
                                placeholder="Pilih CRM specialist"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div
                            class="mb-2"
                            v-bind:class="{
                                'col-md-4': !isRole(managerRoles),
                                'col-md-6': isRole(managerRoles),
                            }"
                        >
                            <label class="fw-bold mb-1">Tags</label>
                            <multiselect
                                v-model="tag"
                                :options="tags"
                                multiple
                                placeholder="Pilih tags"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div
                            class="mb-2"
                            v-bind:class="{
                                'col-md-4': !isRole(managerRoles),
                                'col-md-6': isRole(managerRoles),
                            }"
                        >
                            <label class="fw-bold mb-1">Cabang</label>
                            <multiselect
                                v-model="branch"
                                :options="branchs"
                                label="name"
                                track-by="id"
                                placeholder="Pilih cabang"
                                class="multi-form-custom"
                                :show-labels="false"
                            >
                            </multiselect>
                        </div>
                        <div
                            class="mb-2"
                            v-bind:class="{
                                'col-md-4': !isRole(managerRoles),
                                'col-md-6': isRole(managerRoles),
                            }"
                        >
                            <label class="fw-bold"> Tanggal Dibuat </label>
                            <date-picker
                                v-model="date_created"
                                type="date"
                                value-type="format"
                                class="w-100 mt-1"
                                format="DD MMMM YYYY"
                                :editable="false"
                                placeholder="Pilih tanggal dibuat"
                            >
                            </date-picker>
                        </div>
                    </div>
                    <div class="mt-1">
                        <button
                            class="btn btn-outline-yellow px-5 me-3 width-m-100 my-2"
                            @click="clearFilter()"
                            :disabled="
                                specialist == null &&
                                tag == null &&
                                branch == null &&
                                date_created == ''
                            "
                        >
                            Clear
                        </button>
                        <button
                            class="btn btn-green px-5 me-3 width-m-100 my-2"
                            :disabled="
                                specialist == null &&
                                tag == null &&
                                branch == null &&
                                date_created == ''
                            "
                            @click="filterDate()"
                        >
                            Filter
                        </button>
                    </div>
                </div>
                <div
                    class="d-flex flex-column flex-lg-row align-items-lg-center gap-3 mt-4"
                >
                    <div class="text-center text-lg-start">
                        Menampilkan
                        <span class="fw-bold"
                            >{{ pageStart }}-{{ pageEnd }}</span
                        >
                        Data dari
                        <span class="fw-bold">{{ leadsData.total_data }}</span>
                        Data
                    </div>
                    <div class="ms-lg-auto">
                        <div
                            class="d-flex align-items-center gap-2 justify-content-center"
                        >
                            <label for="pageSize" class="mb-0"
                                >Baris per halaman</label
                            >
                            <select
                                id="pageSize"
                                class="form-control form-select w-select outline-yellow"
                                style="width: auto; min-width: 80px"
                                v-model="limit"
                                @change="onLimitChange()"
                            >
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="mt-4" v-if="is_my_list_ready">
                    <ListLoader />
                </div>
                <div v-else>
                    <div class="table-responsive-custom">
                        <table class="table table-bordered mt-4">
                            <thead class="table-light">
                                <tr>
                                    <th class="text-center" scope="col">No</th>
                                    <th class="text-center" scope="col">
                                        Nama Customer
                                    </th>
                                    <th class="text-center" scope="col">
                                        Kontak Customer
                                    </th>
                                    <th
                                        class="text-center"
                                        scope="col"
                                        v-show="isRole(managerRoles)"
                                    >
                                        CRM Specialist
                                    </th>
                                    <th class="text-center" scope="col">
                                        Tags
                                    </th>
                                    <th class="text-center" scope="col">
                                        Sumber Info
                                    </th>
                                    <th class="text-center" scope="col">
                                        Cabang
                                    </th>
                                    <th class="text-center" scope="col">
                                        <div class="d-flex align-items-center">
                                            Tanggal Dibuat
                                            <div class="ms-auto">
                                                <a
                                                    href="javascript:void(0)"
                                                    class="dropdown-toggle ms-2"
                                                    id="statusRefund"
                                                    data-bs-toggle="dropdown"
                                                    data-bs-auto-close="true"
                                                    aria-expanded="false"
                                                >
                                                    <img
                                                        src="../../assets/dashboard/sort.png"
                                                        width="9"
                                                        alt=""
                                                    />
                                                </a>
                                                <ul
                                                    class="dropdown-menu"
                                                    aria-labelledby="statusRefund"
                                                >
                                                    <li>
                                                        <a
                                                            class="dropdown-item"
                                                            href="javascript:void(0)"
                                                            @click="
                                                                sortBy('asc')
                                                            "
                                                        >
                                                            Terbaru
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            class="dropdown-item"
                                                            href="javascript:void(0)"
                                                            @click="
                                                                sortBy('desc')
                                                            "
                                                        >
                                                            Terlama
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </th>
                                    <th class="text-center" scope="col">
                                        Follow Up
                                    </th>
                                    <th class="text-center" scope="col">
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(item, index) in leadsData.list_data"
                                    :key="index"
                                >
                                    <td class="text-center">
                                        {{ index + 1 }}
                                    </td>
                                    <td class="">
                                        <router-link
                                            class="text-link"
                                            :to="`/data/leads/detail?lead_id=${
                                                item.id
                                            }${
                                                item.followup_id
                                                    ? '&followup_id=' +
                                                      item.followup_id
                                                    : ''
                                            }`"
                                        >
                                            {{ item.name ? item.name : "-" }}
                                        </router-link>
                                    </td>
                                    <td
                                        class="d-flex justify-content-center gap-3"
                                    >
                                        <a
                                            :href="`https://wa.me/${item.phone}`"
                                            v-if="item.phone"
                                        >
                                            <img
                                                src="../../assets/whatsapp-icon.png"
                                                width="20"
                                            />
                                        </a>
                                        <a
                                            :href="`mailto:${item.email}`"
                                            v-if="item.email"
                                        >
                                            <img
                                                src="../../assets/gmail-icon.png"
                                                width="20"
                                            />
                                        </a>
                                    </td>
                                    <td
                                        class="capitalize"
                                        v-show="isRole(managerRoles)"
                                    >
                                        {{
                                            item.created_by
                                                ? item.created_by
                                                : "-"
                                        }}
                                    </td>
                                    <td class="capitalize">
                                        <template v-if="item.tags == null">
                                            -
                                        </template>
                                        <span
                                            v-else
                                            class="tag gray-new radius mb-0 pb-0"
                                            v-for="(tag, index) in item.tags"
                                            :key="index"
                                        >
                                            {{ tag }}
                                        </span>
                                    </td>
                                    <td class="capitalize text-center">
                                        {{
                                            item.data_source
                                                ? item.data_source
                                                : "-"
                                        }}
                                    </td>
                                    <td class="capitalize text-center">
                                        {{ item.branch ? item.branch : "-" }}
                                    </td>
                                    <td class="text-center">
                                        {{ item.created_at }}
                                    </td>
                                    <td class="text-center">
                                        <router-link
                                            class="text-link"
                                            :to="{
                                                name: 'crm-customers-leads-prospect',
                                                params: {
                                                    id: item.id,
                                                    item: item,
                                                    type: 'lead',
                                                },
                                            }"
                                        >
                                            <img
                                                src="../../assets/dashboard/follow-up.png"
                                                width="20"
                                            />
                                        </router-link>
                                    </td>
                                    <td class="text-center">
                                        <router-link
                                            :to="'/data/leads/edit/' + item.id"
                                        >
                                            <img
                                                src="../../assets/dashboard/edit-icon.png"
                                                width="20"
                                            />
                                        </router-link>
                                        <a
                                            href="javascript:void(0)"
                                            @click="
                                                showModalDeleteLeads(item.id)
                                            "
                                            class="ms-2"
                                        >
                                            <img
                                                src="../../assets/dashboard/trash-icon.png"
                                                width="20"
                                            />
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div
                        v-if="leadsData.list_data.length == 0"
                        class="text-center mt-section"
                    >
                        <img
                            src="../../assets/no-data-green.png"
                            width="100"
                            alt=""
                        />
                        <div class="mt-4 mb-4 text-gray">
                            Belum ada daftar leads
                        </div>
                    </div>
                </div>
                <div class="d-flex mt-4">
                    <div class="ms-auto">
                        <div class="d-flex align-items-center">
                            <b-pagination-nav
                                v-if="leadsData.list_data.length > 0"
                                v-model="currentPage"
                                :number-of-pages="totalPage"
                                base-url="#"
                                first-number
                                align="right"
                                @input="changePage()"
                            ></b-pagination-nav>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- modal -->
        <div class="modal-vue">
            <div class="overlay" v-if="modalConfirmDeleteLeads"></div>
            <div class="modal-body-chat vsm" v-if="modalConfirmDeleteLeads">
                <div class="mt-4 text-center">
                    <img src="../../assets/info-yellow.png" width="80" alt="" />
                    <h4 class="mb-0 fw-bold mt-4">Data Leads akan dihapus</h4>
                    <div class="text-gray-dashboard">
                        Apakah Anda yakin ingin menghapus data ini?
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 mt-4">
                        <a
                            href="javascript:void(0)"
                            @click="modalConfirmDeleteLeads = false"
                            class="btn btn-outline-yellow w-100 btn-lg"
                        >
                            Batal
                        </a>
                    </div>
                    <div class="col-md-6 mt-4">
                        <button
                            @click="deleteLeads()"
                            type="button"
                            class="btn btn-primary w-100 btn-lg"
                            :disabled="is_delete_leads"
                        >
                            <span
                                class="spinner-border spinner-border-sm me-2"
                                v-if="is_delete_leads"
                            ></span>
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-vue">
            <div class="overlay" v-if="modalSuccessDeleteLeads"></div>
            <div class="modal-body-chat vsm" v-if="modalSuccessDeleteLeads">
                <div class="mt-4 text-center">
                    <img
                        src="../../assets/dashboard/checklis.png"
                        width="70"
                        alt=""
                    />
                    <h4 class="mb-0 fw-bold mt-4">
                        Data Leads Berhasil dihapus
                    </h4>
                </div>
                <div class="mt-4">
                    <a
                        href="javascript:void(0)"
                        @click="
                            getLeadsData(), (modalSuccessDeleteLeads = false)
                        "
                        class="btn btn-primary w-100 btn-lg"
                    >
                        Tutup
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Api from "../../api/Api";
import ListLoader from "../../components/ListLoader.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import Multiselect from "vue-multiselect";

export default {
    components: {
        ListLoader,
        DatePicker,
        Multiselect,
    },
    name: "LeadsPage",
    props: ["roles"],
    data() {
        return {
            pageStart: 0,
            pageEnd: 0,
            moment: moment,
            leadsData: {
                list_data: [],
            },
            keySearch: "",
            is_my_list_ready: false,
            modalSuccess: false,
            currentPage: 1,
            totalPage: 0,
            limit: 5,
            isFilter: false,
            item_id: null,
            date_created: "",
            modalConfirmDeleteLeads: false,
            modalSuccessDeleteLeads: false,
            leads_id: null,
            is_delete_leads: false,
            branch: null,
            branchs: [],
            tag: null,
            tags: [],
            type_filter: "",
            types: [],
            specialist: null,
            specialists: [],
            managerRoles: ["crm-manager"],
            sort_at: "asc",
        };
    },
    created() {
        this.getLeadsData();
        this.getSpecialists();
        this.getTags();
        this.getBranchs();
    },
    methods: {
        onLimitChange() {
            this.currentPage = 1;
            this.getLeadsData();
        },
        filterDate() {
            this.getLeadsData();
            this.currentPage = 0;
        },
        clearFilter() {
            this.date_created = "";
            this.currentPage = 0;
            this.specialist = null;
            this.branch = null;
            this.tag = null;
            this.getLeadsData();
        },
        searchData() {
            this.getLeadsData();
            this.currentPage = 0;
        },
        changePage() {
            this.getLeadsData();
        },
        getLeadsData() {
            let payload = {
                search: this.keySearch,
                limit: this.limit,
                branch_id: this.branch?.id,
                user_id: this.specialist?.id,
                type: this.type_filter?.type,
                page:
                    this.currentPage == 0 || this.currentPage == null
                        ? 0
                        : this.currentPage - 0,
            };
            if (this.date_created) {
                payload.date_created = moment(this.date_created).format(
                    "YYYY-MM-DD"
                );
            }
            if (this.sort_at) {
                payload.sort_at = this.sort_at;
            }
            if (this.tag) {
                payload.tags = this.tag;
            }
            this.is_my_list_ready = true;
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/leads`, {
                params: payload,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.leadsData = res.data.data;
                    this.totalPage = res.data.data.total_page;
                    const totalItems = this.leadsData.list_data.length;
                    const totalData = res.data.data.total_data;
                    this.pageStart =
                        totalItems === 0
                            ? 0
                            : (this.currentPage - 1) * this.limit + 1;
                    this.pageEnd = Math.min(
                        Number(this.pageStart) + Number(this.limit) - 1,
                        totalData
                    );
                    this.is_my_list_ready = false;
                })
                .catch((err) => {
                    console.log(err);
                    this.is_my_list_ready = false;
                });
        },
        getSpecialists() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/user`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.specialists = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getTags() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/tag`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.tags = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getBranchs() {
            Api.get(`${process.env.VUE_APP_SERVICE_URL}/master/branch`, {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    this.branchs = res.data.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        showModalDeleteLeads(id) {
            this.modalConfirmDeleteLeads = true;
            this.leads_id = id;
        },
        deleteLeads() {
            this.is_delete_leads = true;
            var data = {
                id: this.leads_id,
            };
            Api.post(`${process.env.VUE_APP_SERVICE_URL}/leads/delete`, data, {
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer" + localStorage.getItem("token"),
                },
            })
                .then((res) => {
                    console.log(res);
                    this.modalConfirmDeleteLeads = false;
                    this.modalSuccessDeleteLeads = true;
                    this.is_delete_leads = false;
                })
                .catch((err) => {
                    this.$toast.error(err.response.data.message, {
                        position: "top-right",
                        timeout: 2000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.6,
                        showCloseButtonOnHover: false,
                        hideProgressBar: true,
                        closeButton: false,
                        icon: true,
                        rtl: false,
                    });
                    this.is_delete_leads = false;
                    this.modalConfirmDeleteLeads = false;
                });
        },
        sortBy(value) {
            this.sort_at = value;
            this.getLeadsData();
        },
        isRole(value) {
            let result = value.some((el) => this.roles.includes(el));
            return result;
        },
    },
    computed: {},
};
</script>
<style lang="scss" scoped>
.box-manifest {
    border: 1px solid #c1cdd7;
    background-color: #f9fafb;
    border-radius: 16px;
    padding: 20px 25px;
}
.table-responsive-custom {
    .table {
        border-radius: 8px;
        border-collapse: separate;
        border-spacing: 0;
        border: 1px solid #c1cdd7;

        th,
        td {
            border: 1px solid #c1cdd7;
        }
        thead {
            tr:first-child {
                th:first-child {
                    border-top-left-radius: 8px;
                }
                th:last-child {
                    border-top-right-radius: 8px;
                }
            }
        }

        tbody {
            tr:last-child {
                td:first-child {
                    border-bottom-left-radius: 8px;
                }
                td:last-child {
                    border-bottom-right-radius: 8px;
                }
            }
        }
    }
}
td {
    background-color: transparent !important;
}
th {
    background-color: #f3f5f7 !important;
}
::v-deep .page-link {
    background-color: #f3f5f7 !important;
}
::v-deep .page-item.active .page-link {
    background-color: #1291ff !important;
}
.outline-yellow {
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    background-color: transparent !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
.outline-yellow:focus {
    border: 1px solid #40bf76 !important;
    color: #40bf76 !important;
    background-color: transparent !important;
    --bs-form-select-bg-img: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3E%3Cpath d='M4.46967 7.96967C4.73594 7.7034 5.1526 7.6792 5.44621 7.89705L5.53033 7.96967L12 14.439L18.4697 7.96967C18.7359 7.7034 19.1526 7.6792 19.4462 7.89705L19.5303 7.96967C19.7966 8.23594 19.8208 8.6526 19.6029 8.94621L19.5303 9.03033L12.5303 16.0303C12.2641 16.2966 11.8474 16.3208 11.5538 16.1029L11.4697 16.0303L4.46967 9.03033C4.17678 8.73744 4.17678 8.26256 4.46967 7.96967Z' fill='%2340BF76'/%3E%3C/svg%3E");
}
.btn-filter.transparent {
    background: transparent !important;
}
.width-m-100 {
    width: 100%;
    @media (min-width: 992px) {
        width: fit-content;
    }
}
button.dropdown-item:disabled {
    background-color: transparent !important;
}
th {
    border-color: #e8e8e9;
    padding: 10px !important;
}
tr {
    border-color: #e8e8e9;
    padding: 10px !important;
}
td {
    padding: 10px !important;
}
.table-light {
    background-color: #f7f7f7 !important;
    --bs-table-border-color: #f7f7f7 !important;
    --bs-table-bg: #f7f7f7 !important;
}
.w-search-leads {
    width: 200px !important;
}
.dropdown-menu.show {
    box-shadow: 0 1px 12px -1px rgba(141, 145, 162, 0.25);
    border: 1px solid #f7f7f7;
    border-radius: 8px;
}
th:first-child td:first-child {
    border-top-left-radius: 10px;
}
th:first-child td:last-child {
    border-top-right-radius: 10px;
}

th:last-child td:first-child {
    border-bottom-left-radius: 10px;
}
th:last-child td:last-child {
    border-bottom-right-radius: 10px;
}
</style>
